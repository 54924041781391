<template>
  <div>
    <v-container class="mt-6">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="6" class="mt-6">
          <v-layout align-center column justify-center class="mt-6">
            <v-card>
              <v-card-text>
                <v-text-field label="Username" v-model="username" />
                <v-text-field label="Password" type="password" v-model="password" @keyup.enter="onClickEnter()"/>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn @click="login" type="Submit" :loading="loading">Log In</v-btn>
              </v-card-actions>
            </v-card>
            <v-alert type="error" v-if="loginFailure">Pogrešan username ili lozinka. Probajte opet.</v-alert>
            <div class="mt-2">
              Copyright &copy; 2021 Altermedia
            </div>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as apiUsers from '../api/users.js'
export default {
  name: 'Login',
  data: function () {
    return {
      username: '',
      password: '',
      loginFailure: false,
      loading: false
    }
  },
  methods: {
    onClickEnter () {
      this.login()
    },
    root () {
      if (this.$router.currentRoute.path !== '/') {
        this.$router.push('/')
        return
      }

      if (this.$store.state.getters.isLoggedIn === false) {
        this.$router.push('/login')
      }
    },
    login () {
      this.loading = true;

      apiUsers.LogIn(this.username, this.password)
        .then(response => response.json())
        .then(data => {
          //localStorage.setItem('user-token', data.token)
          this.loading = false;
          this.$store.commit('token', data.token);
          this.root();
        })
        .catch(() => {
          this.loading = false;
          this.$store.commit('token', null)
          this.showLoginError()
        })
    },

    showLoginError () {
      this.loginFailure = true

      setTimeout(() => {
        this.loginFailure = false
      }, 3000)
    }
  }
}

</script>
