<template>
  <div>
    <v-dialog v-model="dialogNewCCC" @keydown.esc="onCancel" persistent max-width="70%">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-if="useIcon" v-bind="attrs" v-on="on">mdi-plus</v-icon>

        <v-btn v-else class="primary mb-3" dark v-bind="attrs" v-on="on">
          Dodaj kod veze
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Dodavanje koda veze
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-row class="mt-4">
                  <v-col cols="12" sm="4" class="pa-0">
                    <v-list>
                      <v-list-item class="pa-0">
                        <v-list-item-icon><v-icon>mdi-export</v-icon></v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{ getName('sender') }}</v-list-item-title>
                          <v-list-item-subtitle>Pošiljalac</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="12" sm="1" class="ma-0">
                    <v-btn icon @click="switchCustomers">
                      <v-icon>mdi-arrow-left-right</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="4" class="pa-0">
                    <v-list>
                      <v-list-item class="pa-0">
                        <v-list-item-icon><v-icon>mdi-import</v-icon></v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{ getName('receiver') }}</v-list-item-title>
                          <v-list-item-subtitle>Primalac</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="12" sm="3" class="ma-0">
                    <find-customer-dialog
                      @update="customerSelected"
                      icon="mdi-plus"
                      :buttonText="getButtonText()"
                      :toolbarText="getToolbarText()"/>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-0">
                    <v-text-field label="Kod veze" required outlined v-model="newCCC.customerCode"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-0 pb-0">
                    <v-text-field label="GLN Dobavljača" outlined v-model="newCCC.glnDobavljac"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-0 pb-0">
                    <v-text-field label="GLN Primaoca" outlined v-model="newCCC.glnPrimalac"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-0 pb-0">
                    <v-text-field label="GLN Kupca" outlined v-model="newCCC.glnKupac"></v-text-field>
                  </v-col>

                   <small>* označava neophodna polja</small>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
                <customers-cust-codes-list :customerId="customerSender.id" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error ma-2 white--text" text @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn class="primary ma-2 white--text" text @click="onAddCCC" :loading="postingToApi">
            Dodaj kod veze
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="5000" color="success">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn text icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
  </div>
</template>

<script>
import * as cccAPI from '../api/customerscustcode'
import CustomersCustCodesList from '../components/CustomersCustCodesList'
import FindCustomerDialog from './FindCustomerDialog'

export default {
  name: "AddCustomersCustCodeDialog",
  components: {
    CustomersCustCodesList,
    FindCustomerDialog
  },
  data: function () {
    return {
      dialogNewCCC: false,
      postingToApi: false,
      newCCC: {
        customerSID: null,
        customerRID: null,
        customerCode: null
      },
      snackbar: false,
      snackbarText: "",
      choosingReceiver: true,

      chosenSender: {
        id: null,
        name: null
      },
      chosenReceiver: {
        id: null,
        name: null
      }
    }
  },
  props: {
    customerSender: {
      type: Object,
      required: true
    },
    customerReceiver: {
      type: Object,
      required: false,
      default: null
    },
    useIcon: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    customerReceiver: {
      handler() {
        this.chosenReceiver = this.customerReceiver;
      }
    }
  },
  mounted() {
    this.newCCC = this.getDefaultNewCCC();

    if (this.customerSender !== null) {
      this.chosenSender = this.customerSender;
    }

    if (this.customerReceiver !== null) {
      this.chosenReceiver = this.customerReceiver;
    }
  },
  methods: {
    getDefaultNewCCC() {
      return {
        customerSID: this.chosenSender.id,
        customerRID: this.chosenReceiver.id,
        customerCode: null
      }
    },
    onAddCCC () {
      this.postingToApi = true;

      this.newCCC.customerSID = this.chosenSender.id;
      this.newCCC.customerRID = this.chosenReceiver.id;

      cccAPI.postCCC(this.newCCC, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          // loading na dugmetu
          this.postingToApi = false;

          // zatvaranje modalnog prozora
          this.dialogNewCCC = false;

          // prikaz snackbar obavestenja
          this.snackbarText = "Dodat kod veze sa ID-jem: " + responseData.id;
          this.snackbar = true;

          this.$emit('update');
        })
        .catch(() => {
          this.postingToApi = false;
        });
    },
    onCancel () {
      this.dialogNewCCC = false;
    },

    customerSelected(chosenCustomer) {
      if (this.choosingReceiver) {
        this.chosenReceiver = chosenCustomer;
      }
      else {
        this.chosenSender = chosenCustomer;
      }
    },

    getButtonText() {
      return this.choosingReceiver ? "Izaberi primaoca" : "Izaberi pošiljaoca";
    },

    getToolbarText() {
      return this.choosingReceiver ? "Izaberi isporučno mesto primaoca" : "Izaberi isporučno mesto pošiljaoca";
    },

    getName (customerType) {
      let customer = null;
      if (customerType === "receiver") {
        customer = this.chosenReceiver;
      } else {
        customer = this.chosenSender;
      }

      return customer.name;
    },

    switchCustomers() {
      this.choosingReceiver = !this.choosingReceiver;

      let tmpCustomer = this.chosenSender;
      this.chosenSender = this.chosenReceiver;
      this.chosenReceiver = tmpCustomer;
    }
  }
}
</script>
