<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10">
        <v-row align-center column justify-center>
          <v-row class="mt-2 mb-2">
            <v-btn class="primary" dark to="/users/createUser">
              Dodaj centralnog korisnika
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
          <users-list class="mb-10"/>

          <v-row class="mt-10 mb-2">
            <v-btn class="primary" dark to="/users/createPortalUser">
              Dodaj korisnika portala
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
          <portal-users-list />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UsersList from '../components/UsersList.vue'
import PortalUsersList from '../components/PortalUsersList.vue'

export default {
  name: "UsersPage",
  components: {
    UsersList,
    PortalUsersList
  }
}
</script>
