
export function registerEdexAffiliateUser (body) {
  const requestHeaders = new Headers()

  const requestOptions = {
    method: 'POST'
  }

  let bodyString = JSON.stringify(body)

  requestOptions.body = bodyString;
  requestHeaders.set('Content-Type', 'application/json');

  requestOptions.headers = requestHeaders

  // do the fetch
  return fetch("http://207.154.226.86:7123/api/auth/register", requestOptions)
    .then(response => {
      if (response.ok) {
        return response
      }
      else {
        if (response.status === 400) {
          return response.json()
            .then(jsonData => {
              if (jsonData.ErrorMessage)
                throw new Error(jsonData.ErrorMessage);
              else
                throw new Error(jsonData.ErrorCode);
            });
        }
        else {
          throw response;
        }
      }
    })
}
