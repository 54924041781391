var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"1200px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.onCancel($event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}]),model:{value:(_vm.dialogSefLinkUserDetails),callback:function ($$v) {_vm.dialogSefLinkUserDetails=$$v},expression:"dialogSefLinkUserDetails"}},[_c('v-card',{attrs:{"width":"100%"}},[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.onCancel}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(" Podaci o SEF Link korisniku ")]),_c('v-spacer')],1),_c('v-card-title',[_vm._v(" Naziv: "+_vm._s(_vm.user.name)+" ")]),_c('v-card-subtitle',[_vm._v(" ID: "+_vm._s(_vm.user.id)+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.userProperties.slice(0, _vm.userProperties.length / 2)),function(item){return _c('v-list-item',{key:item.label},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.label))]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(_vm._s(_vm.getPropertyValue(item.propertyName)))])],1)],1)}),1)],1),_c('v-col',[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.userProperties.slice(_vm.userProperties.length / 2, _vm.userProperties.length)),function(item){return _c('v-list-item',{key:item.label},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.label))]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v(_vm._s(_vm.getPropertyValue(item.propertyName)))])],1)],1)}),1)],1)],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.userStatisticsHeaders,"items":_vm.userStatistics,"loading":_vm.loadingStatistics,"footer-props":{
            'items-per-page-options': [10, 20, 30, 40, 50],
            'items-per-page-text': 'Broj redova po stranici:'
          },"no-data-text":"Nema rezultata","loading-text":"Učitavanje podataka... Molimo sačekajte","width":"100%"},scopedSlots:_vm._u([{key:"item.lastLogin",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.lastLogin)))])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }