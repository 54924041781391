<template>
  <v-container fluid>
    <v-card elevation="4">
      <v-card-title>
        Fajlovi
      </v-card-title>
      <v-card-subtitle>
        <v-row justify="end">
          <v-col cols="12" sm="4" class="py-0">
            <v-text-field
              :rules="[rules.min3Characters, rules.customerNotChosen]"
              v-model="queryString"
              append-icon="mdi-magnify"
              label="Pretraga"
              single-line
              clearable
              @keyup.enter.prevent='onEnterSearch'
              @click:clear='onClearSearch()'
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="12" sm="3" >
            <find-customer-dialog
              @update="senderSelected"
              :isSmall="true"
              icon="mdi-magnify"
              buttonText="Pošiljalac"
              toolbarText="Izaberi isporučno mesto pošiljaoca"
            />
          </v-col>

          <v-col cols="12" sm="4" class="py-0">
            <v-row>
              <v-col>
                <v-switch v-model="isFileIdSearch" label="Pretraga po ID" @click="onSwitch">
                </v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-data-table
      :headers="headers"
      :items="listOfFiles"
      :server-items-length="options.page * options.itemsPerPage + 1"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
      @click:row="onRowClick"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ format_datetime(item.createdAt) }}</span>
        </template>

        <template v-slot:[`item.processed`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">{{ getProcessedIcon(item) }}</v-icon>
            </template>
            <span>{{ getProcessedTooltip(item) }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.duplicate`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">{{ getDuplicateIcon(item) }}</v-icon>
            </template>
            <span>{{ getDuplicateAvatarTooltip(item) }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.error`]="{ item }">
            <v-icon color="red">{{ getErrorIcon(item) }}</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import * as filesAPI from '../api/files.js';
import moment from 'moment';

import FindCustomerDialog from './FindCustomerDialog.vue';

export default {
  name: "FilesList",
  components: {
    FindCustomerDialog
  },
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true]
      },

      queryParameters: {},
      queryString: null,
      sender: null,
      isFileIdSearch: false,

      listOfFiles: [],
      headers: [
          { text: 'ID', value: 'id', width: "5%" },
          { text: 'Naziv', value: 'name' },
          { text: 'Tip', value: 'type', width: "10%", sortable: false },
          { text: 'Datum i vreme', value: 'createdAt', width: "20%" },
          { text: 'Pošiljalac', value: 'sender.name', width: "20%", sortable: false },
          { text: 'Obrađen', value: 'processed', width: "3%", sortable: false },
          { text: 'Duplikat', value: 'duplicate', width: "3%", sortable: false },
          { text: 'Greška', value: 'error', width: "3%", sortable: false },
      ],

      rules: {
        customerNotChosen: value => {
          let invalid = this.isFileIdSearch === false && this.sender === null && value !== null;
          return !invalid || 'Izaberite pošiljaoca.';
        },
        min3Characters: value => {
          let invalid =  this.isFileIdSearch === false && value !== null && value.length < 3 && this.sender != null;
          return !invalid || 'Minimum 3 karaktera';
        },
      },
    }
  },
  watch: {
    options: {
      handler () {
        this.getFilesFromAPI()
      },
      deep: true,
    },
  },
  mounted () {
    this.getFilesFromAPI()
  },
  methods: {
    getFilesFromAPI() {
      this.loading = true;

      let queryParameters = this.mapToQueryParameters();

      filesAPI.getList(queryParameters, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false;
          this.listOfFiles = responseData;
        })
        .catch(() => {
          this.listOfFiles = [];
          this.loading = false;
        })
    },

    onEnterSearch() {
      this.options.page = 1;
      this.getFilesFromAPI();
    },

    onClearSearch() {
      this.options.page = 1;
      this.queryString = null;
      this.getFilesFromAPI()
    },

    onRowClick(file) {
      let route = this.$router.resolve({ path: '/files/' + file.id });
      window.open(route.href, '_blank');
    },

    onSwitch() {
      this.options.page = 1;

      this.forceValidate();

      this.getFilesFromAPI();
    },

    senderSelected(customer) {
      this.sender = customer;
      this.forceValidate();

      this.getFilesFromAPI();
    },

    forceValidate() {
      // ovo je samo da bismo trigerovali revalidaciju
      if (this.queryString)
      {
        this.queryString += " ";
      }
    },

    format_date(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },

    format_datetime(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
      }
    },

    getProcessedIcon(item) {
      if (item.userProcessed)
        return "mdi-check";
      else
        return "mdi-close";
    },

    getProcessedTooltip(item) {
      if (item.userProcessed)
        return "Fajl je obrađen";
      else
        return "Fajl nije obrađen";
    },

    getDuplicateIcon(file) {
      if (file.duplicate) {
        return "mdi-check-all";
      }
    },

    getDuplicateAvatarTooltip(item) {
      if (item.duplicate)
        return "Fajl sadrži duplikat";
    },

    getErrorIcon(file) {
      if (file.error == true) {
        return "mdi-alert";
      }
    },

    getDocumentTypeTooltip(document) {
      let documentTypeDict = {
        1: "Faktura",
        2: "Narudžbina",
        3: "Odgovor",
        4: "Uslovi",
        5: "Faktura PDF",
        6: "Knjiška odobrenja",
        7: "Izvod otvorenih stavki",
        8: "Otpremnica",
        9: "Povratnica"
      }

      return documentTypeDict[document.type];
    },

    mapToQueryParameters() {
      let queryParameters = {};

      if (this.sender !== null) {
        queryParameters.senderId = this.sender.id;
      }

      if (this.queryString) {
        queryParameters.search = this.queryString;
      }

      if (this.options.sortDesc !== null) {
        queryParameters.sortDesc = this.options.sortDesc;
      }

      if (this.options.sortBy !== null) {
        queryParameters.sortBy = this.options.sortBy;
      }

      if (this.options.page !== null) {
        queryParameters.page = this.options.page;
      }

      if (this.options.itemsPerPage !== null) {
        queryParameters.itemsPerPage = this.options.itemsPerPage;
      }

      queryParameters.isFileIdSearch = this.isFileIdSearch;

      return queryParameters;
    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.red-background {
  background-color: rgb(247, 109, 109)
}
</style>
