<template>
  <v-container fluid class="mt-10">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" md="8" class="mb-2">
        <v-card elevation="4">
          <v-card-title> Pretraga dokumenta po ID-ju </v-card-title>
          <v-card-text>
            <v-row>
              <v-col sm="12" md="4" class="mb-4">
                <v-text-field
                  v-model="queryString"
                  append-icon="mdi-magnify"
                  label="ID dokumenta"
                  single-line
                  hide-details
                  clearable
                  @keyup.enter.prevent="onEnterSearch"
                  @click:clear="onClearSearch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="8" v-if="loading">
        <v-card elevation="4">
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="8" v-else-if="document === null">
        <v-card elevation="4">
          <v-card-subtitle>
            Nije nadjen dokument sa zadatim ID-jem
          </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12" md="8" v-else>
        <document-details :document="document" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as documentsAPI from "../api/documents.js";

import DocumentDetails from "../components/DocumentDetails.vue";

export default {
  name: "DocumentSearchById",
  components: {
    DocumentDetails
  },
  data: function () {
    return {
      loading: false,
      document: null,
      queryString: null,
    };
  },
  methods: {
    getDocumentFromAPI() {
      if (!this.queryString){
        return;
      }

      this.loading = true;
      let documentId = parseInt(this.queryString);

      documentsAPI
        .getDocument(documentId, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          this.document = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.document = null;
          this.loading = false;
        });
    },

    onEnterSearch() {
      this.getDocumentFromAPI();
    },

    onClearSearch() {
      this.queryString = "";
      this.getDocumentFromAPI();
    },

    getAvatarColor(item) {
      if (item.userProcessed) return "success";
      else return "error";
    },

    getAvatarTooltip(item) {
      if (item.userProcessed) return "Dokument je preuzet";
      else return "Dokument nije preuzet";
    },
  },
};
</script>
