<template>
  <div class="ma-0 pa-0">
    <v-dialog v-model="dialogFindUser" @keydown.esc="dialogFindUser = false" persistent max-width="60%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="primary"
          :small="isSmall"
          :fab="isCircle"
          :disabled="disabled"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ buttonText }}
          <v-icon :class="getClass()">{{ icon }}</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="dialogFindUser = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ toolbarText }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-card class="mt-4">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="queryString"
                append-icon="mdi-magnify"
                label="Pretraga"
                single-line
                hide-details
                clearable
                @keyup.enter.prevent="onEnterSearch"
                @click:clear="onClearSearch()"
              ></v-text-field>
            </v-card-title>
            <v-data-table
            :headers="headers"
            :items="listOfUsers"
            :server-items-length="options.page * options.itemsPerPage + 1"
            :options.sync="options"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50],
              'items-per-page-text': 'Broj redova po stranici:'
            }"
            no-data-text="Nema rezultata"
            loading-text="Učitavanje podataka... Molimo sačekajte"
            class="elevation-1 row-pointer"
            width="100%"
            @click:row="onRowClick"
            >
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as apiUsers from "../api/users.js";

export default {
  name: "FindUserDialog",
  data: function () {
    return {
      dialogFindUser: false,
      loading: true,
      options: {
        companyId: null,
        sortDesc: [true]
      },
      queryString: null,
      listOfUsers: [],
      headers: [
          { text: 'ID', value: 'id', width: "10%" },
          { text: 'Naziv', value: 'name', width: "15%" },
          { text: 'Email', value: 'email', width: "15%" },
          { text: 'Korisničko ime', value: 'username', width: "15%" },
          { text: 'Kompanija', value: 'company.companyName', sortable: false },
          { text: 'PIB', value: 'company.pib', sortable: false },
          { text: 'Uloga', value: 'role' },
          { text: 'Aktivan', value: 'active' },
      ]
    };
  },
  props: {
    companyId: {
      type: Number,
      required: false,
      default: null
    },
    buttonText: {
      type: String,
      required: true,
    },
    toolbarText: {
      type: String,
      required: true,
    },
    isSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    isCircle: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    options: {
      handler() {
        this.options.search = this.queryString;
        this.options.companyId = this.companyId;
        this.getUsersFromAPI();
      },
      deep: true,
    },
    companyId: {
      handler() {
        this.options.companyId = this.companyId;
        this.options.page = 1;
        this.getUsersFromAPI();
      }
    }
  },
  methods: {
    getUsersFromAPI() {
      this.loading = true;

      apiUsers.getList(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.listOfUsers = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.listOfUsers = [];
          this.loading = false;
        })
    },

    onRowClick(user) {
      this.dialogFindUser = false;
      this.$emit("update", user);
    },

    getClass() {
      if (!this.isSmall && !this.isCircle) {
        return "ml-2";
      }
      return "";
    },

    onEnterSearch() {
      this.options.search = this.queryString;
      this.options.page = 1;
      this.getUsersFromAPI();
    },

    onClearSearch() {
      this.options.search = '';
      this.queryString = '';
      this.getUsersFromAPI();
    },

  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
