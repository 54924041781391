<template>
  <v-container>
    <v-card>
      <v-card-title>
        Centralni korisnici
        <v-spacer></v-spacer>
        <v-text-field
          v-model="queryString"
          append-icon="mdi-magnify"
          label="Pretraga"
          single-line
          hide-details
          clearable
          @keyup.enter.prevent='onEnterSearch'
          @click:clear='onClearSearch()'
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="listOfUsers"
        :server-items-length="options.page * options.itemsPerPage + 1"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50],
          'items-per-page-text': 'Broj redova po stranici:'
        }"
        no-data-text="Nema rezultata"
        loading-text="Učitavanje podataka... Molimo sačekajte"
        class="elevation-1 row-pointer"
        width="100%"
      >
        <template v-slot:[`item.active`]="{ item }">
          <v-avatar :color="getAvatarColor(item)" size="15"></v-avatar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" @click="onRowClick(item)">mdi-eye</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import * as apiUsers from '../api/users.js'
export default {
  name: "UsersList",
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true],
        customerId: null
      },
      queryString: null,
      listOfUsers: [],
      headers: [
          { text: 'ID', value: 'id', width: "10%" },
          { text: 'Naziv', value: 'name', width: "15%" },
          { text: 'Email', value: 'email', width: "15%" },
          { text: 'Korisničko ime', value: 'username', width: "15%" },
          { text: 'Kompanija', value: 'company.companyName', sortable: false },
          { text: 'PIB', value: 'company.pib', sortable: false },
          { text: 'Uloga', value: 'role' },
          { text: 'Aktivan', value: 'active' },
          { text: '', value: 'actions', width:"3%", sortable: false }
      ]
    }
  },
  props: {
    customerId: {
      type: Number,
      required: false,
      default: null
    }
  },
  watch: {
    options: {
      handler () {
        this.options.search = this.queryString;
        this.options.customerId = this.customerId;
        this.getUsersFromAPI();
      },
      deep: true,
    }
  },
  mounted () {
    this.getUsersFromAPI();
  },
  methods: {
    getUsersFromAPI() {
      this.loading = true;

      apiUsers.getList(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.listOfUsers = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.listOfUsers = [];
          this.loading = false;
        })
    },

    onEnterSearch() {
      this.options.search = this.queryString;
      this.options.page = 1;
      this.getUsersFromAPI();
    },

    onClearSearch() {
      this.options.search = '';
      this.queryString = '';
      this.getUsersFromAPI();
    },

    onRowClick(user) {
      let route = this.$router.resolve({ path: '/users/' + user.id });
      window.open(route.href, '_blank');
    },

    getAvatarColor(item) {
      if (item.active === 1)
        return "success";
      else
        return "error";
    },
  }
}
</script>
