<template>
  <span>
    <v-card flat class="elevation-0" :rounded="false">
      <v-card-text>
      <v-data-table
      :headers="headers"
      :items="listOfUsers"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [25, 50, 100],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
      elevation="0"
      >
        <template v-slot:[`item.isSent`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar :color="getSentToSefColor(item.isSent)" size="15" v-bind="attrs" v-on="on"></v-avatar>
            </template>
            <span>{{getSentToSefTooltip(item.isSent)}}</span>
          </v-tooltip>
        </template>

         <template v-slot:[`item.senderName`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" :style="getCompanyNameBorderStyle(item.sendersCompanyId, true)" class="pl-2">{{ getShortenedStr(item.senderName)}}</div>
            </template>
            <span>{{item.senderName}}</span>
          </v-tooltip>
        </template>

         <template v-slot:[`item.receiverName`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" :style="getCompanyNameBorderStyle(item.sendersCompanyId, false)">{{ getShortenedStr(item.receiverName)}}</div>
            </template>
            <span>{{item.receiverName}}</span>
          </v-tooltip>
        </template>

         <template v-slot:[`item.edexStatusMessage`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">{{ item.isSent ? '' : getShortenedStr(item.edexStatusMessage)}}</div>
            </template>
            <span>{{item.edexStatusMessage}}</span>
          </v-tooltip>
        </template>

         <template v-slot:[`item.sefComment`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">{{ getShortenedStr(item.sefComment)}}</div>
            </template>
            <span>{{item.sefComment}}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          <div>{{formatDate(item.createdAt)}}</div>
        </template>

        <template v-slot:[`item.validUntil`]="{ item }">
          <div>{{formatDate(item.validUntil)}}</div>
        </template>

         <template v-slot:[`item.isSeflinkPlus`]="{ item }">
          <v-icon>{{ item.isSeflinkPlus ? 'mdi-check-bold' : '' }}</v-icon>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <sef-link-user-details :user="item" :token="token"/>
        </template>
      </v-data-table>
      </v-card-text>
    </v-card>
  </span>
</template>

<style lang="css">
td {
  padding: 0 !important;
  padding-left: 10px !important;
  height: 30px !important;
}

th {
  padding: 0 !important;
  padding-left: 10px !important;
}
</style>

<script>
import * as apiSefLink from '../api/seflink.js';
import moment from 'moment';
import SefLinkUserDetails from './SefLinkUserDetails.vue';

export default {
  components: { SefLinkUserDetails },
  name: "ListSefLinkUsers",
  data: function () {
    return {
      loading: false,
      downloadingExcel: false,
      options: {
        sortDesc: [true],
        itemsPerPage: 20,
      },
      queryString: null,
      listOfUsers: [],
      totalCount: 1,
      headers: [
          { text: 'Naziv', value: 'name', width: "20%", sortable: false },
          { text: 'PIB', value: 'tid', width: "7%", sortable: false, align: 'center' },
          { text: 'MB', value: 'mn', width: "7%", sortable: false,  align: 'center' },
          { text: 'Adresa', value: 'address', width: "15%", sortable: false },
          { text: 'Grad', value: 'town', width: "4%", sortable: false },
          { text: 'Poštanski broj', value: 'postalCode', width: "7%",  sortable: false },
          { text: 'Datum dodavanja', value: 'createdAt', width: "7%",  sortable: false },
          { text: 'Konktakt email', value: 'contactEmail', width: "7%", sortable: false },
          { text: 'Status supskripcije', value: 'subscriptionStatus', width: "5%", sortable: false },
          { text: 'Datum isteka supskripcije', value: 'validUntil', width: "5%", sortable: false, align: 'center' },
          { text: 'SEF Link+', value: 'isSeflinkPlus', width: "3%", sortable: false },
          { text: '', value: 'actions', width: '3%', sortable: false }
      ]
    }
  },
  mounted() {
  },
  watch: {
    options: {
      handler () {
        this.getSalesFromAPI();
      },
      deep: true,
    },
    filters: {
      handler(newFilters) {
        this.filters = newFilters;
        this.queryString = newFilters.filter;
        //this.options.page = 1;
        this.getSalesFromAPI();
      },
      deep: true
    },
    token: {
      handler() {
       //this.token = edexToken;
        console.log('novi token');
        this.getSalesFromAPI();
      }
    },
  },
   props: {
    filters: {
      type: Object,
      required: false
    },
    token: {
      type: String,
      default: "",
      required: false
    }
  },
  methods: {
    getCompanyNameBorderStyle(isSendersCompanyId, isSender) {
      if ((isSender && isSendersCompanyId) || (!isSender && !isSendersCompanyId)) {
         //return "border: 2px solid #434136; border-radius: 12px;";
         return "background-color: #DCDCDC;";
      } else {
        return "";
      }
    },
    getSalesFromAPI() {
      this.loading = true;
      if (this.token === null) {
        return;
      }

      apiSefLink.getAdminUsers(this.queryString, this.token)
        .then(responseData => {
          this.loading = false;
          this.totalCount = responseData.length;
          this.listOfUsers = responseData;
        })
        .catch((error) => {
          this.listOfUsers = [];
          this.totalCount = 0;
          this.loading = false;
          console.log(error);
        })
    },

    getSentToSefTooltip(isSent) {
      if (isSent === null) {
        return '';
      } else if (isSent === true) {
        return 'Da';
      } else {
        return 'Ne';
      }
    },

    getSentToSefColor(isSent) {
      if (isSent === null) {
        return 'surface-variant';
      } else if (isSent === true) {
        return 'success';
      } else {
        return 'error';
      }
    },

    formatDate(datum) {
      if (datum) {
        return moment(String(datum)).format('DD.MM.YYYY.');
      } else {
        return '';
      }
    },

    getShortenedStr(str) {
      if (str === null) {
        return '';
      }

      const maxLength = 50;
      if (str.length > maxLength) {
        return str.substring(0, maxLength) + '...';
      }

      return str;
    },

    downloadXml(docId) {
      const requestHeaders = new Headers();
      requestHeaders.set('Authorization', 'Bearer ' + this.token);

      const requestOptions = { method: 'GET' };
      requestOptions.headers = requestHeaders;

      // do the fetch
      let fullEndpoint = process.env.VUE_APP_EDEX_BASEURL + '/admin/xml?id=' + docId;
      return fetch(fullEndpoint, requestOptions)
        .then(response => {
          const header = response.headers.get('content-disposition');
          const parts = header.split(';');
          let filename = parts[1].split('=')[1].replaceAll("\"", "");

          return response.blob()
            .then(blob => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download = filename;
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove();  //afterwards we remove the element again
            });
        })
        .catch(() => {
        });
    }
  }
}
</script>

<style scoped>
.v-data-table {
  box-shadow: none !important;
}
</style>
