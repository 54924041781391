var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"pt-5 pb-3",attrs:{"cols":"12","sm":"2"}},[_c('v-select',{attrs:{"dense":"","items":_vm.documentSearchTypes,"item-text":"text","item-value":"value","label":"Tip pretrage","outlined":"","hide-details":""},model:{value:(_vm.documentSearchType),callback:function ($$v) {_vm.documentSearchType=$$v},expression:"documentSearchType"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pretraga","single-line":"","hide-details":"","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.onEnterSearch($event)},"click:clear":function($event){return _vm.onClearSearch()}},model:{value:(_vm.queryString),callback:function ($$v) {_vm.queryString=$$v},expression:"queryString"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.listOfDocuments,"server-items-length":_vm.options.page * _vm.options.itemsPerPage + 1,"options":_vm.options,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': 'Broj redova po stranici:'
    },"no-data-text":"Nema rezultata","loading-text":"Učitavanje podataka... Molimo sačekajte","width":"100%"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.datum",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.format_date(item.datum)))])]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.format_datetime(item.createdAt)))])]}},{key:"item.userProcessed",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":_vm.getAvatarColor(item),"size":"15"}},'v-avatar',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getAvatarTooltip(item)))])])]}},{key:"item.type",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.getDocumentTypeIcon(item)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getDocumentTypeTooltip(item)))])])]}},{key:"item.flOrderNum",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(item.flOrderNum !== null)?_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.copyRefDocNumToClipboard(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-reply ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Referencirani dokument: ")]),_c('span',{ref:"textToCopy"},[_vm._v(_vm._s(_vm.getRefDocTooltip(item)))])])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onRowClick(item)}}},[_vm._v("mdi-eye")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }