<template>
  <v-card v-if="loading === false" width="100%" class="mt-4">
    <v-card-title>
      Lokalni kodovi
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text v-if="localCodesList.length > 0">
      <v-list dense>
        <v-list-item v-for="item in localCodesList" :key="item.id">
          <v-list-item-avatar>
            <v-icon>mdi-code-braces-box</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Lokalni kod: {{ item.code }}</v-list-item-title>
            <v-list-item-subtitle>Kompanija: {{item.companyID}}, Isporučno mesto: {{item.customerID}}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <edit-local-code-dialog :localCode="item"  @update="getLocalCodes" />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-text v-else>
      Nema podataka.
    </v-card-text>
    <v-card-actions>
      <add-local-code-dialog :customer="customer" @update="getLocalCodes" />
    </v-card-actions>
  </v-card>
</template>

<script>
import * as apiLocalCodes from '../api/localcodes.js'
import AddLocalCodeDialog from './AddLocalCodeDialog'
import EditLocalCodeDialog from './EditLocalCodeDialog'

export default {
  name: "LocalCodesList",
  components: {
    AddLocalCodeDialog,
    EditLocalCodeDialog
  },
  data: function () {
    return {
      loading: false,
      localCodesList: []
    }
  },
  props: {
    customer: {
      type: Object,
      required: false
    }
  },
  mounted () {
    this.getLocalCodes(this.customer.id)
  },
  methods: {
    getLocalCodes(customerId) {
      this.loading = true;

      apiLocalCodes.getLocalCodeByCustomerId(customerId, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.localCodesList = responseData;
          this.loading = false;
        })
        .catch(() => {
          console.log("Greska...");
          this.localCodesList = [];
          this.loading = false;
        });
    }
  }
}
</script>
