<template>
<v-container fluid>
  <v-card class="mb-3" elevation="4">
      <v-card-title>
        Napredna pretraga
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2">
            <v-select dense :items="documentTypes" v-model="advancedSearch.docType" item-text="text" item-value="value" label="Tip dokumenta" outlined>
            </v-select>
          </v-col>
          <v-col cols="12" md="2" class="">
            <v-row>
              <v-menu v-model="datePicker.menuDatumDokumentaOd" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    clearable
                    class="mt-0 pt-0"
                    label="Dat. dokumenta (od)"
                    prepend-icon="mdi-calendar"
                    :value="formatDate(advancedSearch.datumDokumentaOd)"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="advancedSearch.datumDokumentaOd = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="advancedSearch.datumDokumentaOd"
                  @input="datePicker.menuDatumDokumentaOd = false"
                ></v-date-picker>
              </v-menu>

              <v-menu v-model="datePicker.menuDatumDokumentaDo" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    clearable
                    class="mt-0 pt-0"
                    label="Dat. dokumenta (do)"
                    prepend-icon="mdi-calendar"
                    :value="formatDate(advancedSearch.datumDokumentaDo)"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="advancedSearch.datumDokumentaDo = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="advancedSearch.datumDokumentaDo"
                  @input="datePicker.menuDatumDokumentaDo = false"
                ></v-date-picker>
              </v-menu>
            </v-row>
          </v-col>
          <v-col cols="12" md="2" class="pl-4">
            <v-row>
              <v-menu v-model="datePicker.menuDatumKreiranjaOd" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    clearable
                    readonly
                    class="mt-0 pt-0"
                    label="Datum kreiranja (od)"
                    prepend-icon="mdi-calendar"
                    :value="formatDate(advancedSearch.datumKreiranjaOd)"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="advancedSearch.datumKreiranjaOd = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="advancedSearch.datumKreiranjaOd"
                  @input="datePicker.menuDatumKreiranjaOd = false"
                ></v-date-picker>
              </v-menu>

              <v-menu v-model="datePicker.menuDatumKreiranjaDo" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    clearable
                    class="mt-0 pt-0"
                    label="Datum kreiranja (do)"
                    prepend-icon="mdi-calendar"
                    :value="formatDate(advancedSearch.datumKreiranjaDo)"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="advancedSearch.datumKreiranjaDo = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="advancedSearch.datumKreiranjaDo"
                  @input="datePicker.menuDatumKreiranjaDo = false"
                ></v-date-picker>
              </v-menu>
            </v-row>
          </v-col>
          <v-col cols="12" md="2" class="pl-6 pr-0">
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <find-customer-dialog
                  @update="senderSelected"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi isporučno mesto pošiljaoca"
                />
              </v-col>
              <v-list class="py-0">
                <v-list-item class="pa-0">
                  <v-list-item-icon><v-icon>mdi-import</v-icon></v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ advancedSearch.sender.name }}</v-list-item-title>
                    <v-list-item-subtitle>Pošiljalac</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-col cols="12" class="ma-0 pa-0">
                <find-customer-dialog
                @update="receiverSelected"
                :isSmall="true"
                icon="mdi-magnify"
                buttonText=""
                toolbarText="Izaberi isporučno mesto primaoca"
                />
              </v-col>
              <v-list class="py-0">
                <v-list-item class="pa-0">
                  <v-list-item-icon><v-icon>mdi-export</v-icon></v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ advancedSearch.receiver.name }}</v-list-item-title>
                    <v-list-item-subtitle>Primalac</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-row>
          </v-col>

          <v-col cols="12" md="2" class="pl-6 pr-0">
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <find-company-dialog
                  @update="receiverCompanySelected"
                  :isSmall="true"
                  icon="mdi-magnify"
                  buttonText=""
                  toolbarText="Izaberi kompaniju primaoca"
                />
              </v-col>
              <v-list class="py-0">
                <v-list-item class="pa-0">
                  <v-list-item-icon><v-icon>mdi-export</v-icon></v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ advancedSearch.receiverCompany.companyName }}</v-list-item-title>
                    <v-list-item-subtitle>Kompanija primalac</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-row>
          </v-col>

          <v-col cols="12" md="1" class="pt-0 mx-0 px-0">
            <v-radio-group v-model="advancedSearch.userProcessed" column class="mt-0 pt-0">
              <v-radio label="-" value="null"></v-radio>
              <v-radio label="Preuzeti" :value="true"></v-radio>
              <v-radio label="Nepreuzeti" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="primary ma-2 white--text" text to="/document/searchById">
            Pretraga dokumenta po ID-ju
            <v-icon right>mdi-magnify</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="resetAdvancedSearch">
          <v-icon>mdi-restart</v-icon>
          Resetuj pretragu
        </v-btn>
        <v-btn class="primary" @click="performAdvancedSearch">
          Prikaži rezultate
        </v-btn>
      </v-card-actions>
    </v-card>
</v-container>
</template>

<script>
import moment from 'moment';
import FindCustomerDialog from './FindCustomerDialog'
import FindCompanyDialog from './FindCompanyDialog.vue';

export default {
  name: "DocumentsAdvancedSearch",
  components: {
    FindCustomerDialog,
    FindCompanyDialog
  },
  data: function () {
    return {
      documentTypes: [
        { value: 1, text: "Faktura" },
        { value: 2, text: "Narudžbenica" },
        { value: 3, text: "Odgovor" },
        { value: 4, text: "Uslovi" },
        { value: 5, text: "Faktura PDF" },
        { value: 6, text: "Knjiška odobrenja" },
        { value: 7, text: "Izvod otvorenih stavki" },
        { value: 8, text: "Otpremnica" },
      ],
      datePicker: {
        menuDatumDokumentaOd: false,
        menuDatumDokumentaDo: false,
        menuDatumKreiranjaOd: false,
        menuDatumKreiranjaDo: false
      },
      advancedSearch: {
        docType: null,
        datumDokumentaOd: null,
        datumDokumentaDo: null,
        datumKreiranjaOd: null,
        datumKreiranjaDo: null,
        sender: {
          name: null
        },
        receiver: {
          name: null
        },
        receiverCompany: {
          companyName: null
        },
        userProcessed: "null"
      }
    }
  },
  methods: {
    senderSelected(customer) {
      this.advancedSearch.sender = customer;
    },

    receiverSelected(customer) {
      this.advancedSearch.receiver = customer;
    },

    receiverCompanySelected(company) {
      this.advancedSearch.receiverCompany = company;
    },

    performAdvancedSearch() {
      console.log("DocumentsAdvancedSearch.vue: Emit event updateAdvancedSearch");
      this.$emit("updateAdvancedSearch", this.advancedSearch);
    },

    resetAdvancedSearch() {
      this.advancedSearch.datumDokumentaOd = null;
      this.advancedSearch.datumDokumentaDo = null;
      this.advancedSearch.datumKreiranjaOd = null;
      this.advancedSearch.datumKreiranjaDo = null;
      this.advancedSearch.sender = {
        name: null
      };
      this.advancedSearch.receiver = {
        name: null
      };
      this.advancedSearch.receiverCompany = {
        companyName: null
      };
      this.advancedSearch.docType = null;
      this.advancedSearch.userProcessed = "null";
    },

    formatDate(datum) {
      return datum ? moment(datum).format('DD.MM.YYYY') : ''
    }
  }
}
</script>
