<template>
  <v-dialog v-model="dialogConfirmResend" @keydown.esc="dialogConfirmResend = false" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
       <v-btn icon text variant="outlined"  v-bind="attrs" v-on="on">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="18.000000pt" height="18.000000pt" viewBox="0 0 500.000000 500.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path d="M2245 4153 c-349 -45 -687 -205 -938 -443 -177 -168 -316 -374 -401
            -591 -84 -216 -119 -396 -119 -619 0 -223 35 -403 119 -619 174 -444 567 -817
            1010 -956 202 -64 273 -76 474 -82 219 -6 340 8 531 63 324 93 636 310 854
            594 41 54 75 100 75 102 0 2 25 49 55 103 56 101 103 211 135 318 l18 57 -213
            0 -213 0 -55 -112 c-120 -249 -307 -446 -541 -572 -305 -164 -682 -190 -1016
            -70 -288 103 -542 325 -679 593 -101 199 -143 367 -143 581 0 396 181 760 497
            997 150 113 305 184 500 229 113 27 387 27 500 0 129 -30 293 -94 388 -153 84
            -52 237 -177 237 -193 0 -4 -149 -157 -331 -339 l-331 -331 726 0 726 0 0 726
            0 725 -241 -240 c-133 -133 -244 -241 -246 -241 -3 0 -37 30 -76 66 -78 72
            -219 169 -339 234 -135 72 -344 139 -516 165 -89 14 -367 18 -447 8z"/>
            <path d="M1540 2515 l0 -815 160 0 160 0 0 350 0 350 23 0 c15 0 34 -15 57
            -46 19 -25 71 -89 116 -142 44 -53 98 -120 120 -148 21 -28 97 -121 167 -207
            l128 -157 185 0 c101 0 184 3 184 8 0 4 -8 18 -18 33 -17 25 -172 220 -182
            229 -3 3 -28 34 -55 70 -27 36 -81 104 -119 152 -39 47 -84 104 -101 126 -16
            21 -65 82 -107 134 -43 52 -78 100 -78 106 0 11 153 205 320 406 41 50 97 119
            123 154 27 34 73 91 103 125 30 34 54 68 54 74 0 10 -40 13 -169 13 l-169 0
            -28 -37 c-16 -21 -71 -89 -123 -152 -51 -62 -120 -146 -151 -185 -32 -40 -77
            -95 -101 -123 -24 -28 -66 -78 -92 -112 -30 -38 -55 -61 -67 -61 -20 0 -20 8
            -20 335 l0 335 -160 0 -160 0 0 -815z"/>
            </g>
            </svg>
        </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Da li želite da ponovo pošaljete dokument na Amaps preko Kafke?
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="red--text plain ma-2" text @click="dialogConfirmResend = false">
          Odustani
        </v-btn>
        <v-btn class="green--text plain ma-2" text @click="onConfirmResend" :loading="resending">
          Pošalji ponovo
        </v-btn>

        <v-row>
          <v-col sm="12" v-if="errorMsg != null">
           {{ errorMsg }}
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as apiSefLink from '../api/seflink.js';

export default {
  name: 'ConfirmResendDocumentToAmaps',
  data: function () {
    return {
      dialogConfirmResend: false,
      resending: false,
      errorMsg: null
    }
  },
  props: {
    documentId: {
      type: Number,
      required: true
    },
    token: {
      type: String,
      default: "",
      required: false
    }
  },
  methods: {
    onConfirmResend() {
      this.resending = true;
      this.errorMsg= null;
      apiSefLink.resendDocumentToAmaps(this.documentId, this.token)
        .then(() => {
          this.resending = false;
          this.errorMsg = null;
        })
        .catch((error) => {
          this.resending = false;
          this.errorMsg = error;
        })
    },
  }
}
</script>
