<template>
  <v-card v-if="loading === false" width="100%">
    <v-card-title>
      Broj: {{ document.broj }}
    </v-card-title>

    <v-card-subtitle>
      ID: {{ document.id }}
      <br />
      ID fajla: {{ document.fileId }}
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col>
          <v-list dense>
            <v-list-item v-for="item in documentInfo" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getDocumentProperty(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn class="primary ma-2 white--text" text @click="onResendDocument" :loading="resendDocInProgress">
        Pošalji ponovo
        <v-icon right>mdi-refresh</v-icon>
      </v-btn>

      <v-btn class="primary ma-2 white--text" text @click="onDownloadFile" :loading="downloadFileInProgress">
        Preuzmi ulazni fajl
        <v-icon right>mdi-download</v-icon>
      </v-btn>

       <v-btn class="primary ma-2 white--text" text @click="onResendDocumentToSefLink" :loading="resendDocToSefLinkInProgress">
        Pošalji ponovo na SEF Link
        <v-icon right>mdi-refresh</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
    </v-card-actions>

    <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show">
    </snackbar>
  </v-card>
</template>

<script>
import moment from "moment";
import * as apiDocuments from "../api/documents";

import Snackbar from "../components/Snackbar.vue"

export default {
  name: 'DocumentDetails',
  components: {
    Snackbar
  },
  data: function () {
    return {
      loading: false,
      resendDocInProgress: false,
      resendDocToSefLinkInProgress: false,
      downloadFileInProgress: false,
      documentInfo: [
        { label: "Tip", propertyName: 'type', icon: "mdi-file-document-outline" },
        { label: "Pošiljalac", propertyName: 'sender.name', icon: "mdi-home-export-outline" },
        { label: "Primalac", propertyName: 'receiver.name', icon: "mdi-home-import-outline" },
        { label: "Datum", propertyName: 'datum', icon: "mdi-calendar-range" },
        { label: "Datum valute", propertyName: 'datumValute', icon: "mdi-calendar-clock" },
        { label: "Napomena", propertyName: 'napomena', icon: "mdi-alert-octagon" },
        { label: "Otpremnica", propertyName: 'otpremnica', icon: "mdi-file-document" },
        { label: "Referencirani dokument", propertyName: 'flOrderNum', icon: "mdi-reply" },
        { label: "Datum kreiranja", propertyName: 'createdAt', icon: "mdi-sort-calendar-ascending" },
        { label: "Datum ažuriranja", propertyName: 'updatedAt', icon: "mdi-calendar-refresh" }
      ],
      documentTypes: {
        "1" : "Faktura",
        "2" : "Narudžbenica",
        "3" : "Odgovor",
        "4" : "Uslovi",
        "5" : "Faktura PDF",
        "6" : "Knjiška odobrenja",
        "7" : "Izvod otvorenih stavki",
        "8" : "Otpremnica",
        "9" : "Povratnica",
        "10" : "Lager"
      },

      snackbar: {
        show : false,
        text : "",
        isError : false
      },
    }
  },
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  methods: {
    getDocumentProperty(propertyName) {
      if (propertyName === "type") {
        return this.documentTypes[this.document.type];
      }

      let dateProperties = [ "datum", "datumValute", "createdAt", "updatedAt" ];
      if (dateProperties.includes(propertyName)) {
        return this.formatDate(this.document[propertyName]);
      }

      if (propertyName.includes(".")) {
        let stringParts = propertyName.split(".");
        let firstPart = stringParts[0];
        let secondPart = stringParts[1];

        let customer = this.document[firstPart];
        return customer[secondPart];
      }

      return this.document[propertyName];
    },

    formatDate(datum) {
      return datum ? moment(datum).format('DD.MM.YYYY') : ''
    },

    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },

    onResendDocument() {
      this.resendDocInProgress = true;

      apiDocuments
        .postResendDocument(this.document.id, this.$store.getters.token)
        .then(response => response.text())
        .then(() => {
          // loading na dugmetu
          this.resendDocInProgress = false;

          this.showNotification("Dokument " + this.document.id + " uspešno ponovo poslat.");
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }
          this.resendDocInProgress = false;
        });
    },

    onResendDocumentToSefLink() {
      console.log(this.$store.getters.token);
      console.log(this.document.id);
      apiDocuments.postResendDocumentToSefLink(this.document.id, this.$store.getters.token)
        .then(response => response.text())
        .then(() => {
          this.resendDocToSefLinkInProgress = false;
          this.showNotification("Dokument " + this.document.id + " uspešno poslat na SEF Link.");
        })
        .catch((error) => {
          this.resendDocToSefLinkInProgress = false;
          if (error.message) {
            this.showError(error.message);
          }
        })
    },

    onDownloadFile() {
      const endpoint = '/files/download/' + this.document.fileId;

      const requestHeaders = new Headers();
      requestHeaders.set('Authorization', 'Bearer ' + this.$store.getters.token);

      const requestOptions = { method: 'GET' };
      requestOptions.headers = requestHeaders;

      this.downloadFileInProgress = true;

      // do the fetch
      const amapsApiBaseUrl = process.env.VUE_APP_API_BASEURL;
      let fullEndpoint = amapsApiBaseUrl + endpoint;
      return fetch(fullEndpoint, requestOptions)
        .then(response => {
          const header = response.headers.get('content-disposition');
          const parts = header.split(';');
          let filename = parts[1].split('=')[1].replaceAll("\"", "");

          return response.blob()
            .then(blob => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download = filename;
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove();  //afterwards we remove the element again
              this.downloadFileInProgress = false;
            });
        })
        .catch(() => {
          this.downloadFileInProgress = false;
        });
    }
  },
}
</script>
