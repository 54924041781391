import * as fetch from './fetch'

export function getDocument(id, token) {
  const endpoint = '/documents/' + id
  return fetch.GET(endpoint, token, true)
}

export function getList(options, advancedSearchParams, token) {
  let parameters = mapToParameters(options, advancedSearchParams);

  const endpoint = '/documents'
  let queryString = new URLSearchParams(parameters).toString()
  return fetch.GET(endpoint + '?' + queryString, token, true)
}

export function postResendDocument(documentId, token) {
  const endpoint = '/documents/resendDocument/' + documentId;
  return fetch.POST(endpoint, token, null, true);
}

export function postResendDocumentToSefLink(documentId, token) {
  const endpoint = '/documents/resendDocumentToEdex/' + documentId;
  return fetch.POST(endpoint, token, null, true);
}

function mapToParameters(options, advancedSearchParams) {
  let parameters = {}

  if (options.sortBy && options.sortBy.length !== 0) {
    parameters.orderBy = options.sortBy
  }

  let sortDesc = options.sortDesc[0]
  if (sortDesc !== null) {
    parameters.orderAsc = !sortDesc
  }

  if (options.pageNum !== null) {
    parameters.pageNum = options.pageNum
  }

  if (options.pageSize !== null) {
    parameters.pageSize = options.pageSize
  }

  if (options.query) {
    parameters.query = options.query
  }

  if (options.documentSearchType) {
    parameters.searchType = options.documentSearchType;
  }

  if (advancedSearchParams.datumDokumentaOd) {
    parameters.datumG = convertDateString(advancedSearchParams.datumDokumentaOd);
  }

  if (advancedSearchParams.datumDokumentaDo) {
    parameters.datumL = convertDateString(advancedSearchParams.datumDokumentaDo);
  }

  if (advancedSearchParams.datumKreiranjaOd) {
    parameters.createdAtG = convertDateString(advancedSearchParams.datumKreiranjaOd);
  }

  if (advancedSearchParams.datumKreiranjaDo) {
    parameters.createdAtL = convertDateString(advancedSearchParams.datumKreiranjaDo);
  }

  if (advancedSearchParams.sender && advancedSearchParams.sender.id) {
    parameters.senderId = advancedSearchParams.sender.id;
  }

  if (advancedSearchParams.receiver && advancedSearchParams.receiver.id) {
    parameters.receiverId = advancedSearchParams.receiver.id;
  }

  if (advancedSearchParams.receiverCompany && advancedSearchParams.receiverCompany.id) {
    parameters.receiverCompanyId = advancedSearchParams.receiverCompany.id;
  }

  if (advancedSearchParams.userProcessed !== null &&
      advancedSearchParams.userProcessed !== undefined &&
      advancedSearchParams.userProcessed !== "null") {
    parameters.userProcessed = advancedSearchParams.userProcessed;
  }

  if (advancedSearchParams.docType) {
    parameters.documentType = advancedSearchParams.docType;
  }

  return parameters
}

function convertDateString(dateString) {
  const [year, month, day] = dateString.split('-')
  return `${day}-${month}-${year}`;
}
