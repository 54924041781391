<template>
  <v-card v-if="loading === false" width="100%">
    <v-card-title>
      {{ getCustomerProperty('name') }}
    </v-card-title>

    <v-card-subtitle>
      Kompanija: {{ getCustomerProperty('company.companyName') }}
    </v-card-subtitle>

    <v-card-subtitle>
      Opis: {{ getCustomerProperty('description') }}
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col>
          <v-list dense>
            <v-list-item v-for="item in customerInfo1" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getCustomerProperty(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col>
          <v-list dense>
            <v-list-item v-for="item in customerInfo2" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getCustomerProperty(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <v-list dense>
        <v-list-item class="compact-form">
          <v-row>
            <v-col cols="12" sm="4">
              <v-switch hide-details="auto" class="disable-events" label="Slanje na email" :input-value="getCustomerProperty('sendByEmail')"></v-switch>
            </v-col>
            <v-col cols="12" sm="4">
              <v-switch hide-details="auto" class="disable-events" label="Aktivan" :input-value="getCustomerProperty('active')"></v-switch>
            </v-col>
            <v-col cols="12" sm="4">
              <v-switch hide-details="auto" class="disable-events" label="Orderable" :input-value="getCustomerProperty('orderable')"></v-switch>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-card-actions>
      <edit-customer-dialog :customer="customer" v-if="loading === false" @update="updateParentComponent"/>
    </v-card-actions>
  </v-card>
</template>

<script>
import EditCustomerDialog from './EditCustomerDialog'

export default {
  name: 'CustomerDetails',
  components: {
    EditCustomerDialog
  },
  data: function () {
    return {
      loading: false,
      customerInfo1: [
        { label: "Grad", propertyName: 'town', icon: "mdi-city-variant" },
        { label: "Adresa", propertyName: 'address', icon: "mdi-map-marker" },
        { label: "Telefon", propertyName: 'telephone', icon: "mdi-phone" },
        { label: "Kontakt osoba", propertyName: 'contactPerson', icon: "mdi-account-tie" },
        { label: "Email", propertyName: 'email', icon: "mdi-email" },
        { label: "GLN", propertyName: 'gln', icon: "mdi-barcode" },
        { label: "LGLN", propertyName: 'lgln', icon: "mdi-barcode" }
      ],
      customerInfo2: [
        { label: "Naselje ID", propertyName: 'naseljeId', icon: "mdi-city" },
        { label: "Template SW", propertyName: 'templateSw', icon: "mdi-laptop" },
        { label: "Software ID", propertyName: 'softwareId', icon: "mdi-laptop" },
        { label: "Alternativni primalac", propertyName: 'altR', icon: "" },
        { label: "Tip", propertyName: 'typeId', icon: "" },
        { label: "Pair", propertyName: 'pair', icon: "" }
      ]
    }
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  methods: {
    getCustomerProperty(propertyName) {
      let booleanProperties = [ "active", "orderable", "sendByEmail"];
      if (propertyName in booleanProperties ) {
        return this.customer[propertyName] ? true : false;
      }

      if (propertyName === "typeId") {
        let customerTypesMap = {
          1: "1 - Kupac",
          2: "2 - Dobavljač",
          3: "3 - Budžetski korisnik"
        };

        return customerTypesMap[this.customer.typeId]
      }

      if (propertyName === "pair") {
        if (!this.customer.pair)
          return null;

        let pairTypesMap = {
          1: "1 - Original",
          2: "2 - Primalac",
          4: "4 - AUB"
        }

        return pairTypesMap[this.customer.pair]
      }

      if (propertyName.includes(".")) {
        let parts = propertyName.split(".");
        let firstPart = parts[0];
        let secondPart = parts[1];

        let firstPartValue = this.customer[firstPart];
        return firstPartValue[secondPart];
      }

      return this.customer[propertyName];
    },
    updateParentComponent (customerId) {
      this.$emit('updateCustomer', customerId);
    }
  }
}
</script>

<style lang="css" scoped>
.disable-events {
  pointer-events: none
}

.styled-input label[for] {
  height: 40px;
  font-size: 20pt;
}

.compact-form {
    transform: scale(0.875);
    transform-origin: left;
}
</style>
