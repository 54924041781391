<template>
  <div class="ma-0 pa-0">
    <v-dialog v-model="dialogFindCompany" @keydown.esc="dialogFindCompany = false" persistent max-width="60%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="primary"
          :small="isSmall"
          :fab="isCircle"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ buttonText }}
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </template>
      <v-card height="800px">
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="dialogFindCompany = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ toolbarText }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-card class="mt-4">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="queryString"
                append-icon="mdi-magnify"
                label="Pretraga"
                single-line
                hide-details
                clearable
                @keyup.enter.prevent="onEnterSearch"
                @click:clear="onClearSearch()"
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="listOfCompanies"
              :server-items-length="options.page * options.itemsPerPage + 1"
              :options.sync="options"
              :loading="loading"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50],
                'items-per-page-text': 'Broj redova po stranici:',
              }"
              no-data-text="Nema rezultata"
              loading-text="Učitavanje podataka... Molimo sačekajte"
              class="elevation-1 row-pointer"
              width="100%"
              @click:row="onRowClick"
            >
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as apiCompanies from "../api/companies.js";

export default {
  name: "FindCompanyDialog",
  data: function () {
    return {
      dialogFindCompany: false,
      loading: true,
      options: {},
      queryString: null,
      listOfCompanies: [],
      headers: [
        { text: "ID", value: "id", width: "10%" },
        { text: "Naziv", value: "companyName", width: "25%" },
        { text: "PIB", value: "pib", width: "10%" },
        { text: "Adresa", value: "address" },
        { text: "Grad", value: "town", width: "20%" },
      ],
    };
  },
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    toolbarText: {
      type: String,
      required: true,
    },
    isSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    isCircle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    options: {
      handler() {
        this.options.search = this.queryString;
        this.getCompaniesFromAPI();
      },
      deep: true,
    },
  },
  methods: {
    getCompaniesFromAPI() {
      this.loading = true;

      apiCompanies
        .getList(this.options, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          this.listOfCompanies = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.listOfCompanies = [];
          this.loading = false;
        });
    },

    onEnterSearch() {
      this.options.search = this.queryString;
      this.options.page = 1;
      this.getCompaniesFromAPI();
    },

    onClearSearch() {
      this.options.search = "";
      this.queryString = null;
      this.getCompaniesFromAPI();
    },

    onRowClick(company) {
      this.dialogFindCompany = false;
      this.$emit("update", company);
    },
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
