<template>
  <v-container>
    <v-card>
      <v-card-title>
        Isporučna mesta
      </v-card-title>
      <v-card-subtitle>
        <v-row justify="end">
          <v-col cols="12" sm="4" class="py-0">
            <v-text-field
              v-model="queryString"
              append-icon="mdi-magnify"
              label="Pretraga"
              single-line
              hide-details
              clearable
              @keyup.enter.prevent='onEnterSearch'
              @click:clear='onClearSearch()'
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="12" sm="4" class="py-0 mt-2">
            <v-switch v-model="isSearchByID" label="Pretraga po ID" @click="onSwitchSearch">
            </v-switch>
          </v-col>
          <v-col cols="12" sm="4" class="py-0 mt-2">
            <v-switch v-model="isCodeSearch" label="Pretraga po šifri isporučnog mesta" @click="onSwitchSearch">
            </v-switch>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-data-table
      :headers="headers"
      :items="listOfCustomers"
      :server-items-length="options.page * options.itemsPerPage + 1"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
      @click:row="onRowClick"
      >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import * as apiCustomers from '../api/customers.js'
import * as apiLocalCodes from '../api/localcodes.js'

export default {
  name: "CustomersList",
  data: function () {
    return {
      loading: true,
      options: {
        sortDesc: [true]
      },
      queryString: null,
      isCodeSearch: false,
      isSearchByID: false,

      listOfCustomers: [],
      headers: [
          { text: 'ID', value: 'id', width: "5%" },
          { text: 'Naziv', value: 'name', width: "25%" },
          { text: 'Adresa', value: 'address', width: "15%" },
          { text: 'Grad', value: 'town', width: "10%" },
          { text: 'Opis', value: 'description' },
          { text: 'Kompanija', value: 'company.companyName', width: "15%" },
          { text: 'Lokalni kod', value: 'localCode', width: "15%" }
      ]
    }
  },
  props: {
    companyId: {
      type: Number,
      required: false
    }
  },
  watch: {
    options: {
      handler () {
        this.options.search = this.queryString;
        this.getCustomersFromAPI()
      },
      deep: true,
    }
  },
  mounted () {
    this.getCustomersFromAPI()
  },
  methods: {
    getCustomersFromAPI() {
      this.loading = true

      if (this.companyId) {
        this.options.companyId = this.companyId;
      }

      apiCustomers.getList(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.listOfCustomers = responseData;
          this.listOfCustomers.forEach((customer) => {
            this.$set(customer, "localCode", "");
          });

          this.getLocalCodes();
          this.loading = false;
        })
        .catch(() => {
          this.listOfCustomers = []
          this.loading = false
        })
    },

    onEnterSearch() {
      this.options.search = this.queryString
      this.options.page = 1
      this.getCustomersFromAPI()
    },

    onClearSearch() {
      this.options.search = ''
      this.queryString = ''
      this.getCustomersFromAPI()
    },

    onRowClick(row) {
      let route = this.$router.resolve({ path: '/customers/' + row.id });
      window.open(route.href, '_blank');
    },

    getLocalCodes() {
      this.listOfCustomers.forEach((customer) => {
        apiLocalCodes.getLocalCodeByCustomerId(customer.id, this.$store.getters.token)
          .then(response => response.json())
          .then(responseData => {
            if (responseData.length == 0) {
              customer.localCode = "";
              return;
            }

            let firstLocalCode = responseData[0];
            customer.localCode = firstLocalCode.code;
          })
          .catch(() => {
            customer.localCode = "";
          })
      });
    },

    onSwitchSearch() {
      this.options.page = 1;
      this.options.isCodeSearch = this.isCodeSearch;
      this.options.isSearchByID = this.isSearchByID;
      this.options.search = this.queryString;
      this.getCustomersFromAPI();
    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
