<template>
  <div>
    <v-dialog v-model="dialogEditCustomer" @keydown.esc="onCancel" persistent max-width="700px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="primary mt-3" dark v-bind="attrs" v-on="on">
          Izmeni
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Izmena isporučnog mesta
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-list dense>
            <v-list-item v-for="item in customerTextValues" :key="item.label">
              <v-text-field class="pt-0 pb-0" :prepend-icon="item.icon" :label="item.label" v-model="updatedCustomer[item.value]" ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-textarea
              prepend-icon="mdi-text-subject"
              label="Opis"
              v-model="updatedCustomer.description"
              filled auto-grow class="pt-0 pb-0">
              </v-textarea>
            </v-list-item>

            <v-list-item class="mb-4 mt-0">
              <v-row>
                <v-col cols="12" sm="4">
                  <v-switch hide-details="auto" label="Slanje na email" v-model="updatedCustomer.sendByEmail"></v-switch>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-switch hide-details="auto" label="Aktivan" v-model="updatedCustomer.active"></v-switch>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-switch hide-details="auto" label="Orderable" v-model="updatedCustomer.orderable"></v-switch>
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item>
              <v-autocomplete :items="swTemplates" v-model="updatedCustomer.templateSw" item-text="name" item-value="id" label="Template Software *" outlined></v-autocomplete>
            </v-list-item>

            <v-list-item>
              <v-autocomplete :items="software" v-model="updatedCustomer.softwareId" item-text="name" item-value="id" label="Software ID" outlined></v-autocomplete>
            </v-list-item>

            <v-list-item>
              <v-select
                :items="customerTypes"
                v-model="updatedCustomer.typeId"
                item-text="text"
                item-value="value"
                label="Tip isporučnog mesta *"
                required
                outlined></v-select>
            </v-list-item>
            <v-list-item>
              <v-select
                :items="pairTypes"
                v-model="updatedCustomer.pair"
                item-text="text"
                item-value="value"
                label="Tip uparivanja"
                outlined></v-select>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error ma-2 white--text" text @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn class="success ma-2 white--text" text @click="dialogConfirmSave = true" :loading="puttingToApi">
            Sačuvaj izmene
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmSave" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Da li želite da sačuvate promene?
        </v-card-title>

         <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text plain ma-2" text @click="dialogConfirmSave = false">
            Odustani
          </v-btn>
          <v-btn class="green--text plain ma-2" text @click="onConfirmEdit" :loading="puttingToApi">
            Sačuvaj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as customersApi from '../api/customers'
import * as utilsApi from '../api/utils'

export default {
  name: 'EditCustomerDialog',
  data: function () {
    return {
      dialogEditCustomer: false,
      dialogConfirmSave: false,
      puttingToApi: false,
      updatedCustomer: {},
      customerTextValues: [
        { label: "Naziv", value: 'name', icon: "mdi-alphabetical" },
        { label: "GLN", value: 'gln', icon: "mdi-barcode" },
        { label: "LGLN", value: 'lgln', icon: "mdi-barcode" },
        { label: "Grad", value: 'town', icon: "mdi-city-variant" },
        { label: "Adresa", value: 'address', icon: "mdi-map-marker" },
        { label: "Naselje ID", value: 'naseljeId', icon: "mdi-city" },
        { label: "Telefon", value: 'telephone', icon: "mdi-phone" },
        { label: "Kontakt osoba", value: 'contactPerson', icon: "mdi-account-tie" },
        { label: "Email", value: 'email', icon: "mdi-email" },
        { label: "Alternativni primalac", value: 'altR', icon: "" },
      ],
      customerTypes: [
        { value: 1, text: "1 - Kupac" },
        { value: 2, text: "2 - Dobavljač" }
      ],
      pairTypes: [
        { value: 1, text: "1 - Original" },
        { value: 2, text: "2 - Primalac" },
        { value: 4, text: "4 - AUB" }
      ],
      software: [],
      swTemplates: []
    }
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  watch: {
    dialogEditCustomer(newValue) {
      if (newValue === true) {
        this.shallowCopy();
      }
    }
  },
  mounted () {
    // Kopiranje objekta
    this.getSoftware();
    this.getSwTemplates();
    this.shallowCopy();
  },
  methods: {
    shallowCopy() {
      this.updatedCustomer = JSON.parse(JSON.stringify(this.customer));
    },
    onCancel() {
      this.shallowCopy();
      this.dialogEditCustomer = false;
    },
    onConfirmEdit() {
      // TODO: validacija pre slanja na API

      this.puttingToApi = true;
      this.preprocessData();

      customersApi.putCustomer(this.customer.id, this.updatedCustomer, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          // loading na dugmetu
          this.puttingToApi = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmSave = false;
          this.dialogEditCustomer = false;

          // brisanje unetih podataka za novu kompaniju
          this.updatedCustomer = {};

          this.$emit('update', responseData.id);
        })
        .catch(() => {
          this.puttingToApi = false;
        });
    },
    preprocessData () {
      this.updatedCustomer.templateSw = parseInt(this.updatedCustomer.templateSw);
      this.updatedCustomer.typeId = parseInt(this.updatedCustomer.typeId);

      if (this.updatedCustomer.altR) {
        this.updatedCustomer.altR = parseInt(this.updatedCustomer.altR);
      }

      if (this.updatedCustomer.softwareId) {
        this.updatedCustomer.softwareId = parseInt(this.updatedCustomer.softwareId);
      }

      if (this.updatedCustomer.softwareId === 0) {
        this.updatedCustomer.softwareId = null;
      }

      this.updatedCustomer.active = (this.updatedCustomer.active) ? 1 : 0;
      this.updatedCustomer.orderable = (this.updatedCustomer.orderable) ? 1 : 0;
      this.updatedCustomer.sendByEmail = (this.updatedCustomer.sendByEmail) ? 1 : 0;
    },
    getSoftware() {
      utilsApi.getSoftware(this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.software = responseData;
        })
        .catch(() => {

        });
    },
    getSwTemplates() {
      utilsApi.getSwTemplates(this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.swTemplates = responseData;
        })
        .catch(() => {

        });
    }
  }
}
</script>
