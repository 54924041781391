<template>
  <div class="ma-0 pa-0">
    <v-dialog v-model="dialogFindCodebook" @keydown.esc="dialogFindCodebook = false" persistent max-width="60%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="primary"
          :small="isSmall"
          :fab="isCircle"
          :disabled="disabled"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ buttonText }}
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="dialogFindCodebook = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ toolbarText }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-title>
          Šifarnici
          <v-spacer></v-spacer>
          <v-text-field
            v-model="queryString"
            append-icon="mdi-magnify"
            label="Pretraga"
            single-line
            hide-details
            clearable
            @keyup.enter.prevent='onEnterSearch'
            @click:clear='onClearSearch()'
          ></v-text-field>
        </v-card-title>
        <v-data-table
        :headers="headers"
        :items="listOfCodebooks"
        :server-items-length="options.page * options.itemsPerPage + 1"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50],
          'items-per-page-text': 'Broj redova po stranici:'
        }"
        no-data-text="Nema rezultata"
        loading-text="Učitavanje podataka... Molimo sačekajte"
        class="elevation-1 row-pointer"
        width="100%"
        @click:row="onRowClick"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>{{ format_date(item.createdAt) }}</span>
          </template>

          <template v-slot:[`item.updatedAt`]="{ item }">
            <span>{{ format_date(item.updatedAt) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as apiCodebooks from "../api/codebooks.js";
import moment from 'moment';

export default {
  name: "FindCodebookDialog",
  data: function () {
    return {
      dialogFindCodebook: false,
      loading: true,
      options: {},
      queryString: null,
      listOfCodebooks: [],
      headers: [
        { text: 'ID', value: 'id', width: "10%" },
        { text: 'Naziv fajla', value: 'filename', width: "20%" },
        { text: 'Datum kreiranja', value: 'createdAt', width: "15%" },
        { text: 'Datum ažuriranja', value: 'updatedAt', width: "15%" },
        { text: 'Kompanija', value: 'company.companyName', width: "20%" },
        { text: 'PIB', value: 'company.pib', width: "20%" },
      ]
    };
  },
  props: {
    companyId: {
      type: Number,
      required: false,
      default: 0
    },
    buttonText: {
      type: String,
      required: true,
    },
    toolbarText: {
      type: String,
      required: true,
    },
    isSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    isCircle: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    options: {
      handler() {
        this.options.search = this.queryString;
        this.getCodebooksFromAPI();
      },
      deep: true,
    },
  },
  methods: {
    getCodebooksFromAPI() {
      this.loading = true;

      apiCodebooks
      .getByCompany(this.companyId, this.$store.getters.token)
        //.getListByCompany(this.options, this.companyId, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          this.listOfCodebooks = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.listOfCodebooks = [];
          this.loading = false;
        });
    },

    onRowClick(codebook) {
      this.dialogFindCodebook = false;
      this.$emit("update", codebook);
    },

    format_date(value){
      if (value) {
        let dateString = moment(String(value)).format('DD.MM.YYYY');
        if (dateString === "01.01.0001") {
          return null;
        }
        else {
          return dateString;
        }
      }
    }
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
