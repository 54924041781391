import * as fetch from './fetch'

export function deleteCCC(id, token) {
  const endpoint = '/customerscustcodes/' + id;
  return fetch.DELETE(endpoint, token, true);
}

export function putCCC(id, updatedCCC, token) {
  const endpoint = '/customerscustcodes/' + id;
  return fetch.PUT(endpoint, token, updatedCCC)
}


export function postCCC(newCCC, token) {
  const endpoint = '/customerscustcodes'
  return fetch.POST(endpoint, token, newCCC)
}

export function getList(options, customerId, isSender, token) {
  let parameters = mapToParameters(options, customerId, isSender)

  const endpoint = '/customerscustcodes/filter'
  let queryString = new URLSearchParams(parameters).toString()
  return fetch.GET(endpoint + '?' + queryString, token, true)
}

export function getSupplierBuyerCCCs(supplierCustomerId, buyerCompanyId, token) {
  let parameters = mapToParametersSupplerBuyerCCCs(supplierCustomerId, buyerCompanyId);

  const endpoint = '/customerscustcodes/supplierBuyerCCCs';
  let queryString = new URLSearchParams(parameters).toString();
  return fetch.GET(endpoint + '?' + queryString, token, true);
}

function mapToParameters(options, customerId, isSender) {
  let parameters = {}

  if (options.sortBy.length !== 0) {
    parameters.orderBy = options.sortBy
  }

  let sortDesc = options.sortDesc[0]
  if (sortDesc !== null) {
    parameters.orderAsc = !sortDesc
  }

  if (options.page !== null) {
    parameters.pageNum = options.page
  }

  if (options.itemsPerPage !== null) {
    parameters.pageSize = options.itemsPerPage
  }

  if (options.search) {
    parameters.query = options.search
  }

  if (isSender) {
    parameters.senderId = customerId;
  }
  else {
    parameters.receiverId = customerId;
  }

  return parameters
}

function mapToParametersSupplerBuyerCCCs(supplierCustomerId, buyerCompanyId) {
  let parameters = {}

  if (supplierCustomerId !== null) {
    parameters.supplierCustomerId = supplierCustomerId;
  }

  if (buyerCompanyId !== null) {
    parameters.buyerCompanyId = buyerCompanyId;
  }

  return parameters
}
