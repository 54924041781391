<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8">
        <v-row align-center column justify-center>
          <v-card width="80%" class="mb-6">
            <v-card-title> Dodavanje centralnog korisnika </v-card-title>

            <v-card-subtitle>
              <v-row column justify-left>
                <v-col sm="3" class="mt-2">
                  <span class="text-subtitle-1 font-weight-medium">Kompanija:</span>
                  {{ selectedCompany.companyName }}
                </v-col>
                <v-col sm="2">
                  <find-company-dialog
                    @update="companySelected"
                    icon="mdi-domain"
                    buttonText="Izaberite"
                    toolbarText="Biranje kompanije"
                  />
                </v-col>
                <v-spacer />
              </v-row>
            </v-card-subtitle>

            <v-card-text>
              <v-row class="mt-2">
                <v-col sm="12" md="6">
                  <v-row>
                    <v-col sm="12" class="pt-0 pb-0">
                      <v-text-field label="Ime" required outlined
                        v-model="newUser.name"
                      ></v-text-field>
                    </v-col>

                    <v-col sm="12" class="pt-0 pb-0">
                      <v-text-field label="Korisničko ime" required outlined
                        v-model="newUser.username"
                      ></v-text-field>
                    </v-col>

                    <v-col sm="12" class="pt-0 pb-0">
                      <v-text-field label="Email" required outlined
                        v-model="newUser.email"
                      ></v-text-field>
                    </v-col>

                    <v-col sm="12" class="pt-0 pb-0">
                      <v-select v-model="newUser.role"
                        :items="roles" item-text="text" item-value="value"
                        label="Uloga" required outlined>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-if="customers !== null">
              <v-card-title> Biranje isporučnih mesta vezanih za novog korisnika </v-card-title>

              <v-list dense>
                <v-list-item v-for="customer in customers" :key="customer.id">
                  <v-list-item-avatar>
                    <v-checkbox v-model="customer.selected">
                    </v-checkbox>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>Naziv: {{ customer.name }}</v-list-item-title>
                    <v-list-item-subtitle>Adresa: {{customer.address}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Grad: {{customer.town}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error ma-2 white--text" text @click="onCancel">
                Odustani
                <v-icon right>mdi-close</v-icon>
              </v-btn>
              <v-btn class="primary ma-2 white--text" text @click="onAddUser" :loading="postingToApi">
                Dodaj korisnika
                <v-icon right>mdi-plus</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>

          <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show">
          </snackbar>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiUsers from "../api/users";
import * as apiCustomers from "../api/customers";
import FindCompanyDialog from "../components/FindCompanyDialog.vue";
import Snackbar from "../components/Snackbar.vue"

export default {
  name: "UserAddPage",
  components: {
    FindCompanyDialog,
    Snackbar
  },
  data: function () {
    return {
      newUser: {},
      postingToApi: false,
      selectedCompany: {},
      customers: null,

      snackbar: {
        show: false,
        text: "",
        isError: false
      },

      roles: [
        { value: 1, text: "1 - FTP" },
        { value: 2, text: "2 - Portali" },
      ],
    };
  },
  methods: {
    onAddUser() {
      // TODO: validacija pre slanja na API
      this.postingToApi = true;

      let selectedCustomers = this.customers.filter(c => c.selected === true).map(c => c.id);

      this.newUser.companyId = this.selectedCompany.id;

      // kreiranje requesta
      let request = this.newUser;
      request.customerIds = selectedCustomers;

      apiUsers
        .postUser(request, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;

          this.showNotification("Dodat korisnik: " + responseData.username);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;
        });
    },
    getCustomersOfCompany(companyId) {
      apiCustomers
        .getCustomersOfCompany(companyId, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          this.customers = responseData;
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }
        });
    },
    onCancel() {
      this.$router.push('/users');
    },
    companySelected(company) {
      this.selectedCompany = company;

      this.getCustomersOfCompany(company.id);
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },
  },
};
</script>
