<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="11">
        <v-row align-center column justify-center>
          <v-row class="mt-2 mb-2">
            <v-progress-circular v-if="loading === true" indeterminate>
            </v-progress-circular>
          </v-row>

          <customer-details v-bind:customer="customer" v-if="finishedLoading === true" @updateCustomer="getCustomer"/>
          <v-row>
            <v-col cols="12" md="6">
              <farmalogist-for-customer :customerId="customer.id" v-if="finishedLoading === true" />
            </v-col>
            <v-col cols="12" md="6">
              <local-codes-list v-bind:customer="customer" v-if="finishedLoading === true"/>
            </v-col>
          </v-row>

          <customers-cust-codes-list v-bind:customerId="customer.id" v-if="finishedLoading === true" />
          <add-customers-cust-code-dialog :customerSender="customer" v-if="finishedLoading === true" @update="getCustomer"/>

          <users-list :customerId="customer.id" v-if="finishedLoading === true" />
          <portal-users-list :customerId="customer.id" v-if="finishedLoading === true"/>

          <v-col cols="12" v-if="finishedLoading === true">
            <v-card elevation="4">
              <v-card-actions>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-btn @click="showDocumentsList" class="mt-4">Učitaj dokumente</v-btn>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4">
                    <v-switch v-model="showDocumentsAsSender" label="Prikaz poslatih dokumenata" @click="onSwitchShowDocumentsSide">
                    </v-switch>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>

          <documents-list v-if="showDocuments" :advancedSearch="documentsAdvancedSearch"/>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiCustomers from '../api/customers.js'
import CustomerDetails from '../components/CustomerDetails'
import FarmalogistForCustomer from '../components/FarmalogistForCustomer'
import CustomersCustCodesList from '../components/CustomersCustCodesList'
import AddCustomersCustCodeDialog from '../components/AddCustomersCustCodeDialog'
import LocalCodesList from '../components/LocalCodesList'
import UsersList from '../components/UsersList'
import PortalUsersList from '../components/PortalUsersList.vue'
import DocumentsList from '../components/DocumentsList.vue'

export default {
  name: "CompanyDetailsPage",
  components: {
    CustomerDetails,
    FarmalogistForCustomer,
    CustomersCustCodesList,
    AddCustomersCustCodeDialog,
    LocalCodesList,
    UsersList,
    PortalUsersList,
    DocumentsList
  },
  data: function () {
    return {
      customer: null,
      loading: false,
      finishedLoading: false,

      showDocuments: false,
      showDocumentsAsSender: true,
      documentsAdvancedSearch: {
        sender: {},
        receiver: {}
      }
    }
  },
  mounted () {
    const id = this.$route.params.id;
    this.getCustomer(id);
  },
  methods: {
    getCustomer(id) {
      this.loading = true;

      apiCustomers.getCustomer(id, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.customer = responseData;

          this.loading = false;
          this.finishedLoading = true;
        })
        .catch(() => {
          this.customer = null;
          console.log("Greska! Nije nadjen customer");

          this.loading = false;
        })
    },

    onSwitchShowDocumentsSide() {
      if (this.showDocumentsAsSender) {
        this.documentsAdvancedSearch.sender = this.customer;
        this.documentsAdvancedSearch.receiver = null;
      }
      else {
        this.documentsAdvancedSearch.receiver = this.customer;
        this.documentsAdvancedSearch.sender = null;
      }
    },

    showDocumentsList() {
      this.showDocuments = true;
      this.onSwitchShowDocumentsSide();
    }
  }
}
</script>
