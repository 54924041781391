<template>
  <div>
    <v-dialog v-model="dialogNewUserCustomerLink" @keydown.esc="onCancel" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-if="useIcon" v-bind="attrs" v-on="on">mdi-plus</v-icon>

        <v-btn v-else class="primary mb-3" dark v-bind="attrs" v-on="on">
          Dodaj vezu
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Dodavanje veze između korisnika portala i isporučnog mesta
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-row class="mt-4">
                  <v-col cols="12" sm="4" class="pa-0">
                    <v-list>
                      <v-list-item class="pa-0">
                        <v-list-item-icon><v-icon>mdi-export</v-icon></v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{ chosenCustomer.name }}</v-list-item-title>
                          <v-list-item-subtitle>Isporučno mesto</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>

                  <v-col cols="12" sm="3" class="ma-0">
                    <find-customer-dialog
                      @update="customerSelected"
                      icon="mdi-plus"
                      buttonText="Izaberi"
                      toolbarText="Izaberi isporučno mesto"/>
                  </v-col>
                </v-row>

                <v-row class="mt-4">
                  <v-col cols="12" sm="4" class="pa-0">
                    <v-list>
                      <v-list-item class="pa-0">
                        <v-list-item-icon><v-icon>mdi-export</v-icon></v-list-item-icon>

                        <v-list-item-content>
                          <v-list-item-title>{{ chosenUser.username }}</v-list-item-title>
                          <v-list-item-subtitle>Korisnik portala</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>

                  <v-col cols="12" sm="3" class="ma-0">
                    <find-portal-user-dialog
                      @update="userSelected"
                      icon="mdi-plus"
                      buttonText="Izaberi"
                      toolbarText="Izaberi korisnika portala"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error ma-2 white--text" text @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn class="primary ma-2 white--text" text @click="onAddUserCustomerLink" :loading="postingToApi">
            Dodaj vezu
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="5000" color="success">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import * as apiPortalUsers from '../api/portalusers.js'
import FindCustomerDialog from './FindCustomerDialog.vue';
import FindPortalUserDialog from './FindPortalUserDialog.vue';

export default {
  name: "AddPortalUserCustomerLinkDialog",
  components: {
    FindCustomerDialog,
    FindPortalUserDialog
  },
  data: function () {
    return {
      dialogNewUserCustomerLink: false,
      postingToApi: false,

      chosenCustomer: {
        id: null,
        name: null
      },
      chosenUser: {
        id: null,
        username: null
      },

      snackbar: false,
      snackbarText: "",
    }
  },
  props: {
    customer: {
      type: Object,
      required: false,
      default: null
    },
    portalUser: {
      type: Object,
      required: false,
      default: null
    },
    useIcon: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    if (this.customer !== null) {
      this.chosenCustomer = this.customer;
    }

    if (this.portalUser !== null) {
      this.chosenUser = this.portalUser;
    }
  },
  methods: {
    onAddUserCustomerLink () {
      // TODO: validacija pre slanja na API

      this.postingToApi = true;

      let newUserCustomerLink = {
        portalUserId: this.chosenUser.id,
        customerId: this.chosenCustomer.id
      };

      apiPortalUsers.postPortalUserCustomerLink(newUserCustomerLink, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          // loading na dugmetu
          this.postingToApi = false;

          // zatvaranje modalnog prozora
          this.dialogNewUserCustomerLink = false;

          // prikaz snackbar obavestenja
          this.snackbarText = "Dodata veza sa ID-jem: " + responseData.id;
          this.snackbar = true;

          this.$emit('update');
        })
        .catch(() => {
          this.postingToApi = false;
        });
    },
    onCancel () {
      this.dialogNewUserCustomerLink = false;
    },

    customerSelected(chosenCustomer) {
      this.chosenCustomer = chosenCustomer;
    },

    userSelected(chosenUser) {
      this.chosenUser = chosenUser;
    },
  }
}
</script>
