var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" Isporučna mesta vezana za korisnika "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pretraga","single-line":"","hide-details":"","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.onEnterSearch($event)},"click:clear":function($event){return _vm.onClearSearch()}},model:{value:(_vm.queryString),callback:function ($$v) {_vm.queryString=$$v},expression:"queryString"}})],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.listOfUserCustomerLinks,"server-items-length":_vm.options.page * _vm.options.itemsPerPage + 1,"options":_vm.options,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      },"no-data-text":"Nema rezultata","loading-text":"Učitavanje podataka... Molimo sačekajte","width":"100%"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.format_datetime(item.createdAt)))])]}},{key:"item.updatedAt",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.format_datetime(item.updatedAt)))])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.onRowClick(item)}}},[_vm._v("mdi-trash-can")])]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogConfirmDelete),callback:function ($$v) {_vm.dialogConfirmDelete=$$v},expression:"dialogConfirmDelete"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Da li želite da obrišete vezu između korisnika i isporučnog mesta? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"red--text plain ma-2",attrs:{"outlined":""},on:{"click":_vm.cancelDeleting}},[_vm._v(" Ne ")]),_c('v-btn',{staticClass:"error ma-2",attrs:{"text":"","loading":_vm.deleting},on:{"click":_vm.onConfirmDelete}},[_vm._v(" Da "),_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }