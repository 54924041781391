<template>
  <v-card v-if="loading === false" width="100%">
    <v-card-title>
      {{ getCompanyProperty('companyName') }}
    </v-card-title>

    <v-card-subtitle>
      PIB: {{ getCompanyProperty('pib') }}
    </v-card-subtitle>

    <v-card-subtitle>
      Opis: {{ getCompanyProperty('description') }}
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
           <v-list dense>
            <v-list-item :three-line="item.propertyName === 'address'" v-for="item in companyInformationPart1" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getCompanyProperty(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" md="6">
           <v-list dense>
            <v-list-item v-for="item in companyInformationPart2" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getCompanyProperty(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <edit-company-dialog :company="company" v-if="loading === false" @update="updateParentComponent"/>
    </v-card-actions>
  </v-card>
</template>

<script>
import EditCompanyDialog from './EditCompanyDialog'

export default {
  name: 'CompanyDetails',
  components: {
    EditCompanyDialog
  },
  data: function () {
    return {
      loading: false,
      companyInformationPart1: [
        { label: "Grad", propertyName: 'town', icon: "mdi-city-variant" },
        { label: "Adresa", propertyName: 'address', icon: "mdi-map-marker" },
        { label: "GLN", propertyName: 'gln', icon: "mdi-barcode" }
      ],
      companyInformationPart2: [
        { label: "Telefon", propertyName: 'telephone', icon: "mdi-phone" },
        { label: "Kontakt osoba", propertyName: 'contactPerson', icon: "mdi-account-tie" },
        { label: "Email", propertyName: 'email', icon: "mdi-email" }
      ]
    }
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  methods: {
    getCompanyProperty(propertyName) {
      return this.company[propertyName];
    },
    updateParentComponent (companyId) {
      this.$emit('updateCompany', companyId);
    }
  }
}
</script>
