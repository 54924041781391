var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-3 mb-3",attrs:{"width":"100%"}},[_c('v-card-title',[_vm._v(" Kodovi veze ")]),_c('v-card-subtitle',[_c('v-row',[_c('v-col',{attrs:{"sm":"4"}},[_c('find-customer-dialog',{attrs:{"icon":"","customerType":2,"buttonText":"Izaberi dobavljača","toolbarText":"Biranje isporučnog mesta dobavljača"},on:{"update":_vm.supplierCustomerSelected}})],1),_c('v-col',{attrs:{"sm":"4"}},[_c('find-company-dialog',{attrs:{"icon":"","buttonText":"Izaberi kompaniju kupca","toolbarText":"Biranje kompanije kupca"},on:{"update":_vm.buyerCompanySelected}})],1),_c('v-col',{attrs:{"sm":"4"}},[_c('v-text-field',{attrs:{"label":"Pretraga"},model:{value:(_vm.queryString),callback:function ($$v) {_vm.queryString=$$v},expression:"queryString"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-subtitle-2",attrs:{"sm":"4"}},[_vm._v(" Dobavljač: "+_vm._s(_vm.customerSupplier.name)+" ")]),_c('v-col',{staticClass:"text-subtitle-2",attrs:{"sm":"4"}},[_vm._v(" Kompanija kupac: "+_vm._s(_vm.companyBuyer.companyName)+" ")])],1)],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.listOfCCC,"options":_vm.options,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': 'Broj redova po stranici:'
    },"search":_vm.queryString,"no-data-text":"Nema rezultata","loading-text":"Učitavanje podataka... Molimo sačekajte","width":"100%"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actionAdd",fn:function(ref){
    var item = ref.item;
return [_c('add-customers-cust-code-dialog',{attrs:{"customerSender":_vm.customerSupplier,"customerReceiver":item.customer,"useIcon":true},on:{"update":function($event){return _vm.getCCCFromAPI()}}})]}},{key:"item.actionEdit",fn:function(ref){
    var item = ref.item;
return [(item.customersCustCode !== null)?_c('edit-customer-code-dialog',{attrs:{"customersCustCode":item.customersCustCode},on:{"update":_vm.getCCCFromAPI}}):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }