<template>
  <div>
    <v-dialog v-model="dialogEditUser" @keydown.esc="onCancel" persistent max-width="700px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="primary mt-3" dark v-bind="attrs" v-on="on">
          Izmeni
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Izmena centralnog korisnika
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-list dense>
            <v-list-item v-for="item in userTextValues" :key="item.label">
              <v-text-field class="pt-0 pb-0" :prepend-icon="item.icon" :label="item.label" v-model="updatedUser[item.value]" ></v-text-field>
            </v-list-item>

            <v-list-item class="mb-4 mt-0">
              <v-row>
                <v-col cols="12" sm="4">
                  <v-switch hide-details="auto" label="Aktivan" v-model="updatedUser.active"></v-switch>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-switch hide-details="auto" label="Aktiviran" v-model="updatedUser.activated"></v-switch>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    :items="roles"
                    v-model="updatedUser.role"
                    item-text="text"
                    item-value="value"
                    label="Uloga"
                    outlined>
                  </v-select>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error ma-2 white--text" text @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn class="success ma-2 white--text" text @click="dialogConfirmSave = true" :loading="puttingToApi">
            Sačuvaj izmene
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmSave" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Da li želite da sačuvate promene?
        </v-card-title>

         <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text plain ma-2" text @click="dialogConfirmSave = false">
            Odustani
          </v-btn>
          <v-btn class="green--text plain ma-2" text @click="onConfirmEdit" :loading="puttingToApi">
            Sačuvaj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as apiUsers from '../api/users.js';

export default {
  name: 'EditUserDialog',
  data: function () {
    return {
      dialogEditUser: false,
      dialogConfirmSave: false,
      puttingToApi: false,
      updatedUser: {},
      userTextValues: [
        { label: "Naziv", value: 'name', icon: "mdi-alphabetical" },
        { label: "Email", value: 'email', icon: "mdi-email" },
        { label: "Korisničko ime", value: 'username', icon: "mdi-account" },
        { label: "Lozinka za Login", value: "password", icon: "mdi-lock" },
        { label: "Lozinka za API", value: "password2", icon: "mdi-lock" },
        { label: "Lozinka temp", value: "passwordTemp", icon: "mdi-lock" },
        { label: "Lozinka za FTP", value: "ftp", icon: "mdi-lock" },
        { label: "Kod", value: "code", icon: "mdi-lock" },
      ],
      roles: [
        { value: 1, text: "1 - Portali" },
        { value: 2, text: "2 - FTP" },
        { value: 3, text: "3 - ?" },
        { value: 5, text: "5 - ?" },
      ],
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  watch: {
    dialogEditUser(newValue) {
      if (newValue === true) {
        this.shallowCopy();
      }
    }
  },
  mounted () {
    // Kopiranje objekta
    this.shallowCopy();
  },
  methods: {
    shallowCopy() {
      this.updatedUser = JSON.parse(JSON.stringify(this.user));
    },
    onCancel() {
      this.shallowCopy();
      this.dialogEditUser = false;
    },
    onConfirmEdit() {
      // TODO: validacija pre slanja na API

      this.puttingToApi = true;
      this.preprocessData();

      apiUsers.updateUser(this.user.id, this.updatedUser, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          // loading na dugmetu
          this.puttingToApi = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmSave = false;
          this.dialogEditUser = false;

          // brisanje unetih podataka za novu kompaniju
          this.updatedUser = {};

          this.$emit('update', responseData.id);
        })
        .catch(() => {
          this.puttingToApi = false;
        });
    },
    preprocessData () {
      this.updatedUser.active = (this.updatedUser.active) ? 1 : 0;
      this.updatedUser.activated = (this.updatedUser.orderable) ? 1 : 0;
    },
  }
}
</script>
