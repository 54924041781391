<template>
  <v-card class="mt-3 mb-3" width="100%">
    <v-card-title>
      Kodovi veze
    </v-card-title>
    <v-card-subtitle>
      <v-row>
        <v-col sm="4">
          <find-customer-dialog
            @update="supplierCustomerSelected"
            icon=""
            :customerType="2"
            buttonText="Izaberi dobavljača"
            toolbarText="Biranje isporučnog mesta dobavljača"
          />
        </v-col>
        <v-col sm="4">
          <find-company-dialog
            @update="buyerCompanySelected"
            icon=""
            buttonText="Izaberi kompaniju kupca"
            toolbarText="Biranje kompanije kupca"
          />

        </v-col>

        <v-col sm="4">
          <v-text-field
            v-model="queryString"
            label="Pretraga"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="4" class="text-subtitle-2">
          Dobavljač: {{ customerSupplier.name }}
        </v-col>
        <v-col sm="4" class="text-subtitle-2">
          Kompanija kupac: {{ companyBuyer.companyName }}
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-data-table
      :headers="headers"
      :items="listOfCCC"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      :search="queryString"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
    >
      <template v-slot:[`item.actionAdd`]="{ item }">
        <add-customers-cust-code-dialog
          :customerSender="customerSupplier"
          :customerReceiver="item.customer"
          :useIcon="true"
          @update="getCCCFromAPI()"
        />
      </template>

      <template v-slot:[`item.actionEdit`]="{ item }">
        <edit-customer-code-dialog
          v-if="item.customersCustCode !== null"
          :customersCustCode="item.customersCustCode"
          @update="getCCCFromAPI"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import * as apiCCC from '../api/customerscustcode.js';

import FindCustomerDialog from "./FindCustomerDialog.vue";
import FindCompanyDialog from "./FindCompanyDialog.vue";
import AddCustomersCustCodeDialog from "./AddCustomersCustCodeDialog.vue";
import EditCustomerCodeDialog from "./EditCustomerCodeDialog.vue";

export default {
  name: "CustomerCustCodesBetweenCompanies",
  components: {
    FindCustomerDialog,
    FindCompanyDialog,
    AddCustomersCustCodeDialog,
    EditCustomerCodeDialog
  },
  data: function () {
    return {
      loading: false,

      queryString: null,
      options: {
        sortDesc: [true]
      },
      companyBuyer: {
        companyName: null
      },
      customerSupplier: {
        name: null
      },
      listOfCCC: [],
      headers: [
          { text: 'ID', value: 'customer.id', width: "5%" },
          { text: 'Naziv primaoca', value: 'customer.name', width: "17%" },
          { text: 'Adresa primaoca', value: 'customer.address', width: "15%", sortable: false },
          { text: 'Opis', value: 'customer.description', width: "10%", sortable: false },
          { text: 'Kod veze', value: 'customersCustCode.customerCode', sortable: false },
          { text: 'GLN Dobavljača', value: 'customersCustCode.glnDobavljac', width: "10%", sortable: false },
          { text: 'GLN Primaoca', value: 'customersCustCode.glnPrimalac', width: "10%", sortable: false },
          { text: 'GLN Kupca', value: 'customersCustCode.glnKupac', width: "10%", sortable: false },
          { text: '', value: 'actionAdd', width: "3%", sortable: false },
          { text: '', value: 'actionEdit', width: "3%", sortable: false }
      ]
    }
  },
  methods: {
    getCCCFromAPI() {
      if (this.customerSupplier.id === undefined || this.companyBuyer.id === undefined) {
        return;
      }

      this.loading = true;

      apiCCC.getSupplierBuyerCCCs(this.customerSupplier.id, this.companyBuyer.id, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false
          this.listOfCCC = responseData
        })
        .catch(() => {
          this.listOfCCC = []
          this.loading = false
        })
    },

    supplierCustomerSelected(customer) {
      this.customerSupplier = customer;
      this.options.page = 1;
      this.getCCCFromAPI();
    },

    buyerCompanySelected(company) {
      this.companyBuyer = company;
      this.options.page = 1;
      this.getCCCFromAPI();
    }
  }
}
</script>
