<template>
  <v-card class="mt-3 mb-3" width="100%">
    <v-card-title>
      Kodovi veze
    </v-card-title>
    <v-card-subtitle>
      <v-row>
        <v-col cols="12" sm="4">
          <v-switch v-model="isSender" label="Tretiraj kao pošiljaoca" @click="onSwitch">
          </v-switch>
        </v-col>
        <v-col cols="12" sm="8">
          <v-text-field
            v-model="queryString"
            append-icon="mdi-magnify"
            label="Pretraga"
            single-line
            hide-details
            clearable
            @keyup.enter.prevent='onEnterSearch'
            @click:clear='onClearSearch()'
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-data-table
      :headers="headers"
      :items="listOfCCC"
      :server-items-length="options.page * options.itemsPerPage + 1"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
    >
       <template v-slot:[`item.actions`]="{ item }">
        <edit-customer-code-dialog
          :customersCustCode="item"
          :useIcon="true"
          @update="getCCCFromAPI"
        />
      </template>
    </v-data-table>
<!--
    <edit-customer-code-dialog v-if="loading === false"
      :customersCustCode="selectedCCC"
      :dialogEditCustomerCode="openDialog"
      v-on:dialog-close="onCloseEditDialog"
      @update="getCCCFromAPI"/> -->
  </v-card>
</template>

<script>
import * as apiCCC from '../api/customerscustcode.js'
import EditCustomerCodeDialog from './EditCustomerCodeDialog'

export default {
  name: "CustomersCustCodesList",
  components: {
    EditCustomerCodeDialog
  },
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true]
      },
      isSender: true,
      queryString: null,
      listOfCCC: [],
      headers: [
          { text: 'ID', value: 'id', width: "10%" },
          { text: 'Pošiljalac', value: 'customerS.name', width: "17%" },
          { text: 'Primalac', value: 'customerR.name', width: "17%" },
          { text: 'Kod veze', value: 'customerCode' },
          { text: 'GLN Dobavljača', value: 'glnDobavljac', width: "13%" },
          { text: 'GLN Primaoca', value: 'glnPrimalac', width: "13%" },
          { text: 'GLN Kupca', value: 'glnKupac', width: "13%" },
          { text: '', value: 'actions', width: "7%", sortable: false }
      ],
      selectedCCC: {},
      openDialog: false
    }
  },
  props: {
    customerId: {
      type: Number,
      required: false
    }
  },
  watch: {
    options: {
      handler () {
        this.options.search = this.queryString;
        this.getCCCFromAPI();
      },
      deep: true,
    }
  },
  methods: {
    getCCCFromAPI() {
      this.loading = true

      apiCCC.getList(this.options, this.customerId, this.isSender, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false
          this.listOfCCC = responseData
        })
        .catch(() => {
          this.listOfCCC = []
          this.loading = false
        })
    },

    onEnterSearch() {
      this.options.search = this.queryString
      this.options.page = 1
      this.getCCCFromAPI()
    },

    onClearSearch() {
      this.options.search = '';
      this.queryString = '';
      this.getCCCFromAPI();
    },

    onRowClick(rowItem) {
      this.selectedCCC = rowItem;
      this.openDialog = true;
    },

    onSwitch() {
      this.options.page = 1;
      this.getCCCFromAPI();
    },

    onCloseEditDialog() {
      this.selectedCCC = {};
      this.openDialog = false;
    }
  }
}
</script>
