<template>
  <div>
    <v-dialog v-model="dialogEditCustomerCode" @keydown.esc="onCancel" persistent max-width="700px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Izmena koda veze
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-list dense>
            <v-container>
            <v-row>
              <v-col cols="12">
                <v-row class="mt-4">
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-text-field prepend-icon="mdi-export" label="Pošiljalac" disabled outlined v-model="updatedCustomerCode.customerS.name"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-text-field prepend-icon="mdi-import" label="Primalac" disabled outlined v-model="updatedCustomerCode.customerR.name"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-0">
                    <v-text-field label="Kod veze" required outlined v-model="updatedCustomerCode.customerCode"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-0 pb-0">
                    <v-text-field label="GLN Dobavljača" outlined v-model="updatedCustomerCode.glnDobavljac"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-0 pb-0">
                    <v-text-field label="GLN Primaoca" outlined v-model="updatedCustomerCode.glnPrimalac"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-0 pb-0">
                    <v-text-field label="GLN Kupca" outlined v-model="updatedCustomerCode.glnKupac"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
                <!-- <customers-cust-codes-list :customerId="customersCustCode.customerSID" /> -->
              </v-col>
            </v-row>
          </v-container>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-btn class="error ma-2 white--text" text @click="dialogConfirmDelete = true" :loading="deleting">
            Obriši
            <v-icon right>mdi-trash-can-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" color="red" outlined @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn class="success ma-2 white--text" text @click="dialogConfirmSave = true" :loading="puttingToApi">
            Sačuvaj izmene
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmSave" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Da li želite da sačuvate promene?
        </v-card-title>

         <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text plain ma-2" text @click="dialogConfirmSave = false">
            Odustani
          </v-btn>
          <v-btn class="green--text plain ma-2" text @click="onConfirmEdit" :loading="puttingToApi">
            Sačuvaj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmDelete" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Da li želite da obrišete kod veze?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text plain ma-2" outlined @click="dialogConfirmDelete = false">
            Ne
          </v-btn>
          <v-btn class="error ma-2" text @click="onConfirmDelete" :loading="deleting">
            Da
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as cccAPI from '../api/customerscustcode'

export default {
  name: 'EditCustomerCodeDialog',
  data: function () {
    return {
      dialogConfirmSave: false,
      dialogConfirmDelete: false,
      dialogEditCustomerCode: false,
      puttingToApi: false,
      deleting: false,
      updatedCustomerCode: this.getDefaultUpdatedCustomerCode()
    }
  },
  props: {
    customersCustCode: {
      type: Object,
      required: true
    }
  },
  watch: {
    customersCustCode: {
      handler() {
        // Kopiranje objekta
        this.shallowCopy();
      }
    }
  },
  mounted () {
    this.shallowCopy();
  },
  methods: {
    shallowCopy() {
      this.updatedCustomerCode = JSON.parse(JSON.stringify(this.customersCustCode));
    },
    onCancel() {
      this.dialogEditCustomerCode = false;
    },
    onConfirmEdit() {
      // TODO: validacija pre slanja na API

      this.puttingToApi = true;

      cccAPI.putCCC(this.customersCustCode.id, this.updatedCustomerCode, this.$store.getters.token)
        .then(response => response.json())
        .then(() => {
          // loading na dugmetu
          this.puttingToApi = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmSave = false;
          this.dialogEditCustomerCode = false;

          // brisanje unetih podataka
          this.updatedCustomerCode = this.getDefaultUpdatedCustomerCode();

          this.$emit('update');
        })
        .catch(() => {
          this.puttingToApi = false;
        });
    },
    onConfirmDelete() {
      this.deleting = true;

      cccAPI.deleteCCC(this.customersCustCode.id, this.$store.getters.token)
        .then(response => response.json())
        .then(() => {
          // loading na dugmetu
          this.deleting = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmDelete = false;
          this.dialogEditCustomerCode = false;

          // brisanje unetih podataka
          this.updatedCustomerCode = this.getDefaultUpdatedCustomerCode();

          this.$emit('update');
        })
        .catch(() => {
          this.deleting = false;
        });
    },
    getDefaultUpdatedCustomerCode() {
      return {
        customerS: {
          name: null
        },
        customerR: {
          name: null
        }
      }
    }
  }
}
</script>
