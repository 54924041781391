var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-card',{staticClass:"elevation-0",attrs:{"flat":"","rounded":false}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.listOfDocuments,"server-items-length":_vm.totalCount,"options":_vm.options,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': 'Broj redova po stranici:'
    },"no-data-text":"Nema rezultata","loading-text":"Učitavanje podataka... Molimo sačekajte","width":"100%","elevation":"0"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.isSent",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":_vm.getSentToSefColor(item.isSent),"size":"15"}},'v-avatar',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getSentToSefTooltip(item.isSent)))])])]}},{key:"item.senderName",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pl-2",style:(_vm.getCompanyNameBorderStyle(item.sendersCompanyId, true))},'div',attrs,false),on),[_vm._v(_vm._s(_vm.getShortenedStr(item.senderName)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.senderName))])])]}},{key:"item.receiverName",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({style:(_vm.getCompanyNameBorderStyle(item.sendersCompanyId, false))},'div',attrs,false),on),[_vm._v(_vm._s(_vm.getShortenedStr(item.receiverName)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.receiverName))])])]}},{key:"item.edexStatusMessage",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(item.isSent ? '' : _vm.getShortenedStr(item.edexStatusMessage)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.edexStatusMessage))])])]}},{key:"item.sefComment",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(_vm.getShortenedStr(item.sefComment)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.sefComment))])])]}},{key:"item.actualDeliveryDate",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.actualDeliveryDate)))])]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))])]}},{key:"item.sentDate",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.sentDate)))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('confirm-resend-document-to-sef-vue',{attrs:{"token":_vm.token,"documentId":item.id}}),_c('confirm-resend-document-to-amaps-vue',{attrs:{"token":_vm.token,"documentId":item.id}}),_c('v-btn',{attrs:{"text":"","x-small":"","fab":"","variant":"outlined"}},[_c('v-icon',{on:{"click":function($event){return _vm.downloadXml(item.id)}}},[_vm._v("mdi-download")])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }