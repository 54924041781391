<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6">
        <v-row align-center column justify-center>
          <v-row class="mt-2 mb-2">
            <v-progress-circular v-if="loading === true" indeterminate>
            </v-progress-circular>
          </v-row>

          <file-details :file="file" v-if="file !== null"/>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiFiles from '../api/files.js'
import FileDetails from '../components/FileDetails'

export default {
  name: "FileDetailsPage",
  components: {
    FileDetails
  },
  data: function () {
    return {
      file: null,
      loading: false
    }
  },
  mounted () {
    const id = this.$route.params.id;
    console.log(id);
    this.getFile(id);
  },
  methods: {
    getFile(id) {
      this.loading = true;

      apiFiles.getFile(id, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.file = responseData;
          console.log(this.file);
          this.loading = false;
        })
        .catch(() => {
          this.file = null;
          this.loading = false;
        })
    }
  }
}
</script>
