<template>
  <div class="ma-0 pa-0">
    <v-dialog v-model="dialogFindPortal" @keydown.esc="dialogFindPortal = false" persistent max-width="60%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="primary"
          :small="isSmall"
          :fab="isCircle"
          :disabled="disabled"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ buttonText }}
          <v-icon :class="getClass()">{{ icon }}</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="dialogFindPortal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ toolbarText }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-data-table
        :headers="headers"
        :items="listOfPortals"
        :server-items-length="options.page * options.itemsPerPage + 1"
        :options.sync="options"
        hide-default-footer
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50],
          'items-per-page-text': 'Broj redova po stranici:'
        }"
        no-data-text="Nema rezultata"
        loading-text="Učitavanje podataka... Molimo sačekajte"
        class="elevation-1 row-pointer"
        width="100%"
        @click:row="onRowClick"
        >
        </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as apiPortals from "../api/portals.js";

export default {
  name: "FindPortalDialog",
  data: function () {
    return {
      dialogFindPortal: false,
      loading: true,
      options: {},
      queryString: null,
      listOfPortals: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Naziv portala', value: 'portalName' },
        { text: 'Opis', value: 'description' }
      ]
    };
  },
  props: {
    companyId: {
      type: Number,
      required: false,
      default: 0
    },
    buttonText: {
      type: String,
      required: true,
    },
    toolbarText: {
      type: String,
      required: true,
    },
    isSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    isCircle: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    options: {
      handler() {
        this.options.search = this.queryString;
        this.getPortalsFromAPI();
      },
      deep: true,
    },
  },
  methods: {
    getPortalsFromAPI() {
      this.loading = true;

      apiPortals
      .getList(this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          this.listOfPortals = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.listOfPortals = [];
          this.loading = false;
        });
    },

    onRowClick(portal) {
      this.dialogFindPortal = false;
      this.$emit("update", portal);
    },

    getClass() {
      if (!this.isSmall && !this.isCircle) {
        return "ml-2";
      }
      return "";
    }
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
