<template>
  <div class="ma-0 pa-0">
    <v-dialog v-model="dialogFindCustomer" @keydown.esc="dialogFindCustomer = false" persistent max-width="60%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="primary" :small="isSmall" :fab="isCircle" dark v-bind="attrs" v-on="on">
          {{ buttonText }}
          <v-icon>{{icon}}</v-icon>
        </v-btn>
      </template>
      <v-card height="800px">
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="dialogFindCustomer = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ toolbarText }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-card class="mt-4">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="queryString"
                append-icon="mdi-magnify"
                label="Pretraga"
                single-line
                hide-details
                clearable
                @keyup.enter.prevent='onEnterSearch'
                @click:clear='onClearSearch()'
              ></v-text-field>
            </v-card-title>
            <v-data-table
            :headers="headers"
            :items="listOfCustomers"
            :server-items-length="options.page * options.itemsPerPage + 1"
            :options.sync="options"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50],
              'items-per-page-text': 'Broj redova po stranici:'
            }"
            no-data-text="Nema rezultata"
            loading-text="Učitavanje podataka... Molimo sačekajte"
            class="elevation-1 row-pointer"
            width="100%"
            @click:row="onRowClick"
            >
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as apiCustomers from '../api/customers.js'
import * as apiLocalCodes from '../api/localcodes.js'

export default {
  name: "FindCustomerDialog",
  data: function () {
    return {
      dialogFindCustomer: false,
      loading: true,
      options: {},
      queryString: null,
      listOfCustomers: [],
      headers: [
        { text: 'ID', value: 'id', width: "10%" },
        { text: 'Naziv', value: 'name', width: "25%" },
        { text: 'Adresa', value: 'address' },
        { text: 'Grad', value: 'town', width: "10%" },
        { text: 'Kompanija', value: 'company.companyName', width: "15%", sortable: false },
        { text: 'Lokalni kod', value: 'localCode', width: "15%", sortable: false }
      ]
    }
  },
  props: {
    companyId: {
      type: Number,
      required: false
    },
    buttonText: {
      type: String,
      required: true
    },
    toolbarText: {
      type: String,
      required: true
    },
    isSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: String,
      required: true
    },
    isCircle: {
      type: Boolean,
      required: false,
      default: false
    },
    customerType: {
      type: Number,
      required: false,
      default: null
    }
  },
  watch: {
    options: {
      handler () {
        this.options.search = this.queryString;
        this.getCustomersFromAPI();
      },
      deep: true,
    }
  },
  methods: {
    getCustomersFromAPI() {
      this.loading = true

      if (this.companyId) {
        this.options.companyId = this.companyId;
      }

      if (this.customerType !== null) {
        this.options.customerType = this.customerType;
      }

      apiCustomers.getList(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.listOfCustomers = responseData;
          this.listOfCustomers.forEach((customer) => {
            this.$set(customer, "localCode", "");
          });

          this.getLocalCodes();
          this.loading = false;
        })
        .catch(() => {
          this.listOfCustomers = []
          this.loading = false
        })
    },

    onEnterSearch() {
      this.options.search = this.queryString
      this.options.page = 1
      this.getCustomersFromAPI()
    },

    onClearSearch() {
      this.options.search = '';
      this.queryString = null;
      this.getCustomersFromAPI()
    },

    onRowClick(customer) {
      this.dialogFindCustomer = false;
      this.$emit('update', customer);
    },

    getLocalCodes() {
      this.listOfCustomers.forEach((customer) => {
        apiLocalCodes.getLocalCodeByCustomerId(customer.id, this.$store.getters.token)
          .then(response => response.json())
          .then(responseData => {
            if (responseData.length == 0) {
              customer.localCode = "";
              return;
            }

            let firstLocalCode = responseData[0];
            customer.localCode = firstLocalCode.code;
          })
          .catch(() => {
            console.log("Greska...");
            customer.localCode = "";
          })
      });


    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
