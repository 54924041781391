<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10">
        <v-row align-center column justify-center>
          <v-card width="80%" class="mb-6">
            <v-card-title> Ažuriranje šifarnika </v-card-title>

            <v-card-text>
              <v-row>
                <v-col sm="12" md="6">
                  <v-file-input
                    label="Šifarnik u XLSX (Excel) formatu"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    v-model="uploadedFile"
                    truncate-length="50"
                    outlined
                    prepend-icon="mdi-microsoft-excel"
                  ></v-file-input>
                </v-col>
                <v-col sm="12">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col sm="12" class="text-subtitle-1 font-weight-medium">
                      Podešavanja fajla
                    </v-col>

                    <v-col sm="12" class="pt-0 pb-0">
                      <v-checkbox dense class="mt-0"
                        v-model="fileSettings.removeLeadingZeros"
                        label="Brisati šiframa vodeće nule"
                      ></v-checkbox>
                    </v-col>

                    <v-col sm="12" md="4" class="pt-0 pb-0">
                      <v-text-field
                        label="Početni red"
                        required
                        outlined
                        v-model="fileSettings.firstRow"
                      ></v-text-field>
                    </v-col>
                    <v-col  sm="12" md="4" class="pt-0 pb-0">
                      <v-text-field
                        label="Poslednji red"
                        outlined
                        v-model="fileSettings.lastRow"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col sm="12">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col sm="12" class="text-subtitle-1 font-weight-medium">
                      Podešavanja kolona
                    </v-col>
                    <v-col sm="12" class="text-caption">
                      Napomena: Unose se slova (kao u Excelu)
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-row>
                        <v-col sm="12" class="pt-0 pb-0">
                          <v-text-field required outlined
                            label="Šifra"
                            v-model="columnSettings.sifra"
                          ></v-text-field>
                        </v-col>

                        <v-col sm="12" class="pt-0 pb-0">
                          <v-text-field required outlined
                            label="Naziv"
                            v-model="columnSettings.naziv"
                          ></v-text-field>
                        </v-col>

                        <v-col sm="12" class="pt-0 pb-0">
                          <v-text-field outlined
                            label="Proizvođač"
                            v-model="columnSettings.proizvodjac"
                          ></v-text-field>
                        </v-col>

                        <v-col sm="12" class="pt-0 pb-0">
                          <v-text-field outlined
                            label="Opis"
                            v-model="columnSettings.opis"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col  sm="12" md="6">
                      <v-row>
                        <v-col sm="12" class="pt-0 pb-0">
                          <v-text-field outlined
                            label="JKL"
                            v-model="columnSettings.jkl"
                          ></v-text-field>
                        </v-col>

                        <v-col sm="12" class="pt-0 pb-0">
                          <v-text-field outlined
                            label="ATC"
                            v-model="columnSettings.atc"
                          ></v-text-field>
                        </v-col>

                        <v-col sm="12" class="pt-0 pb-0">
                          <v-text-field outlined
                            label="EAN 1"
                            v-model="columnSettings.ean1"
                          ></v-text-field>
                        </v-col>

                        <v-col sm="12" class="pt-0 pb-0">
                          <v-text-field outlined
                            label="EAN 2"
                            v-model="columnSettings.ean2"
                          ></v-text-field>
                        </v-col>

                        <v-col sm="12" class="pt-0 pb-0">
                          <v-text-field outlined
                            label="EAN 3"
                            v-model="columnSettings.ean3"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error ma-2 white--text" text @click="onCancel">
                Odustani
                <v-icon right>mdi-close</v-icon>
              </v-btn>
              <v-btn class="primary ma-2 white--text" text @click="onAddCodebook" :loading="postingToApi">
                Ažuriraj šifarnik
                <v-icon right>mdi-content-save</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>

          <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show">
          </snackbar>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as codebooksAPI from "../api/codebooks";
import Snackbar from "../components/Snackbar.vue"

export default {
  name: "CodebookUpdatePage",
  components: {
    Snackbar
  },
  data: function () {
    return {
      codebookId: -1,
      postingToApi: false,

      snackbar: {
        show : false,
        text : "",
        isError : false
      },

      fileSettings: {},
      columnSettings: {},
      uploadedFile: {},
    };
  },
  mounted() {
    this.codebookId = this.$route.params.id;
  },
  methods: {
    onAddCodebook() {
      // TODO: validacija pre slanja na API

      this.postingToApi = true;

      let mappedColumnSettings = this.mapColumnLetters(this.columnSettings);
      let codebookUpdate = this.createCodebook(mappedColumnSettings);

      codebooksAPI
        .putCodebook(codebookUpdate, this.uploadedFile, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;

          let message = "Ažuriran šifarnik ID: " + responseData.codeBook.id + ", broj dodatih artikala: " + responseData.addedProductsCount;
          this.showNotification(message);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;
        });
    },
    onCancel() {
      this.fileSettings = {};
      this.columnSettings = {};
      this.$router.push('/codebooks');
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      // prikaz snackbar obavestenja
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },
    createCodebook(mappedColumnSettings) {
      let codebook = {
        "codebookId" : this.codebookId,
        "fileSettings" : this.fileSettings,
        "columnSettings" : mappedColumnSettings
      };

      codebook.fileSettings.filename = this.uploadedFile.name;

      return codebook;
    },
    mapColumnLetters(columnSettings) {
      let mappedColumnSettings = {};
      for (var prop in columnSettings) {
        if (Object.prototype.hasOwnProperty.call(columnSettings, prop)) {
            let letter = columnSettings[prop];
            if (letter === null) {
              continue;
            }

            letter = letter.toLowerCase();
            let number = letter.charCodeAt(0);

            number = number - 97;
            if (number >= 0 && number <= 25) {
              mappedColumnSettings[prop] = number;
            }
            else {
              mappedColumnSettings[prop] = columnSettings[prop];
            }
        }
      }

      return mappedColumnSettings;
    }
  },
};
</script>
