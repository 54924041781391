import * as fetch from './fetch'

export function postCodebook(newCodebook, file, token) {
  const endpoint = '/codebooks'
  return fetch.POSTMultipart(endpoint, token, newCodebook, file, true, "createRequest");
}

export function putCodebook(codebookUpdate, file, token) {
  const endpoint = '/codebooks'
  return fetch.PUTMultipart(endpoint, token, codebookUpdate, file, true, "updateRequest");
}

export function getList(options, token) {
  let parameters = mapToParameters(options);

  const endpoint = '/codebooks';
  let queryString = new URLSearchParams(parameters).toString();
  return fetch.GET(endpoint + '?' + queryString, token, true);
}

export function getListByCompany(options, companyId, token) {
  let parameters = mapToParameters(options, companyId);

  const endpoint = '/codebooks';
  let queryString = new URLSearchParams(parameters).toString();
  return fetch.GET(endpoint + '?' + queryString, token, true);
}

export function getByCompany(companyId, token) {
  const endpoint = '/codebooks/byCompany/' + companyId;
  return fetch.GET(endpoint, token, true);
}

export function pairing(requestBody, file, token) {
  const endpoint = '/codebooks/pairing';
  return fetch.POSTMultipart(endpoint, token, requestBody, file, true, "pairRequest")
}

function mapToParameters(options, companyId = null) {
  let parameters = {}

  if (companyId) {
    parameters.companyId = companyId;
  }

  if (options.sortBy.length !== 0) {
    parameters.orderBy = options.sortBy
  }

  let sortDesc = options.sortDesc[0]
  if (sortDesc !== null) {
    parameters.orderAsc = !sortDesc
  }

  if (options.page !== null) {
    parameters.pageNum = options.page
  }

  if (options.itemsPerPage !== null) {
    parameters.pageSize = options.itemsPerPage
  }

  if (options.search) {
    parameters.query = options.search
  }

  if (options.companyID) {
    parameters.companyID = options.companyID
  }

  return parameters
}
