<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6">
        <v-row align-center column justify-center>
          <v-row class="mt-2 mb-2">
            <v-progress-circular v-if="loading === true" indeterminate>
            </v-progress-circular>
          </v-row>

          <portal-user-details :portalUser="portalUser" v-if="portalUser !== null"/>
        </v-row>
      </v-col>

      <v-col cols="12" sm="7">
        <portal-user-customer-links-list :portalUserId="portalUser.id" v-if="portalUser !== null" />
      </v-col>

      <v-col cols="12" sm="6">
        <add-portal-user-customer-link-dialog :portalUser="portalUser" v-if="portalUser !== null" @update="addedNewUserCustomerLink" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiPortalUsers from '../api/portalusers.js'
import AddPortalUserCustomerLinkDialog from '../components/AddPortalUserCustomerLinkDialog.vue'
import PortalUserCustomerLinksList from '../components/PortalUserCustomerLinksList.vue'
import PortalUserDetails from '../components/PortalUserDetails'

export default {
  name: "PortalUserDetailsPage",
  components: {
    PortalUserDetails,
    PortalUserCustomerLinksList,
    AddPortalUserCustomerLinkDialog
  },
  data: function () {
    return {
      portalUser: null,
      loading: false
    }
  },
  mounted () {
    const id = this.$route.params.id;
    this.getPortalUser(id);
  },
  methods: {
    getPortalUser(id) {
      this.loading = true;

      apiPortalUsers.getPortalUserById(id, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.portalUser = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.portalUser = null;
          this.loading = false;
        })
    },
    addedNewUserCustomerLink() {
      this.$router.go();
    }
  }
}
</script>
