<template>
  <div>
    <v-dialog v-model="dialogNewFarmalogist" @keydown.esc="onCancel" persistent max-width="60%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="primary" dark v-bind="attrs" v-on="on">
          Dodaj
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Dodavanje Farmalogist kredencijala
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-row class="mt-4">
                  <v-col cols="12" class="pt-0 pb-0">
                    <v-text-field prepend-icon="mdi-map-marker" label="ID isporučnog mesta" disabled outlined v-model="newFarmalogist.customerID"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-text-field prepend-icon="mdi-account"  label="Username" required outlined v-model="newFarmalogist.username"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-text-field prepend-icon="mdi-lock"  label="Password" required outlined v-model="newFarmalogist.password"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-0">
                    <v-text-field prepend-icon="mdi-key"  label="Comitent key" required outlined v-model="newFarmalogist.comitentKey"></v-text-field>
                  </v-col>
                   <small>* označava neophodna polja</small>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error ma-2 white--text" text @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn class="primary ma-2 white--text" text @click="onAddFarmalogist" :loading="postingToApi">
            Dodaj
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="5000" color="success">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn text icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
  </div>
</template>

<script>
import * as farmalogistAPI from '../api/farmalogistB2B.js'

export default {
  name: "AddFarmalogistDialog",
  data: function () {
    return {
      dialogNewFarmalogist: false,
      postingToApi: false,
      newFarmalogist: this.getDefaultNewFarmalogist(),
      snackbar: false,
      snackbarText: ""
    }
  },
  props: {
    customerId: {
      type: Number,
      required: true
    }
  },
  methods: {
    getDefaultNewFarmalogist() {
      return {
        customerID: this.customerId
      }
    },
    onAddFarmalogist () {
      // TODO: validacija pre slanja na API

      this.postingToApi = true;

      farmalogistAPI.postFarmalogist(this.newFarmalogist, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          // loading na dugmetu
          this.postingToApi = false;

          // zatvaranje modalnog prozora
          this.dialogNewFarmalogist = false;

          // prikaz snackbar obavestenja
          this.snackbarText = "Dodat Farmalogist objekat sa ID-jem: " + responseData.id;
          this.snackbar = true;

          // brisanje unetih podataka za novu kompaniju
          this.newFarmalogist = this.getDefaultNewFarmalogist();

          this.$emit('update');
        })
        .catch(() => {
          this.postingToApi = false;
        });
    },
    onCancel () {
      this.dialogNewFarmalogist = false;
      this.newFarmalogist = this.getDefaultNewFarmalogist();
    }
  }
}
</script>
