<template>
  <span>
    <v-card flat class="elevation-0" :rounded="false">
      <v-card-text>
      <v-data-table
      :headers="headers"
      :items="listOfDocuments"
      :server-items-length="totalCount"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
      elevation="0"
      >
        <template v-slot:[`item.isSent`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar :color="getSentToSefColor(item.isSent)" size="15" v-bind="attrs" v-on="on"></v-avatar>
            </template>
            <span>{{getSentToSefTooltip(item.isSent)}}</span>
          </v-tooltip>
        </template>

         <template v-slot:[`item.senderName`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" :style="getCompanyNameBorderStyle(item.sendersCompanyId, true)" class="pl-2">{{ getShortenedStr(item.senderName)}}</div>
            </template>
            <span>{{item.senderName}}</span>
          </v-tooltip>
        </template>

         <template v-slot:[`item.receiverName`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" :style="getCompanyNameBorderStyle(item.sendersCompanyId, false)">{{ getShortenedStr(item.receiverName)}}</div>
            </template>
            <span>{{item.receiverName}}</span>
          </v-tooltip>
        </template>

         <template v-slot:[`item.edexStatusMessage`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">{{ item.isSent ? '' : getShortenedStr(item.edexStatusMessage)}}</div>
            </template>
            <span>{{item.edexStatusMessage}}</span>
          </v-tooltip>
        </template>

         <template v-slot:[`item.sefComment`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">{{ getShortenedStr(item.sefComment)}}</div>
            </template>
            <span>{{item.sefComment}}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.actualDeliveryDate`]="{ item }">
          <div>{{formatDate(item.actualDeliveryDate)}}</div>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          <div>{{formatDate(item.createdAt)}}</div>
        </template>

        <template v-slot:[`item.sentDate`]="{ item }">
          <div>{{formatDate(item.sentDate)}}</div>
        </template>

         <template v-slot:[`item.actions`]="{ item }">
          <confirm-resend-document-to-sef-vue :token="token" :documentId="item.id" />

          <confirm-resend-document-to-amaps-vue :token="token" :documentId="item.id" />

          <v-btn text x-small fab variant="outlined">
            <v-icon @click="downloadXml(item.id)">mdi-download</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      </v-card-text>
    </v-card>
  </span>
</template>

<style lang="css">
td {
  padding: 0 !important;
  padding-left: 10px !important;
  height: 30px !important;
}

th {
  padding: 0 !important;
  padding-left: 10px !important;
}
</style>

<script>
import * as apiSefLink from '../api/seflink.js';
import ConfirmResendDocumentToSefVue from './ConfirmResendDocumentToSef.vue';
import ConfirmResendDocumentToAmapsVue from './ConfirmResendDocumentToAmaps.vue';

export default {
  name: "ListSefLinkAdminDocuments",
  components: {
    ConfirmResendDocumentToSefVue,
    ConfirmResendDocumentToAmapsVue,
  },
  data: function () {
    return {
      loading: false,
      downloadingExcel: false,
      options: {
        sortDesc: [true],
        itemsPerPage: 20,
      },
      queryString: null,
      listOfDocuments: [],
      totalCount: 1,
      headers: [
          //Marbo dokument	Datum prometa	Datum prijema	Datum slanja ka kupcu/SEFu	Tip dokumenta	Kupac	Vrednost dokumenta	Poslato ka kupcu/SEFu	SEF id	SEF status	Razlog zasto dokument nije obradjen	Razlog odbijanja na SEFu	CRF Status
          //7304815810	02-02-23	01-02-23	02-02-23	Credit Memo	Pera Peric	 134,569 	NE			Kupac nije na SEFu
          { text: 'Broj', value: 'documentNumber', width: "7%", sortable: false },
          { text: 'Datum prometa', value: 'actualDeliveryDate', width: "7%", sortable: false },
          { text: 'Datum prijema', value: 'createdAt', width: "7%", sortable: false },
          { text: 'Datum slanja na SEF', value: 'sentDate', width: "7%", sortable: false, align: 'center' },
          { text: 'Tip', value: 'documentType', width: "4%", sortable: false },
          { text: 'Pošiljalac', value: 'senderName', sortable: false },
          { text: 'Primalac', value: 'receiverName', sortable: false },
          { text: 'Poslato na SEF', value: 'isSent', width: "7%", sortable: false },
          { text: 'SEF ID', value: 'sefId', width: "8%", sortable: false },
          { text: 'SEF status', value: 'sefStatusMessage', width: "5%", sortable: false },
          { text: 'Greška', value: 'edexStatusMessage', sortable: false },
          { text: 'Razlog odbijanja', value: 'sefComment', sortable: false },
          { text: 'CRF status', value: 'sentToCrf', width: "6%", sortable: false },
          { text: 'Akcije', value: 'actions', width: "7%", sortable: false }
      ]
    }
  },
  mounted() {
  },
  watch: {
    options: {
      handler () {
        this.getSalesFromAPI();
      },
      deep: true,
    },
    filters: {
      handler(newFilters) {
        this.filters = newFilters;
        //this.options.page = 1;
        this.getSalesFromAPI();
      },
      deep: true
    },
    token: {
      handler() {
       //this.token = edexToken;
        console.log('novi token');
        this.getSalesFromAPI();
      }
    },
  },
   props: {
    filters: {
      type: Object,
      required: false
    },
    token: {
      type: String,
      default: "",
      required: false
    }
  },
  methods: {
    getCompanyNameBorderStyle(isSendersCompanyId, isSender) {
      if ((isSender && isSendersCompanyId) || (!isSender && !isSendersCompanyId)) {
         //return "border: 2px solid #434136; border-radius: 12px;";
         return "background-color: #DCDCDC;";
      } else {
        return "";
      }
    },
    getSalesFromAPI() {
      this.loading = true;
      if (this.token === null) {
        return;
      }

      apiSefLink.getAdminDocuments(this.options, this.filters, this.token)
        .then(responseData => {
          this.loading = false;
          this.totalCount = responseData.totalCount;
          this.listOfDocuments = responseData.data;
          console.log(responseData.totalCount);
        })
        .catch((error) => {
          this.listOfDocuments = [];
          this.totalCount = 0;
          this.loading = false;
          console.log(error);
        })
    },

    getSentToSefTooltip(isSent) {
      if (isSent === null) {
        return '';
      } else if (isSent === true) {
        return 'Da';
      } else {
        return 'Ne';
      }
    },

    getSentToSefColor(isSent) {
      if (isSent === null) {
        return 'surface-variant';
      } else if (isSent === true) {
        return 'success';
      } else {
        return 'error';
      }
    },

    formatDate(datum) {
      if (datum) {
        return datum.replaceAll('-', '.');
      } else {
        return '';
      }
    },

    getShortenedStr(str) {
      if (str === null) {
        return '';
      }

      const maxLength = 50;
      if (str.length > maxLength) {
        return str.substring(0, maxLength) + '...';
      }

      return str;
    },

    downloadXml(docId) {
      const requestHeaders = new Headers();
      requestHeaders.set('Authorization', 'Bearer ' + this.token);

      const requestOptions = { method: 'GET' };
      requestOptions.headers = requestHeaders;

      // do the fetch
      let fullEndpoint = process.env.VUE_APP_EDEX_BASEURL + '/admin/xml?id=' + docId;
      return fetch(fullEndpoint, requestOptions)
        .then(response => {
          const header = response.headers.get('content-disposition');
          const parts = header.split(';');
          let filename = parts[1].split('=')[1].replaceAll("\"", "");

          return response.blob()
            .then(blob => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download = filename;
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove();  //afterwards we remove the element again
            });
        })
        .catch(() => {
        });
    }
  }
}
</script>

<style scoped>
.v-data-table {
  box-shadow: none !important;
}
</style>
