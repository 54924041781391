var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-title',[_vm._v(" Fajlovi ")]),_c('v-card-subtitle',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"rules":[_vm.rules.min3Characters, _vm.rules.customerNotChosen],"append-icon":"mdi-magnify","label":"Pretraga","single-line":"","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.onEnterSearch($event)},"click:clear":function($event){return _vm.onClearSearch()}},model:{value:(_vm.queryString),callback:function ($$v) {_vm.queryString=$$v},expression:"queryString"}})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('find-customer-dialog',{attrs:{"isSmall":true,"icon":"mdi-magnify","buttonText":"Pošiljalac","toolbarText":"Izaberi isporučno mesto pošiljaoca"},on:{"update":_vm.senderSelected}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4"}},[_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"label":"Pretraga po ID"},on:{"click":_vm.onSwitch},model:{value:(_vm.isFileIdSearch),callback:function ($$v) {_vm.isFileIdSearch=$$v},expression:"isFileIdSearch"}})],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.listOfFiles,"server-items-length":_vm.options.page * _vm.options.itemsPerPage + 1,"options":_vm.options,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': 'Broj redova po stranici:'
    },"no-data-text":"Nema rezultata","loading-text":"Učitavanje podataka... Molimo sačekajte","width":"100%"},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.format_datetime(item.createdAt)))])]}},{key:"item.processed",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.getProcessedIcon(item)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getProcessedTooltip(item)))])])]}},{key:"item.duplicate",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.getDuplicateIcon(item)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getDuplicateAvatarTooltip(item)))])])]}},{key:"item.error",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.getErrorIcon(item)))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }