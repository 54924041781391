import * as fetch from './fetch'

export function getList(token) {
  const endpoint = '/portals';
  return fetch.GET(endpoint, token, true);
}

export function getActions(token) {
  const endpoint = '/portals/actions';
  return fetch.GET(endpoint, token, true);
}
