<template>
  <v-container>
    <v-card>
      <v-card-title>
        Isporučna mesta vezana za korisnika portala
        <v-spacer></v-spacer>
        <v-text-field
          v-model="queryString"
          append-icon="mdi-magnify"
          label="Pretraga"
          single-line
          hide-details
          clearable
          @keyup.enter.prevent='onEnterSearch'
          @click:clear='onClearSearch()'
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="listOfPortalUserCustomerLinks"
        :server-items-length="options.page * options.itemsPerPage + 1"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50],
          'items-per-page-text': 'Broj redova po stranici:'
        }"
        no-data-text="Nema rezultata"
        loading-text="Učitavanje podataka... Molimo sačekajte"
        class="elevation-1 row-pointer"
        width="100%"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ format_datetime(item.createdAt) }}</span>
        </template>

        <template v-slot:[`item.updatedAt`]="{ item }">
          <span>{{ format_datetime(item.updatedAt) }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" @click="onRowClick(item)">mdi-trash-can</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogConfirmDelete" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Da li želite da obrišete vezu između korisnika portala i isporučnog mesta?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text plain ma-2" outlined @click="cancelDeleting">
            Ne
          </v-btn>
          <v-btn class="error ma-2" text @click="onConfirmDelete" :loading="deleting">
            Da
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import * as apiPortalUsers from '../api/portalusers.js';
import moment from 'moment';

export default {
  name: "PortalUserCustomerLinksList",
  components: {
  },
  data: function () {
    return {
      dialogConfirmDelete: false,
      loading: false,
      deleting: false,
      options: {
        sortDesc: [true],
        customerId: null,
        portalUserId: null
      },
      queryString: null,
      listOfPortalUserCustomerLinks: [],
      headers: [
          { text: 'ID', value: 'customer.id', width: "7%" },
          { text: 'Naziv', value: 'customer.name' },
          { text: 'Adresa', value: 'customer.address', width: "25%", sortable: false },
          { text: 'Datum kreiranja', value: 'createdAt', width: "17%", sortable: false },
          { text: 'Datum ažuriranja', value: 'updatedAt', width: "17%", sortable: false },
          { text: '', value: 'actions', width:"3%", sortable: false }
      ],
      linkToDelete: null
    }
  },
  props: {
    customerId: {
      type: Number,
      required: false,
      default: null
    },
    portalUserId: {
      type: Number,
      required: false,
      default: null
    }
  },
  watch: {
    options: {
      handler () {
        this.options.search = this.queryString;
        this.options.customerId = this.customerId;
        this.options.portalUserId = this.portalUserId;
        this.getPortalUserCustomerLinksFromAPI();
      },
      deep: true,
    }
  },
  mounted () {
    this.options.customerId = this.customerId;
    this.options.portalUserId = this.portalUserId;
    this.getPortalUserCustomerLinksFromAPI();
  },
  methods: {
    getPortalUserCustomerLinksFromAPI() {
      this.loading = true;

      apiPortalUsers.getPortalUserCustomerLinksList(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.listOfPortalUserCustomerLinks = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.listOfPortalUserCustomerLinks = [];
          this.loading = false;
        })
    },

    onEnterSearch() {
      this.options.search = this.queryString;
      this.options.page = 1;
      this.getPortalUserCustomerLinksFromAPI();
    },

    onClearSearch() {
      this.options.search = '';
      this.queryString = '';
      this.getPortalUserCustomerLinksFromAPI();
    },

    onRowClick(link) {
      this.dialogConfirmDelete = true;
      this.linkToDelete = link;
    },

    cancelDeleting() {
      this.dialogConfirmDelete = false;
      this.linkToDelete = null;
    },

    onConfirmDelete() {
      this.deleting = true;

      apiPortalUsers.deletePortalUserCustomerLink(this.linkToDelete.id, this.$store.getters.token)
        .then(() => {})
        .then(() => {
          // loading na dugmetu
          this.deleting = false;

          // zatvaranje modalnog prozora
          this.dialogConfirmDelete = false;

          this.getPortalUserCustomerLinksFromAPI();
        })
        .catch(() => {
          this.deleting = false;
        });
    },

    format_datetime(value){
      if (value) {
        let datetime = moment(String(value)).format('DD.MM.YYYY HH:mm:ss');
        if (datetime === "01.01.0001 00:00:00") {
          return "-"
        }
        else {
          return datetime;
        }
      }
    },
  }
}
</script>
