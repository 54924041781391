<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6">
        <v-row align-center column justify-center>
          <v-row class="mt-2 mb-2">
            <v-progress-circular v-if="loading === true" indeterminate>
            </v-progress-circular>
          </v-row>

          <user-details :user="user" v-if="user !== null"/>
        </v-row>
      </v-col>
      <v-col cols="12" sm="7">
        <user-customer-links-list :userId="user.id" v-if="user !== null" />
      </v-col>
      <v-col cols="12" sm="6">
        <add-user-customer-link-dialog :user="user" v-if="user !== null" @update="addedNewUserCustomerLink" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiUsers from '../api/users.js';
import UserDetails from '../components/UserDetails.vue';
import UserCustomerLinksList from '../components/UserCustomerLinksList.vue';
import AddUserCustomerLinkDialog from '../components/AddUserCustomerLinkDialog.vue';

export default {
  name: "UserDetailsPage",
  components: {
    UserDetails,
    UserCustomerLinksList,
    AddUserCustomerLinkDialog
  },
  data: function () {
    return {
      user: null,
      loading: false
    }
  },
  mounted () {
    const id = this.$route.params.id;
    this.getUser(id);
  },
  methods: {
    getUser(id) {
      this.loading = true;

      apiUsers.getUserById(id, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.user = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.user = null;
          this.loading = false;
        })
    },

    addedNewUserCustomerLink() {
      this.$router.go();
    }
  }
}
</script>
