<template>
  <div>
    <v-dialog v-model="dialogNewCompany" @keydown.esc="onCancel" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="primary" dark v-bind="attrs" v-on="on">
          Dodaj kompaniju
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Dodavanje kompanije
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-container>
            <v-row>
              <v-col cols="12" class="pt-0 pb-0">
                <v-text-field label="Naziv kompanije *" required outlined v-model="newCompany.companyName"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-text-field label="PIB *" required outlined v-model="newCompany.pib"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-text-field label="GLN" outlined v-model="newCompany.gln"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="8" class="pt-0 pb-0">
                <v-text-field label="Adresa" outlined v-model="newCompany.address"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
                <v-text-field label="Grad" outlined v-model="newCompany.town"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-text-field label="Email" outlined v-model="newCompany.email"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-text-field label="Telefon" outlined v-model="newCompany.telephone"></v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <v-text-field label="Kontakt osoba" outlined v-model="newCompany.contactPerson"></v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <v-textarea label="Opis" outlined auto-grow v-model="newCompany.description"></v-textarea>
              </v-col>
              <v-col cols="12" class="pt-0 pb-0">
                <v-alert type="error" v-if="addingFailed">Postoji kompanija sa zadatim PIB-om!</v-alert>
              </v-col>
            </v-row>
          </v-container>
          <small>* označava neophodna polja</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error ma-2 white--text" text @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn class="primary ma-2 white--text" text @click="onAddCompany" :loading="postingToApi">
            Dodaj kompaniju
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="5000" color="success">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn text icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
  </div>
</template>

<script>
import * as companiesApi from '../api/companies'

export default {
  name: "AddCompanyDialog",
  data: function () {
    return {
      dialogNewCompany: false,
      postingToApi: false,
      newCompany: {},
      snackbar: false,
      snackbarText: "",

      addingFailed: false
    }
  },
  methods: {
    onAddCompany () {
      // TODO: validacija pre slanja na API

      this.postingToApi = true;

      companiesApi.postCompany(this.newCompany, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          // loading na dugmetu
          this.postingToApi = false;

          // zatvaranje modalnog prozora
          this.dialogNewCompany = false;

          // prikaz snackbar obavestenja
          this.snackbarText = "Dodata kompanija sa ID-jem: " + responseData.id;
          this.snackbar = true;

          // brisanje unetih podataka za novu kompaniju
          this.newCompany = {};
        })
        .catch((error) => {
          if (error.message && error.message.startsWith("Company with PIB")) {
            this.showError();
          }

          this.postingToApi = false;
        });
    },
    onCancel () {
      this.dialogNewCompany = false;
      this.newCompany = {};
    },
    showError() {
      this.addingFailed = true;

      setTimeout(() => {
        this.addingFailed = false;
      }, 5000)
    }
  }
}
</script>
