import * as fetch from './fetch'

export function getList(options, token) {
  let parameters = mapToParameters(options)

  const endpoint = '/portalusers'
  let queryString = new URLSearchParams(parameters).toString()
  return fetch.GET(endpoint + '?' + queryString, token, true)
}

export function postUser(newUserRequest, token) {
  const endpoint = '/portalusers/createUser';
  return fetch.POST(endpoint, token, newUserRequest, true);
}

export function getPortalUserById(id, token) {
  const endpoint = '/portalusers/' + id;
  return fetch.GET(endpoint, token, true);
}

export function updatePortalUser(id, updatedUser, token) {
  const endpoint = '/portalusers/' + id;

  return fetch.PUT(endpoint, token, updatedUser, true);
}

export function deletePortalUser(id, token) {
  const endpoint = '/portalusers/' + id;
  return fetch.DELETE(endpoint, token, true);
}

export function getPortalUserCustomerLinksList(options, token) {
  let parameters = mapToParametersForUserCustomerLinks(options)

  const endpoint = '/portalusers/customerLinks'
  let queryString = new URLSearchParams(parameters).toString()
  return fetch.GET(endpoint + '?' + queryString, token, true)
}

export function deletePortalUserCustomerLink(linkId, token) {
  const endpoint = '/portalusers/customerLinks/' + linkId;
  return fetch.DELETE(endpoint, token, true)
}

export function postPortalUserCustomerLink(newUserCustomerLink, token) {
  const endpoint = '/portalusers/customerLinks/';
  return fetch.POST(endpoint, token, newUserCustomerLink, true);
}

function mapToParameters(options) {
  let parameters = {}

  if (options.sortBy.length !== 0) {
    parameters.orderBy = options.sortBy
  }

  let sortDesc = options.sortDesc[0]
  if (sortDesc !== null) {
    parameters.orderAsc = !sortDesc
  }

  if (options.page !== null) {
    parameters.pageNum = options.page
  }

  if (options.itemsPerPage !== null) {
    parameters.pageSize = options.itemsPerPage
  }

  if (options.search) {
    parameters.query = options.search
  }

  if (options.customerId) {
    parameters.customerId = options.customerId;
  }

  if (options.companyId) {
    parameters.companyId = options.companyId;
  }

  return parameters
}

function mapToParametersForUserCustomerLinks(options) {
  let parameters = {}

  if (options.sortBy && options.sortBy.length !== 0) {
    parameters.orderBy = options.sortBy
  }

  let sortDesc = options.sortDesc[0]
  if (sortDesc !== null) {
    parameters.orderAsc = !sortDesc
  }

  if (options.page !== null) {
    parameters.pageNum = options.page
  }

  if (options.itemsPerPage !== null) {
    parameters.pageSize = options.itemsPerPage
  }

  if (options.search) {
    parameters.query = options.search
  }

  if (options.portalUserId) {
    parameters.portalUserId = options.portalUserId;
  }

  if (options.customerId) {
    parameters.customerId = options.customerId;
  }

  return parameters
}
