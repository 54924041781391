<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6">
        <v-row align-center column justify-center>
          <v-card width="80%" class="mb-6">
            <v-card-title> Dodavanje affiliate korisnika </v-card-title>

            <v-card-text>
              <v-row class="mt-2">
                <v-col sm="6" md="6">
                  <v-row>
                    <v-col sm="6" class="pt-0 pb-0">
                      <v-text-field label="PIB" required outlined dense
                        v-model="newUser.pib"
                      ></v-text-field>
                    </v-col>

                    <v-col sm="6" class="pt-0 pb-0">
                      <v-btn @click="getCompanyFromNbs" type="Submit" :loading="loadingCompanyFromNbs" >
                         <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </v-col>

                    <v-col sm="12" class="pt-0 pb-0">
                      <v-text-field label="Ime" required outlined dense
                        v-model="newUser.firstName"
                      ></v-text-field>
                    </v-col>

                    <v-col sm="12" class="pt-0 pb-0">
                      <v-text-field label="Prezime" required outlined dense
                        v-model="newUser.lastName"
                      ></v-text-field>
                    </v-col>

                    <v-col sm="12" class="pt-0 pb-0">
                      <v-text-field label="Kontakt telefon" required outlined dense
                        v-model="newUser.phoneNumber"
                      ></v-text-field>
                    </v-col>

                     <v-col sm="12" class="pt-0 pb-0">
                      <v-text-field label="Kontakt email" required outlined dense
                        v-model="newUser.contactEmail"
                      ></v-text-field>
                    </v-col>

                    <v-col sm="12" class="pt-0 pb-0">
                      <v-text-field label="Email" required outlined dense
                        v-model="newUser.email"
                      ></v-text-field>
                    </v-col>

                    <v-col sm="12" class="pt-0 pb-0">
                      <v-text-field label="Lozinka" required outlined dense
                        v-model="newUser.password"
                        type="password"
                      ></v-text-field>
                    </v-col>

                     <v-col sm="12" class="pt-0 pb-0">
                      <v-text-field label="Promo kod" required outlined dense
                        v-model="newUser.promoCode"
                      ></v-text-field>
                    </v-col>

                    <v-col sm="12" class="pt-0 pb-0">
                      <v-text-field label="Knjigovodstveni softver" required outlined dense
                        v-model="newUser.software"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col sm="6" md="6">
                  <v-row>
                    <v-col sm="12" class="pt-0 pb-0">
                      <v-list dense>
                        <v-list-item v-for="item in companyInfo" :key="item.label">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.label }}</v-list-item-title>
                            <v-list-item-subtitle>{{ getCompanyProperty(item.propertyName) }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error ma-2 white--text" text @click="onCancel">
                Odustani
                <v-icon right>mdi-close</v-icon>
              </v-btn>
              <v-btn class="primary ma-2 white--text" text @click="onAddUser" :loading="postingToApi">
                Dodaj korisnika
                <v-icon right>mdi-plus</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>

          <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show">
          </snackbar>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiNbs from "../api/nbs";
import * as apiEdexAffiliates from "../api/edex_affiliates";
import Snackbar from "../components/Snackbar.vue"

export default {
  name: "SefLinkCreateAffiliiatePage",
  components: {
    Snackbar
  },
  data: function () {
    return {
      newUser: {},
      company: {
        'companyName': null,
        'nationalIdentificationNumber': null,
        'address': null,
        'city': null,
        'postalCode': null
      },
      postingToApi: false,
      loadingCompanyFromNbs: false,
      customers: null,

      snackbar: {
        show: false,
        text: "",
        isError: false
      },

      companyInfo: [
        { 'label': 'Naziv kompanije', 'propertyName': 'companyName' },
        { 'label': 'MB', 'propertyName': 'nationalIdentificationNumber' },
        { 'label': 'Adresa', 'propertyName': 'address' },
        { 'label': 'Grad', 'propertyName': 'city' },
        { 'label': 'Poštanski broj', 'propertyName': 'postalCode' }
      ]
    };
  },
  methods: {
    onAddUser() {
      this.postingToApi = true;

       let registrationEntity = {
        'companyTid': this.newUser.pib,
        'companyName': this.company.companyName,
        'companyAddress': this.company.address,
        'companyMn': this.company.nationalIdentificationNumber,
        'companyTown': this.company.city,
        'companyPostalCode': this.company.postalCode,
        'userName': this.newUser.email,
        'password': this.newUser.password,
        'firstName': this.newUser.firstName,
        'lastName': this.newUser.lastName,
        'email': this.newUser.email,
        'contactEmail': this.newUser.contactEmail,
        'phoneNumber': this.newUser.phoneNumber,
        'promoCode': this.newUser.promoCode,
      };

      console.log(registrationEntity);

      apiEdexAffiliates.registerEdexAffiliateUser(registrationEntity)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;

          this.showNotification("Dodat korisnik: " + responseData.email);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;
        });
    },
    getCompanyFromNbs() {
      this.loadingCompanyFromNbs = true;
      apiNbs.getCompanyInfo(this.newUser.pib)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.loadingCompanyFromNbs = false;
          console.log(responseData);
          this.company = responseData;
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.loadingCompanyFromNbs = false;
        });
    },
    onCancel() {
      this.$router.push('/users');
    },
    companySelected(company) {
      this.selectedCompany = company;

      this.getCustomersOfCompany(company.id);
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },
    getCompanyProperty(propertyName) {
      return this.company[propertyName];
    },
  },
};
</script>
