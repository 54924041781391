<template>
  <v-container class="mt-8" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8">
        <v-row align-center column justify-center>
          <files-list />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilesList from '../components/FilesList.vue'

export default {
  name: "FilesPage",
  components: {
    FilesList,
  }
}
</script>
