<template>
  <v-container>
    <v-card>
      <v-card-title>
        Kompanije
      </v-card-title>
      <v-card-subtitle>
        <v-row justify="end">
          <v-col cols="12" sm="4" class="py-0">
            <v-text-field
              v-model="queryString"
              append-icon="mdi-magnify"
              label="Pretraga"
              single-line
              hide-details
              clearable
              @keyup.enter.prevent='onEnterSearch'
              @click:clear='onClearSearch()'
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="12" sm="4" class="py-0">
            <v-switch v-model="isSearchByID" label="Pretraga po ID" @click="onSwitchSearch">
            </v-switch>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-data-table
      :headers="headers"
      :items="listOfCompanies"
      :server-items-length="options.page * options.itemsPerPage + 1"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
      @click:row="onRowClick"
      >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import * as apiCompanies from '../api/companies.js'
export default {
  name: "CompaniesList",
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true]
      },
      queryString: null,
      isSearchByID: false,
      listOfCompanies: [],
      headers: [
          { text: 'ID', value: 'id', width: "10%" },
          { text: 'Naziv', value: 'companyName', width: "25%" },
          { text: 'PIB', value: 'pib', width: "10%" },
          { text: 'Adresa', value: 'address' },
          { text: 'Grad', value: 'town', width: "20%" },
      ]
    }
  },
  watch: {
    options: {
      handler () {
        this.options.search = this.queryString;
        this.getCompaniesFromAPI()
      },
      deep: true,
    }
  },
  mounted () {
    this.getCompaniesFromAPI()
  },
  methods: {
    getCompaniesFromAPI() {
      this.loading = true

      apiCompanies.getList(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false
          this.listOfCompanies = responseData
        })
        .catch(() => {
          this.listOfCompanies = []
          this.loading = false
        })
    },

    onEnterSearch() {
      this.options.search = this.queryString
      this.options.page = 1
      this.getCompaniesFromAPI()
    },

    onClearSearch() {
      this.options.search = ''
      this.queryString = ''
      this.getCompaniesFromAPI()
    },

    onRowClick(company) {
      let route = this.$router.resolve({ path: '/companies/' + company.id });
      window.open(route.href, '_blank');
    },


     onSwitchSearch() {
      this.options.page = 1;
      this.options.isSearchByID = this.isSearchByID;
      this.options.search = this.queryString;
      this.getCompaniesFromAPI();
    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
