<template>
  <v-card v-if="loading === false" width="100%">
    <v-card-title>
      Naziv: {{ file.name }}
    </v-card-title>

    <v-card-subtitle>
      ID: {{ file.id }}
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col>
          <v-list dense>
            <v-list-item v-for="item in fileProperties" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle class="text-wrap">{{ getFilePropertyValue(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col>
          <v-list dense>
            <v-list-item v-for="item in fileStatusProperties" :key="item.label">
              <v-list-item-icon>
                <v-icon :color="getIconColor(item)">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getFileStatusPropertyValue(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn class="primary ma-2 white--text" text @click="onReloadFile" :loading="reloadFileInProgress">
        Ponovo učitaj fajl
        <v-icon right>mdi-refresh</v-icon>
      </v-btn>

      <v-btn class="primary ma-2 white--text" text @click="onDownloadFile" :loading="downloadFileInProgress">
        Preuzmi ulazni fajl
        <v-icon right>mdi-download</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
    </v-card-actions>

    <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show">
    </snackbar>
  </v-card>
</template>

<script>
import moment from "moment";
import * as apiFiles from "../api/files.js";

import Snackbar from "../components/Snackbar.vue"

export default {
  name: 'FileDetails',
  components: {
    Snackbar
  },
  data: function () {
    return {
      loading: false,
      reloadFileInProgress: false,
      downloadFileInProgress: false,
      fileProperties: [
        { label: "Tip", propertyName: 'type', icon: "mdi-file-document-outline" },
        { label: "Pošiljalac", propertyName: 'sender.name', icon: "mdi-home-export-outline" },
        { label: "Datum i vreme kreiranja", propertyName: 'createdAt', icon: "mdi-calendar-range" },
        { label: "Lokacija u arhivi", propertyName: 'archivePath', icon: "mdi-archive" },
        { label: "Folder pošiljaoca", propertyName: 'custFolder', icon: "mdi-folder" },
        { label: "Datum i vreme ažuriranja", propertyName: 'updatedAt', icon: "mdi-calendar-refresh" }
      ],

      fileStatusProperties: [
         { label: "Duplikat", propertyName: 'duplicate', icon: "mdi-check-all" },
         { label: "Greška", propertyName: 'error', icon: "mdi-alert" },
         { label: "Obrađen", propertyName: 'processed', icon: "mdi-check-circle" },
      ],

      snackbar: {
        show : false,
        text : "",
        isError : false
      },
    }
  },
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  methods: {
    getFilePropertyValue(propertyName) {
      let dateProperties = [ "createdAt", "updatedAt" ];
      if (dateProperties.includes(propertyName)) {
        return this.formatDateTime(this.file[propertyName]);
      }

      if (propertyName.includes(".")) {
        let stringParts = propertyName.split(".");
        let firstPart = stringParts[0];
        let secondPart = stringParts[1];

        let customer = this.file[firstPart];
        return customer[secondPart];
      }

      return this.file[propertyName];
    },

    getFileStatusPropertyValue(propertyName) {
      let value = this.file[propertyName];
      if (value === null || value === false) {
        return "Ne";
      }
      else {
        return "Da";
      }
    },

    getIconColor(item) {
      if (item.propertyName === "error" && this.file[item.propertyName] === true) {
        return "red";
      }
      else {
        return "";
      }
    },


    formatDateTime(datum) {
      return datum ? moment(datum).format('DD.MM.YYYY HH:mm:ss') : ''
    },

    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },

    onReloadFile() {
      this.reloadFileInProgress = true;

      apiFiles
        .postReloadFile(this.file.id, this.$store.getters.token)
        .then(response => response.text())
        .then(() => {
          // loading na dugmetu
          this.reloadFileInProgress = false;

          this.showNotification("Fajl " + this.file.id + " je uspešno ponovo učitan.");
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }
          this.reloadFileInProgress = false;
        });
    },

    onDownloadFile() {
      const endpoint = '/files/download/' + this.file.id;

      const requestHeaders = new Headers();
      requestHeaders.set('Authorization', 'Bearer ' + this.$store.getters.token);

      const requestOptions = { method: 'GET' };
      requestOptions.headers = requestHeaders;

      this.downloadFileInProgress = true;

      // do the fetch
      const amapsApiBaseUrl = process.env.VUE_APP_API_BASEURL;
      let fullEndpoint = amapsApiBaseUrl + endpoint;
      return fetch(fullEndpoint, requestOptions)
        .then(response => {
          const header = response.headers.get('content-disposition');
          const parts = header.split(';');
          let filename = parts[1].split('=')[1].replaceAll("\"", "");

          return response.blob()
            .then(blob => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download = filename;
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove();  //afterwards we remove the element again
              this.downloadFileInProgress = false;
            });
        })
        .catch(() => {
          this.downloadFileInProgress = false;
        });
    }
  },
}
</script>
