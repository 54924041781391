var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-card',{staticClass:"elevation-0",attrs:{"flat":"","rounded":false}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.listOfUsers,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [25, 50, 100],
      'items-per-page-text': 'Broj redova po stranici:'
    },"no-data-text":"Nema rezultata","loading-text":"Učitavanje podataka... Molimo sačekajte","width":"100%","elevation":"0"},scopedSlots:_vm._u([{key:"item.isSent",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":_vm.getSentToSefColor(item.isSent),"size":"15"}},'v-avatar',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getSentToSefTooltip(item.isSent)))])])]}},{key:"item.senderName",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pl-2",style:(_vm.getCompanyNameBorderStyle(item.sendersCompanyId, true))},'div',attrs,false),on),[_vm._v(_vm._s(_vm.getShortenedStr(item.senderName)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.senderName))])])]}},{key:"item.receiverName",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({style:(_vm.getCompanyNameBorderStyle(item.sendersCompanyId, false))},'div',attrs,false),on),[_vm._v(_vm._s(_vm.getShortenedStr(item.receiverName)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.receiverName))])])]}},{key:"item.edexStatusMessage",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(item.isSent ? '' : _vm.getShortenedStr(item.edexStatusMessage)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.edexStatusMessage))])])]}},{key:"item.sefComment",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(_vm.getShortenedStr(item.sefComment)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.sefComment))])])]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))])]}},{key:"item.validUntil",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.validUntil)))])]}},{key:"item.isSeflinkPlus",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.isSeflinkPlus ? 'mdi-check-bold' : ''))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('sef-link-user-details',{attrs:{"user":item,"token":_vm.token}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }