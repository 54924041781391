<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8">
        <v-row align-center column justify-center>
          <v-card width="80%" class="mb-6">
            <v-card-title> Dodavanje korisnika portala </v-card-title>

            <v-card-subtitle>
              <v-row column justify-left>
                <v-col sm="5" class="mt-2">
                  <span class="text-subtitle-1 font-weight-medium">Kompanija:</span>
                  {{ selectedCompany.companyName }}
                </v-col>
                <v-col sm="2">
                  <find-company-dialog
                    @update="companySelected"
                    icon="mdi-domain"
                    buttonText="Izaberite"
                    toolbarText="Biranje kompanije"
                  />
                </v-col>
                <v-spacer />
              </v-row>
            </v-card-subtitle>

            <v-card-subtitle>
              <v-row column justify-left>
                <v-col sm="5" class="mt-2">
                  <span class="text-subtitle-1 font-weight-medium">Portal:</span>
                  {{ selectedPortal.portalName }}
                </v-col>
                <v-col sm="2">
                  <find-portal-dialog
                    @update="portalSelected"
                    icon="mdi-monitor"
                    buttonText="Izaberite"
                    toolbarText="Biranje portala"
                  />
                </v-col>
                <v-spacer />
              </v-row>
            </v-card-subtitle>

            <v-card-subtitle>
              <v-row column justify-left>
                <v-col sm="5" class="mt-2">
                  <span class="text-subtitle-1 font-weight-medium">Centralni korisnik:</span>
                  {{ selectedUser.username }}
                </v-col>
                <v-col sm="2">
                  <find-user-dialog
                    @update="userSelected"
                    icon="mdi-account"
                    buttonText="Izaberite"
                    toolbarText="Biranje centralnog korisnika"
                    :disabled="selectedCompany.id === null"
                    :companyId="selectedCompany.id"
                  />
                </v-col>
                <v-spacer />
              </v-row>
            </v-card-subtitle>

            <v-card-text>
              <v-row class="mt-2">
                <v-col sm="12" md="6">
                  <v-row>
                    <v-col sm="12" class="pt-0 pb-0">
                      <v-text-field label="Korisničko ime" required outlined
                        v-model="newPortalUser.username"
                      ></v-text-field>
                    </v-col>

                    <v-col sm="12" class="pt-0 pb-0">
                      <v-select v-model="newPortalUser.role"
                        :items="roles" item-text="text" item-value="value"
                        label="Uloga" required outlined>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="12">
                  <v-select
                    v-model="newPortalUser.actions"
                    :items="actions"
                    :menu-props="{ maxHeight: '400' }"
                    :item-text="getActionName"
                    item-value="id"
                    label="Akcije"
                    multiple
                    hint="Izaberite akcije"
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text v-if="customers !== null">
              <v-card-title> Biranje isporučnih mesta vezanih za novog korisnika portala </v-card-title>

              <v-list dense>
                <v-list-item v-for="customer in customers" :key="customer.id">
                  <v-list-item-avatar>
                    <v-checkbox v-model="customer.selected">
                    </v-checkbox>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>Naziv: {{ customer.name }}</v-list-item-title>
                    <v-list-item-subtitle>Adresa: {{customer.address}}</v-list-item-subtitle>
                    <v-list-item-subtitle>Grad: {{customer.town}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>


            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error ma-2 white--text" text @click="onCancel">
                Odustani
                <v-icon right>mdi-close</v-icon>
              </v-btn>
              <v-btn class="primary ma-2 white--text" text @click="onAddUser" :loading="postingToApi">
                Dodaj korisnika portala
                <v-icon right>mdi-plus</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>

          <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show">
          </snackbar>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as apiPortalUsers from "../api/portalusers";
import * as apiPortals from "../api/portals.js";
import * as apiCustomers from "../api/customers";

import FindCompanyDialog from "../components/FindCompanyDialog.vue";
import FindPortalDialog from "../components/FindPortalDialog.vue";
import FindUserDialog from "../components/FindUserDialog.vue";
import Snackbar from "../components/Snackbar.vue";

export default {
  name: "PortalUserAddPage",
  components: {
    FindCompanyDialog,
    FindPortalDialog,
    FindUserDialog,
    Snackbar
  },
  data: function () {
    return {
      newPortalUser: {
        actions: []
      },
      postingToApi: false,
      customers: null,
      selectedCompany: {
        id: null
      },
      selectedPortal: {},
      selectedUser: {},

      snackbar: {
        show: false,
        text: "",
        isError: false
      },

      roles: [
        { value: 2, text: "2 - Podrazumevano" },
        { value: 3, text: "3 - Dobavljač" },
      ],

      actions: []
    };
  },
  mounted() {
    this.getActionsFromAPI();
  },
  methods: {
    onAddUser() {
      // TODO: validacija pre slanja na API
      this.postingToApi = true;

      this.newPortalUser.companyId = this.selectedCompany.id;
      this.newPortalUser.portalId = this.selectedPortal.id;
      this.newPortalUser.userId = this.selectedUser.id;
      this.newPortalUser.customerIds = this.customers.filter(c => c.selected === true).map(c => c.id);

      apiPortalUsers
        .postUser(this.newPortalUser, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;

          this.showNotification("Dodat korisnik portala: " + responseData.username);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;
        });
    },
    getActionsFromAPI () {
      apiPortals.getActions(this.$store.getters.token)
        .then(response => response.json())
        .then(actionsList => {
          this.actions = actionsList;
        })
        .catch(() => {
          this.actions = [];
        });
    },
    getCustomersOfCompany(companyId) {
      apiCustomers.getCustomersOfCompany(companyId, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          this.customers = responseData;
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }
        });
    },
    onCancel() {
      this.$router.push('/users');
    },
    companySelected(company) {
      this.selectedCompany = company;

      this.getCustomersOfCompany(company.id);
    },
    portalSelected(portal) {
      this.selectedPortal = portal;

      this.newPortalUser.actions = this.getDefaultActions(this.selectedPortal.id);
    },
    userSelected(user) {
      this.selectedUser = user;
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },
    getActionName(item) {
      return item.name + " (" + item.buttonText + ")";
    },
    getDefaultActions(portalId) {
      let defaultActionsNames = this.getDefaultActionNamesForPortal(portalId);
      let actions = [];
      defaultActionsNames.forEach(defaultAction => {
        actions.push(this.getActionId(defaultAction));
      });

      return actions;
    },
    getActionId(actionName) {
      let x =  this.actions.filter(a => a.name == actionName)[0];
      return x.id;
    },
    getDefaultActionNamesForPortal(portalId) {
      const CENTRAL = 0;
      const NS_PHARM = 1;
      const MEDICOM = 2;
      const VELEX = 3;
      const MEDICA_LINEA = 4;
      const KEPROM = 5;
      const ADOC = 8;
      const INPHARM = 9;

      let actionsPerPortal = {
        "resend_doc": [CENTRAL, NS_PHARM, MEDICOM, VELEX, MEDICA_LINEA, KEPROM, ADOC, INPHARM],
        "download_xml": [CENTRAL, NS_PHARM, MEDICOM, VELEX, MEDICA_LINEA, KEPROM, ADOC, INPHARM],
        "missing_invoice": [CENTRAL, NS_PHARM, MEDICOM, VELEX, MEDICA_LINEA, KEPROM, ADOC, INPHARM],
        "get_invoices_list": [CENTRAL, NS_PHARM, MEDICOM, VELEX, MEDICA_LINEA, KEPROM, ADOC, INPHARM],
        "get_orders_list": [CENTRAL, NS_PHARM, MEDICOM, VELEX, MEDICA_LINEA, ADOC, INPHARM],
        "get_responses_list": [CENTRAL, NS_PHARM, MEDICOM, VELEX, MEDICA_LINEA, ADOC, INPHARM],
        "get_packingLists_list": [CENTRAL],
        "create_order": [CENTRAL, NS_PHARM, MEDICOM, VELEX, MEDICA_LINEA, ADOC, INPHARM],
        "upload_order": [CENTRAL, NS_PHARM, MEDICOM, VELEX, MEDICA_LINEA, INPHARM],
        "get_cash_balance": [NS_PHARM],
        "download_pdf": [VELEX, KEPROM],
        "allow_tender_order": [MEDICA_LINEA]
      }

      let actionNames = Object.keys(actionsPerPortal).filter(key =>
        actionsPerPortal[key].includes(portalId)
      );

      return actionNames;
    }
  },
};
</script>
