<template>
  <v-container class="mt-6">
    <v-row align="center" justify="center">
      <v-col sm="12" md="2">
         <v-text-field
          v-model="tempFilters.filter"
          append-icon="mdi-magnify"
          label="Pretraga"
          hide-details
          clearable
          @keyup.enter.prevent='performSearch'
        ></v-text-field>
      </v-col>

      <v-col sm="12">
        <List-Sef-Link-Users
          :filters="filters"
          :token="edexToken"
        />
      </v-col>
      <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show">
      </snackbar>
    </v-row>
  </v-container>
</template>

<script>
import * as apiSefLink from "../api/seflink.js";
import Snackbar from "../components/Snackbar.vue";
import ListSefLinkUsers from '../components/ListSefLinkUsers.vue';

export default {
  name: "SefLinkListUsersPage",
  components: {
    Snackbar,
    ListSefLinkUsers
  },
  data: function () {
    return {
      edexToken: null,
      postingToApi: false,
      loadingCompanyFromNbs: false,
      tempFilters: {
        filter: null
      },
      filters: {
        filter: null
      },

      snackbar: {
        show: false,
        text: "",
        isError: false
      },
    };
  },
  mounted() {
    apiSefLink.loginToEdex()
      .then(response => response.json())
      .then(responseData => {
        this.edexToken = responseData.token;
      });
  },
  methods: {
    companySelected(company) {
      this.selectedCompany = company;

      this.getCustomersOfCompany(company.id);
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },
    performSearch() {
      this.filters.filter = this.tempFilters.filter;
    },
  },
};
</script>
