<template>
  <v-container fluid>
    <v-card elevation="4">
      <v-card-title>
        <v-row >
          <v-col cols="12" sm="2" class="pt-5 pb-3">
            <v-select dense
              :items="documentSearchTypes"
              v-model="documentSearchType"
              item-text="text"
              item-value="value"
              label="Tip pretrage"
              outlined
              hide-details>
            </v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="4" class="py-0">
            <v-text-field
              v-model="queryString"
              append-icon="mdi-magnify"
              label="Pretraga"
              single-line
              hide-details
              clearable
              @keyup.enter.prevent='onEnterSearch'
              @click:clear='onClearSearch()'
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
      :headers="headers"
      :items="listOfDocuments"
      :server-items-length="options.page * options.itemsPerPage + 1"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
      >
        <template v-slot:[`item.datum`]="{ item }">
          <span>{{ format_date(item.datum) }}</span>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ format_datetime(item.createdAt) }}</span>
        </template>

        <template v-slot:[`item.userProcessed`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar :color="getAvatarColor(item)" size="15" v-bind="attrs" v-on="on"></v-avatar>
            </template>
            <span>{{getAvatarTooltip(item)}}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.type`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">{{ getDocumentTypeIcon(item) }}</v-icon>
            </template>
            <span>{{getDocumentTypeTooltip(item)}}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.flOrderNum`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-if="item.flOrderNum !== null" v-bind="attrs" v-on="on"  @click="copyRefDocNumToClipboard(item)">
                mdi-reply
              </v-icon>
            </template>
            <span>Referencirani dokument: </span>
            <span ref="textToCopy">{{getRefDocTooltip(item)}}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="onRowClick(item)">mdi-eye</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import * as documentsAPI from '../api/documents.js';
import moment from 'moment';

export default {
  name: "DocumentsList",
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true]
      },
      queryString: null,
      documentSearchType: 2,

      listOptions: {
        sortDesc: [true]
      },

      listOfDocuments: [],
      headers: [
          { text: 'ID', value: 'id', width: "5%" },
          { text: '', value: 'type', width: "3%", sortable: false },
          { text: 'Broj dokumenta', value: 'broj', width: "9%" },
          { text: 'Otpremnica', value: 'otpremnica', width: "7%" },
          { text: 'Datum dokumenta', value: 'datum', width: "9%" },
          { text: 'Datum kreiranja', value: 'createdAt', width: "13%" },
          { text: 'Pošiljalac', value: 'senderName', width: "13%", sortable: false },
          { text: 'Primalac', value: 'receiverName', width: "13%", sortable: false },
          { text: 'Kompanija primalac', value: 'receiverCompanyName', width: "15%", sortable: false },
          { text: 'Ref', value: 'flOrderNum', width: "3%", sortable: false },
          { text: 'Preuzet', value: 'userProcessed', width: "3%", sortable: false },
          { text: 'Akcije', value: 'actions', width:"3%", sortable: false}
      ],
      documentSearchTypes: [
        { text: "Deo broja dokumenta", value: 1 },
        { text: "Ceo broj dokumenta", value: 2 },
        { text: "ID dokumenta", value: 3 },
        { text: "ID fajla", value: 4 },
        { text: "Broj otpremnice", value: 5 },
      ]
    }
  },
  props: {
    advancedSearch: {
      type: Object,
      required: false
    }
  },
  watch: {
    options: {
      handler () {
        this.copyListOptions();
        //this.options.search = this.queryString;
        this.getDocumentsFromAPI()
      },
      deep: true,
    },
    advancedSearch: {
      handler(newAdvancedSearch) {
        this.advancedSearch = newAdvancedSearch;
        this.getDocumentsFromAPI();
      },
      deep: true
    }
  },
  mounted () {
    this.getDocumentsFromAPI()
  },
  methods: {
    getDocumentsFromAPI() {
      this.loading = true;

      documentsAPI.getList(this.listOptions, this.advancedSearch, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false;
          this.listOfDocuments = responseData;
        })
        .catch(() => {
          this.listOfDocuments = [];
          this.loading = false;
        })
    },

    onEnterSearch() {
      this.options.page = 1;
      this.copyListOptions();
      this.getDocumentsFromAPI();
    },

    onClearSearch() {
      this.options.page = 1;
      this.queryString = '';
      this.copyListOptions();
      this.getDocumentsFromAPI()
    },

    onRowClick(document) {
      let route = this.$router.resolve({ path: '/documents/' + document.id });
      window.open(route.href, '_blank');
    },

    onSwitch() {
      this.options.page = 1;
      this.copyListOptions();
      this.getDocumentsFromAPI();
    },

    format_date(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },

    format_datetime(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
      }
    },

    getAvatarColor(item) {
      if (item.userProcessed)
        return "success";
      else
        return "error";
    },

    getAvatarTooltip(item) {
      if (item.userProcessed)
        return "Dokument je preuzet";
      else
        return "Dokument nije preuzet";
    },

    performAdvancedSearch() {
      // this.options.search = this.queryString;
      // this.options.page = 1;
      // this.options.documentSearchType = this.documentSearchType;
      this.options.page = 1;
      this.copyListOptions();
      this.getDocumentsFromAPI();
    },

    getDocumentTypeIcon(document) {
      if (document.type === 5) {
        return "mdi-pdf-box";
      }

      let documentTypeDict = {
        1: "f",
        2: "n",
        3: "o",
        4: "u",
        6: "k",
        7: "i",
        8: "t",
        9: "p",
        10: "l"
      }

      return "mdi-alpha-" + documentTypeDict[document.type] + "-box";
    },

    getDocumentTypeTooltip(document) {
      let documentTypeDict = {
        1: "Faktura",
        2: "Narudžbina",
        3: "Odgovor",
        4: "Uslovi",
        5: "Faktura PDF",
        6: "Knjiška odobrenja",
        7: "Izvod otvorenih stavki",
        8: "Otpremnica",
        9: "Povratnica",
        10: "Lager"
      }

      return documentTypeDict[document.type];
    },

    getRefDocTooltip(document) {
      return document.flOrderNum;
    },

    copyRefDocNumToClipboard(document) {
      navigator.clipboard.writeText(document.flOrderNum);
    },

    copyListOptions() {
      this.listOptions.pageNum = this.options.page;
      this.listOptions.pageSize = this.options.itemsPerPage;
      this.listOptions.sortBy = this.options.sortBy;
      this.listOptions.sortDesc = this.options.sortDesc;
      this.listOptions.query = this.queryString;
      this.listOptions.documentSearchType = this.documentSearchType;
    }
  }
}
</script>

<style scoped>
/* .row-pointer >>> tbody tr :hover {
  cursor: pointer;
} */
</style>
