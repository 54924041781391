import * as fetch from './fetch'

export function deleteLocalCode(localCodeId, token) {
  const endpoint = '/localcodes/' + localCodeId;
  return fetch.DELETE(endpoint, token, true);
}

export function putLocalCode(id, updatedLocalCode, token) {
  const endpoint = '/localcodes/' + id;
  return fetch.PUT(endpoint, token, updatedLocalCode, true);
}

export function getLocalCodeByCustomerId(customerId, token) {
  const endpoint = '/localcodes/byCustomer/' + customerId;
  return fetch.GET(endpoint, token, true);
}

export function postLocalCode(newLocalCode, token) {
  const endpoint = '/localcodes';
  return fetch.POST(endpoint, token, newLocalCode, true);
}
