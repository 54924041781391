import * as fetch from './fetch.js'

export function deleteFarmalogist(id, token) {
  const endpoint = '/farmalogistb2b/' + id;
  return fetch.DELETE(endpoint, token, true);
}

export function putFarmalogist(id, updatedFarmalogist, token) {
  const endpoint = '/farmalogistb2b/' + id;
  return fetch.PUT(endpoint, token, updatedFarmalogist, true);
}

export function postFarmalogist(farmalogist, token) {
  const endpoint = '/farmalogistb2b';
  return fetch.POST(endpoint, token, farmalogist, true);
}

export function getFarmalogistByCustomerId(customerId, token) {
  const endpoint = '/farmalogistB2B/byCustomer/' + customerId;
  return fetch.GET(endpoint, token, true);
}
