<template>
  <div>
    <v-dialog v-model="dialogEditLocalCode" @keydown.esc="onCancel" persistent max-width="700px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Izmena lokalnog koda
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-list dense>
            <v-list-item v-for="item in localCodeValues" :key="item.label">
              <v-text-field class="pt-0 pb-0" :disabled="item.disabled" :prepend-icon="item.icon" :label="item.label" v-model="updatedLocalCode[item.value]" ></v-text-field>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-btn class="error ma-2 white--text" text @click="dialogConfirmDelete = true" :loading="deleting">
            Obriši
            <v-icon right>mdi-trash-can-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" color="red" outlined @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn class="success ma-2 white--text" text @click="dialogConfirmSave = true" :loading="puttingToApi">
            Sačuvaj izmene
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmSave" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Da li želite da sačuvate promene?
        </v-card-title>

         <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text plain ma-2" text @click="dialogConfirmSave = false">
            Odustani
          </v-btn>
          <v-btn class="green--text plain ma-2" text @click="onConfirmEdit" :loading="puttingToApi">
            Sačuvaj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmDelete" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Da li želite da obrišete lokalni kod?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text plain ma-2" outlined @click="dialogConfirmDelete = false">
            Ne
          </v-btn>
          <v-btn class="error ma-2" text @click="onConfirmDelete" :loading="deleting">
            Da
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as localCodesAPI from '../api/localcodes'

export default {
  name: 'EditLocalCodeDialog',
  data: function () {
    return {
      dialogEditLocalCode: false,
      dialogConfirmSave: false,
      dialogConfirmDelete: false,
      puttingToApi: false,
      deleting: false,
      updatedLocalCode: {},
      localCodeValues: [
        { label: "ID kompanije", value: 'companyID', icon: "mdi-domain", disabled: true },
        { label: "ID isporučnog mesta", value: 'customerID', icon: "mdi-map-marker", disabled: true },
        { label: "Kod", value: 'code', icon: "mdi-lock", disabled: false }
      ]
    }
  },
  props: {
    localCode: {
      type: Object,
      required: true
    }
  },
  watch: {
    dialogEditLocalCode(newValue) {
      if (newValue === true) {
        this.shallowCopy();
      }
    }
  },
  mounted () {
    // Kopiranje objekta
    this.shallowCopy();
  },
  methods: {
    shallowCopy() {
      this.updatedLocalCode = JSON.parse(JSON.stringify(this.localCode));
    },
    onCancel() {
      this.shallowCopy();
      this.dialogEditLocalCode = false;
    },
    onConfirmEdit() {
      // TODO: validacija pre slanja na API

      this.puttingToApi = true;

      localCodesAPI.putLocalCode(this.localCode.id, this.updatedLocalCode, this.$store.getters.token)
        .then(response => response.json())
        .then(() => {
          // loading na dugmetu
          this.puttingToApi = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmSave = false;
          this.dialogEditLocalCode = false;

          // brisanje unetih podataka za novu kompaniju
          this.updatedLocalCode = {};

          this.$emit('update', this.localCode.customerID);
        })
        .catch(() => {
          this.puttingToApi = false;
        });
    },
    onConfirmDelete() {
      this.deleting = true;

      localCodesAPI.deleteLocalCode(this.localCode.id, this.$store.getters.token)
        .then(response => response.json())
        .then(() => {
          // loading na dugmetu
          this.deleting = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmDelete = false;
          this.dialogEditFarmalogist = false;

          this.$emit('update', this.localCode.customerID);
        })
        .catch(() => {
          this.deleting = false;
        });
    }
  }
}
</script>
