<template>
  <v-container class="mt-8" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="11">
        <v-row align-center column justify-center>
          <documents-advanced-search @updateAdvancedSearch="onChangeAdvancedSearch" />
          <documents-list :advancedSearch="advancedSearch" />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DocumentsList from '../components/DocumentsList.vue'
import DocumentsAdvancedSearch from '../components/DocumentsAdvancedSearch.vue'

export default {
  name: "DocumentsPage",
  components: {
    DocumentsList,
    DocumentsAdvancedSearch,
  },
  data: function () {
    return {
      advancedSearch: {
        docType: null,
        datumDokumentaOd: null,
        datumDokumentaDo: null,
        datumKreiranjaOd: null,
        datumKreiranjaDo: null,
        sender: {
          name: null
        },
        receiver: {
          name: null
        },
        userProcessed: "null"
      }
    }
  },
  methods: {
    onChangeAdvancedSearch(newAdvancedSearch) {
      //console.log("DocumentsPage.vue: received event");
      //this.$set(this, "advancedSearch", newAdvancedSearch);
      this.advancedSearch = newAdvancedSearch;
    }
  }
}
</script>
