<template>
  <v-dialog v-model="dialogConfirmResend" @keydown.esc="dialogConfirmResend = false" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
       <v-btn icon text variant="outlined"  v-bind="attrs" v-on="on">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="20.000000pt" height="20.000000pt" viewBox="0 0 500.000000 500.000000"
          preserveAspectRatio="xMidYMid meet">

          <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
          fill="#000000" stroke="none">
          <path d="M2245 4153 c-349 -45 -687 -205 -938 -443 -177 -168 -316 -374 -401
          -591 -84 -216 -119 -396 -119 -619 0 -223 35 -403 119 -619 174 -444 567 -817
          1010 -956 202 -64 273 -76 474 -82 219 -6 340 8 531 63 324 93 636 310 854
          594 41 54 75 100 75 102 0 2 25 49 55 103 56 101 103 211 135 318 l18 57 -213
          0 -213 0 -55 -112 c-120 -249 -307 -446 -541 -572 -305 -164 -682 -190 -1016
          -70 -288 103 -542 325 -679 593 -101 199 -143 367 -143 581 0 396 181 760 497
          997 150 113 305 184 500 229 113 27 387 27 500 0 129 -30 293 -94 388 -153 84
          -52 237 -177 237 -193 0 -4 -149 -157 -331 -339 l-331 -331 726 0 726 0 0 726
          0 725 -241 -240 c-133 -133 -244 -241 -246 -241 -3 0 -37 30 -76 66 -78 72
          -219 169 -339 234 -135 72 -344 139 -516 165 -89 14 -367 18 -447 8z"/>
          <path d="M1995 3294 c-168 -24 -282 -65 -361 -129 -186 -153 -191 -503 -10
          -662 87 -77 169 -109 431 -169 237 -54 305 -105 305 -229 0 -130 -76 -198
          -232 -211 -158 -13 -307 45 -419 163 -38 40 -75 73 -83 73 -8 0 -27 -17 -42
          -37 -16 -21 -54 -68 -87 -106 l-59 -67 22 -35 c37 -59 130 -134 225 -180 109
          -53 182 -72 320 -85 273 -26 517 63 608 221 91 156 99 353 21 509 -72 144
          -188 209 -476 269 -247 52 -312 81 -344 158 -51 119 34 226 193 243 156 16
          300 -22 390 -104 22 -20 47 -36 55 -36 14 0 116 124 153 188 19 31 19 32 -5
          57 -60 64 -185 125 -305 149 -74 16 -250 27 -300 20z"/>
          </g>
          </svg>
        </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Da li želite da ponovo pošaljete dokument na SEF?
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="red--text plain ma-2" text @click="dialogConfirmResend = false">
          Odustani
        </v-btn>
        <v-btn class="green--text plain ma-2" text @click="onConfirmResend" :loading="resending">
          Pošalji ponovo
        </v-btn>

        <v-row>
          <v-col sm="12" v-if="errorMsg != null">
           {{ errorMsg }}
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as apiSefLink from '../api/seflink.js';

export default {
  name: 'ConfirmResendDocumentToSef',
  data: function () {
    return {
      dialogConfirmResend: false,
      resending: false,
      errorMsg: null
    }
  },
  props: {
    documentId: {
      type: Number,
      required: true
    },
    token: {
      type: String,
      default: "",
      required: false
    }
  },
  methods: {
    onConfirmResend() {
      this.resending = true;
      this.errorMsg= null;
      apiSefLink.resendDocumentToSef(this.documentId, this.token)
        .then(() => {
          this.resending = false;
          this.errorMsg = null;
        })
        .catch((error) => {
          this.resending = false;
          this.errorMsg = error;
        })
    },
  }
}
</script>
