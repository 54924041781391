<template>
  <v-app-bar clipped-left app dense color="red darken-4" dark style="z-index: 4;">
    <v-toolbar-title link @click="goToHome()" style="cursor:pointer;">Admin Tool</v-toolbar-title>
    <v-spacer />
    <v-btn class="button-class" @click="logout()" v-if="this.$store.getters.isLoggedIn === true">
      Log out
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: 'Toolbar',
  methods: {
    logout () {
      this.$store.commit('token', null)
      this.goToHome()
    },
    goToHome () {
      if (this.$router.currentRoute.path !== '/') {
        this.$router.push('/')
        return
      }

      if (this.$store.getters.isLoggedIn === false
        && this.$router.currentRoute.path !== '/login') {
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style scoped>
.button-class{
  margin-left: 10px;
  margin-right: 10px;
}
</style>
