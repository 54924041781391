<template>
  <div>
    <v-dialog v-model="dialogNewLocalCode" @keydown.esc="onCancel" persistent max-width="60%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="primary" dark v-bind="attrs" v-on="on">
          Dodaj
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Dodavanje lokalnog koda
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-row class="mt-4">
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-text-field prepend-icon="mdi-map-marker" label="ID isporučnog mesta" disabled outlined v-model="newLocalCode.customerID"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-text-field prepend-icon="mdi-domain"  label="ID kompanije" disabled outlined v-model="newLocalCode.companyID"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-0">
                    <v-text-field label="Kod *" required outlined v-model="newLocalCode.code"></v-text-field>
                  </v-col>
                   <small>* označava neophodna polja</small>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error ma-2 white--text" text @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn class="primary ma-2 white--text" text @click="onAddLocalCode" :loading="postingToApi">
            Dodaj lokalni kod
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="5000" color="success">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn text icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
  </div>
</template>

<script>
import * as localCodesAPI from '../api/localcodes.js'

export default {
  name: "AddLocalCodeDialog",
  data: function () {
    return {
      dialogNewLocalCode: false,
      postingToApi: false,
      newLocalCode: this.getDefaultNewLocalCode(),
      snackbar: false,
      snackbarText: ""
    }
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  methods: {
    getDefaultNewLocalCode() {
      return {
        customerID: this.customer.id,
        companyID: this.customer.companyId
      }
    },
    onAddLocalCode () {
      // TODO: validacija pre slanja na API

      this.postingToApi = true;

      localCodesAPI.postLocalCode(this.newLocalCode, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          // loading na dugmetu
          this.postingToApi = false;

          // zatvaranje modalnog prozora
          this.dialogNewLocalCode = false;

          // prikaz snackbar obavestenja
          this.snackbarText = "Dodat lokalni kod sa ID-jem: " + responseData.id;
          this.snackbar = true;

          // brisanje unetih podataka za novu kompaniju
          this.newLocalCode = this.getDefaultNewLocalCode();

          this.$emit('update', responseData.customerID);
        })
        .catch(() => {
          this.postingToApi = false;
        });
    },
    onCancel () {
      this.dialogNewLocalCode = false;
      this.newLocalCode = this.getDefaultNewLocalCode();
    }
  }
}
</script>
