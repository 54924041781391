import * as fetch from './fetch'

export function putCompany(id, updatedCompany, token) {
  const endpoint = '/companies/' + id;
  return fetch.PUT(endpoint, token, updatedCompany, true);
}

export function postCompany(newCompany, token) {
  const endpoint = '/companies'
  return fetch.POST(endpoint, token, newCompany)
}

export function getCompany(id, token) {
  const endpoint = '/companies/' + id
  return fetch.GET(endpoint, token, true)
}

export function getList(options, token) {
  let parameters = mapToParameters(options)

  const endpoint = '/companies'
  let queryString = new URLSearchParams(parameters).toString()
  return fetch.GET(endpoint + '?' + queryString, token, true)
}

function mapToParameters(options) {
  let parameters = {}

  if (options.sortBy.length !== 0) {
    parameters.orderBy = options.sortBy
  }

  let sortDesc = options.sortDesc[0]
  if (sortDesc !== null) {
    parameters.orderAsc = !sortDesc
  }

  if (options.page !== null) {
    parameters.pageNum = options.page
  }

  if (options.itemsPerPage !== null) {
    parameters.pageSize = options.itemsPerPage
  }

  if (options.search) {
    parameters.query = options.search
  }

  if (options.isSearchByID !== null) {
    parameters.searchById = options.isSearchByID;
  }

  return parameters
}
