
export function getCompanyInfo(tid) {
  const requestOptions = {
    method: 'GET'
  };

  // do the fetch
  return fetch('http://207.154.226.86:5001/api/nbs/companyInfo/' + tid, requestOptions)
    .then(response => {
      if (response.ok) {
        return response;
      } else {
        throw response;
      }
    })
}
