<template>
  <v-container class="mt-6">
    <v-row align="center" justify="center">
      <v-col sm="12" md="2">
         <v-text-field
          v-model="tempFilters.senderCompany"
          append-icon="mdi-magnify"
          label="Pošiljalac"
          hide-details
          clearable
          @keyup.enter.prevent='performSearch'
        ></v-text-field>
      </v-col>
      <v-col sm="12" md="2">
         <v-text-field
          v-model="tempFilters.receiverCompany"
          append-icon="mdi-magnify"
          label="Primalac"
          hide-details
          clearable
          @keyup.enter.prevent='performSearch'
        ></v-text-field>
      </v-col>
      <v-col sm="12" md="2">
         <v-text-field
          v-model="tempFilters.documentNumber"
          append-icon="mdi-magnify"
          label="Broj dokumenta"
          hide-details
          clearable
          @keyup.enter.prevent='performSearch'
        ></v-text-field>
      </v-col>

      <v-col sm="12" md="2">
        <v-select
        :items="documentTypesValues"
        v-model="tempFilters.documentType"
        item-text="text"
        item-value="value"
        label="Tip dokumenta"
        outlined dense hide-details clearable></v-select>
      </v-col>

       <v-col sm="12" md="2">
        <v-btn class="primary ma-2 white--text" text @click="performSearch">
          Filtriraj
        </v-btn>
      </v-col>

      <v-col sm="12">
        <List-Sef-Link-Admin-Documents
          :filters="filters"
          :token="edexToken"
        />
      </v-col>
      <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show">
      </snackbar>
    </v-row>
  </v-container>
</template>

<script>
import * as apiSefLink from "../api/seflink.js";
import Snackbar from "../components/Snackbar.vue";
import ListSefLinkAdminDocuments from '../components/ListSefLinkAdminDocuments.vue';

export default {
  name: "SefLinkListDocumentsPage",
  components: {
    Snackbar,
    ListSefLinkAdminDocuments
  },
  data: function () {
    return {

      documentTypesValues: [
        { id: "380", text: "Faktura", value: "380" },
        { id: "381", text: "Knjižno odobrenje", value: "381" },
        { id: "383", text: "Knjižno zaduženje", value: "383" },
        { id: "386", text: "Avansna faktura", value: "386" },
      ],

      edexToken: null,
      postingToApi: false,
      loadingCompanyFromNbs: false,
      customers: null,

      snackbar: {
        show: false,
        text: "",
        isError: false
      },
      filters: {
        senderCompany: null,
        receiverCompany: null,
        documentNumber: null,
        documentType: null
      },
      tempFilters: {
        senderCompany: null,
        receiverCompany: null,
        documentNumber: null,
        documentType: null
      }
    };
  },
  mounted() {
    apiSefLink.loginToEdex()
      .then(response => response.json())
      .then(responseData => {
        this.edexToken = responseData.token;
      });
  },
  methods: {
    companySelected(company) {
      this.selectedCompany = company;

      this.getCustomersOfCompany(company.id);
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },
    performSearch() {
      this.filters.senderCompany = this.tempFilters.senderCompany;
      this.filters.receiverCompany = this.tempFilters.receiverCompany;
      this.filters.documentNumber = this.tempFilters.documentNumber;
      this.filters.documentType = this.tempFilters.documentType;
    },
  },
};
</script>
