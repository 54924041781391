<template>
  <v-card v-if="loading === false" width="100%" class="mt-4">
    <v-card-title>
      Farmalogist B2B
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text v-if="farmalogistList.length > 0">
      <v-list dense>
        <v-list-item v-for="item in farmalogistList" :key="item.id">
          <v-list-item-icon>
            <v-icon>mdi-key</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Korisničko ime: {{ item.username }}</v-list-item-title>
            <v-list-item-subtitle>Lozinka: {{ item.password }}</v-list-item-subtitle>
            <v-list-item-subtitle>Comitent Key: {{ item.comitentKey }}</v-list-item-subtitle>

          </v-list-item-content>

          <v-list-item-action>
            <edit-farmalogist-dialog :farmalogist="item"  @update="getFarmalogistB2B" />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-text v-else>
      Nema podataka.
    </v-card-text>
    <v-card-actions>
      <add-farmalogist-dialog :customerId="customerId" @update="getFarmalogistB2B" />
    </v-card-actions>
  </v-card>
</template>

<script>
import * as apiFarmalogist from '../api/farmalogistB2B.js'
import AddFarmalogistDialog from './AddFarmalogistDialog'
import EditFarmalogistDialog from './EditFarmalogistDialog'

export default {
  name: "FarmalogistForCustomer",
  components: {
    AddFarmalogistDialog,
    EditFarmalogistDialog
  },
  data: function () {
    return {
      loading: false,
      farmalogistList: [],
      farmalogistToUpdate: {}
    }
  },
  props: {
    customerId: {
      type: Number,
      required: false
    }
  },
  mounted () {
    this.getFarmalogistB2B()
  },
  methods: {
    getFarmalogistB2B() {
      this.loading = true;

      apiFarmalogist.getFarmalogistByCustomerId(this.customerId, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.farmalogistList = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.farmalogistList = [];
          this.loading = false;
        })
    }
  }
}
</script>
