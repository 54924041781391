<template>
  <div>
    <v-dialog v-model="dialogEditCompany"  @keydown.esc="onCancel" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="primary mt-3" dark v-bind="attrs" v-on="on">
          Izmeni
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Izmena kompanije
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-list dense>
            <v-list-item v-for="item in companyInformation" :key="item.label">
              <v-textarea
              v-if="item.label === 'Opis'"
              :prepend-icon="item.icon"
              :label="item.label"
              v-model="updatedCompany[item.value]"
              filled auto-grow class="pt-0 pb-0">
              </v-textarea>
              <v-text-field class="pt-0 pb-0" v-else :prepend-icon="item.icon" :label="item.label" v-model="updatedCompany[item.value]" ></v-text-field>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error ma-2 white--text" text @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn class="success ma-2 white--text" text @click="dialogConfirmSave = true" :loading="puttingToApi">
            Sačuvaj izmene
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmSave" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Da li želite da sačuvate promene?
        </v-card-title>

         <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text plain ma-2" text @click="dialogConfirmSave = false">
            Odustani
          </v-btn>
          <v-btn class="green--text plain ma-2" text @click="onConfirmEdit" :loading="puttingToApi">
            Sačuvaj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as companiesApi from '../api/companies'

export default {
  name: 'EditCompanyDialog',
  data: function () {
    return {
      dialogEditCompany: false,
      dialogConfirmSave: false,
      puttingToApi: false,
      updatedCompany: {},
      companyInformation: [
        { label: "Naziv", value: 'companyName', icon: "mdi-alphabetical" },
        { label: "PIB", value: 'pib', icon: "mdi-alpha-p-box" },
        { label: "Opis", value: 'description', icon: "mdi-text-subject" },
        { label: "Grad", value: 'town', icon: "mdi-city-variant" },
        { label: "Adresa", value: 'address', icon: "mdi-map-marker" },
        { label: "Telefon", value: 'telephone', icon: "mdi-phone" },
        { label: "Kontakt osoba", value: 'contactPerson', icon: "mdi-account-tie" },
        { label: "Email", value: 'email', icon: "mdi-email" },
        { label: "GLN", value: 'gln', icon: "mdi-barcode" }
      ]
    }
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  watch: {
    dialogEditCompany(newValue) {
      if (newValue === true) {
        this.shallowCopy();
      }
    }
  },
  mounted () {
    // Kopiranje objekta
    this.shallowCopy();
  },
  methods: {
    shallowCopy() {
      this.updatedCompany = JSON.parse(JSON.stringify(this.company));
    },
    onCancel() {
      this.shallowCopy();
      this.dialogEditCompany = false;
    },
    onConfirmEdit() {
      // TODO: validacija pre slanja na API

      this.puttingToApi = true;

      companiesApi.putCompany(this.company.id, this.updatedCompany, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          // loading na dugmetu
          this.puttingToApi = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmSave = false;
          this.dialogEditCompany = false;

          // brisanje unetih podataka za novu kompaniju
          this.updatedCompany = {};

          this.$emit('update', responseData.id);
        })
        .catch(() => {
          this.puttingToApi = false;
        });
    }
  }
}
</script>
