<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10">
        <v-row align-center column justify-center>

          <customers-list />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomersList from '../components/CustomersList.vue'

export default {
  name: "CompaniesPage",
  components: {
    CustomersList
  }
}
</script>
