<template>
  <v-dialog v-model="dialogSefLinkUserDetails" @keydown.esc="onCancel" persistent max-width="1200px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </template>
    <v-card width="100%">
      <v-toolbar dense dark color="primary">
        <v-btn icon dark @click="onCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          Podaci o SEF Link korisniku
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-title>
        Naziv: {{ user.name }}
      </v-card-title>

      <v-card-subtitle>
        ID: {{ user.id }}
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col>
            <v-list dense>
              <v-list-item v-for="item in userProperties.slice(0, userProperties.length / 2)" :key="item.label">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                  <v-list-item-subtitle class="text-wrap">{{ getPropertyValue(item.propertyName) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col>
            <v-list dense>
              <v-list-item v-for="item in userProperties.slice(userProperties.length / 2, userProperties.length)" :key="item.label">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                  <v-list-item-subtitle class="text-wrap">{{ getPropertyValue(item.propertyName) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <!-- <v-col>
            <v-list dense>
              <v-list-item v-for="item in userStatusProperties" :key="item.label">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                  <v-list-item-subtitle>{{ getBooleanPropertyValue(item.propertyName) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-list dense>
              <v-list-item v-for="item in userSecretProperties" :key="item.label">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                  <v-list-item-subtitle>{{ getPropertyValue(item.propertyName) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col> -->
        </v-row>

        <v-row>
          <v-data-table
            :headers="userStatisticsHeaders"
            :items="userStatistics"
            :loading="loadingStatistics"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50],
              'items-per-page-text': 'Broj redova po stranici:'
            }"
            no-data-text="Nema rezultata"
            loading-text="Učitavanje podataka... Molimo sačekajte"
            class="elevation-1 row-pointer"
            width="100%"
            >
               <template v-slot:[`item.lastLogin`]="{ item }">
                <span>{{ formatDate(item.lastLogin) }}</span>
              </template>
            </v-data-table>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import * as seflinkApi from '../api/seflink.js';

export default {
  name: 'SefLinkUserDetails',
  data: function () {
    return {
      loadingStatistics: false,
      dialogSefLinkUserDetails: false,
      userProperties: [
        { label: "Naziv", propertyName: 'name', icon: "mdi-account" },
        { label: "PIB", propertyName: 'tid', icon: "mdi-account" },
        { label: "MB", propertyName: 'mn', icon: "mdi-account" },
        { label: "Adresa", propertyName: 'address', icon: "mdi-map-marker" },
        { label: "Grad", propertyName: 'town', icon: "mdi-city-variant" },
        { label: "Poštanski broj", propertyName: 'postalCode', icon: "mdi-city-variant" },
        { label: "Kontakt email", propertyName: 'contactEmail', icon: "mdi-email" },
        { label: "SEF Link +", propertyName: 'isSeflinkPlus', icon: "" },
        { label: "Datum dodavanja", propertyName: 'createdAt', icon: "" },
        { label: "Status supskripcije", propertyName: 'subscriptionStatus', icon: "" },
        { label: "Datum isteka supskripcije", propertyName: 'validUntil', icon: "" },
      ],

      userStatistics: [],
      userStatisticsHeaders: [
        { text: 'Verzija operativnog sistema', value: 'osVersion', sortable: false },
        { text: 'Verzija aplikacije', value: 'appVersion', sortable: false, align: 'center' },
        { text: 'ID uređaja', value: 'deviceId', sortable: false,  align: 'center' },
        { text: 'Naziv uređaja', value: 'computerName', sortable: false },
        { text: 'Broj loginova u poslednjih mesec dana', value: 'numberOfLoginsInLastMonth', sortable: false },
        { text: 'Broj loginova u poslednjih nedelju dana', value: 'numberOfLoginsInLastWeek', sortable: false },
        { text: 'Ukupan broj loginova', value: 'totalLogins', sortable: false },
        { text: 'Poslednji login', value: 'lastLogin', sortable: true },
      ],

      snackbar: {
        show : false,
        text : "",
        isError : false
      },
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.getUserStatistics();
  },
  methods: {
    getPropertyValue(propertyName) {
      let dateProperties = [ "createdAt", "validUntil" ];
      if (dateProperties.includes(propertyName)) {
        return this.formatDate(this.user[propertyName]);
      }

      if (propertyName.includes(".")) {
        let stringParts = propertyName.split(".");
        let firstPart = stringParts[0];
        let secondPart = stringParts[1];

        let company = this.user[firstPart];
        return company[secondPart];
      }

      return this.user[propertyName];
    },

    getBooleanPropertyValue(propertyName) {
      let value = this.user[propertyName];
      if (value === null || value === 0) {
        return "Ne";
      }
      else {
        return "Da";
      }
    },

    formatDateTime(datum) {
      return datum ? moment(datum).format('DD.MM.YYYY HH:mm:ss') : ''
    },

    formatDate(datum) {
      return datum ? moment(datum).format('DD.MM.YYYY') : ''
    },

    onCancel() {
      this.dialogSefLinkUserDetails = false;
    },

    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },

    getUserStatistics() {
      this.loadingStatistics = true;
      if (this.token === null) {
        return;
      }

      seflinkApi.getUserStatistics(this.user.id, this.token)
        .then(responseData => {
          this.loadingStatistics= false;
          this.userStatistics = responseData;
        })
        .catch((error) => {
          this.userStatistics = [];
          this.loadingStatistics = false;
          console.log(error);
        })
    }
  },
}
</script>
