<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10">
        <v-row align-center column justify-center>
          <v-row class="mt-2 mb-2">
            <v-col cols="12" sm="12" md="6">
              <v-btn class="primary ma-2 " dark to="/codebooks/create">
                Dodaj šifarnik
                <v-icon>mdi-plus</v-icon>
              </v-btn>

              <v-btn class="primary ma-2 " dark to="/codebooks/pairing">
                Uparivanje
                <v-icon class="ml-2">mdi-book-sync-outline</v-icon>
              </v-btn>

              <v-spacer></v-spacer>
            </v-col>
            <v-spacer />
          </v-row>
          <codebooks-list />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import CodebooksList from "../components/CodebooksList";
export default {
  name: "CodebooksPage",
  components: {
    CodebooksList,
  },
};
</script>
