<template>
  <v-card v-if="loading === false" width="100%">
    <v-card-title>
      Naziv: {{ user.name }}
    </v-card-title>

    <v-card-subtitle>
      ID: {{ user.id }}
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col>
          <v-list dense>
            <v-list-item v-for="item in userProperties" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle class="text-wrap">{{ getPropertyValue(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col>
          <v-list dense>
            <v-list-item v-for="item in userStatusProperties" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getBooleanPropertyValue(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-list dense>
            <v-list-item v-for="item in userSecretProperties" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getPropertyValue(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <!-- <v-btn class="error ma-2 white--text" text @click="dialogConfirmDelete = true" :loading="deleting">
        Obriši
        <v-icon right>mdi-trash-can-outline</v-icon>
      </v-btn> -->
      <v-spacer></v-spacer>
      <edit-user-dialog :user="user" v-if="loading === false" />
    </v-card-actions>

    <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show">
    </snackbar>

    <!-- <v-dialog v-model="dialogConfirmDelete" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Da li želite da obrišete korisnika?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text plain ma-2" outlined @click="dialogConfirmDelete = false">
            Ne
          </v-btn>
          <v-btn class="error ma-2" text @click="onConfirmDelete" :loading="deleting">
            Da
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-card>
</template>

<script>
import moment from "moment";
import * as apiUsers from "../api/users.js";

import EditUserDialog from "./EditUserDialog.vue";
import Snackbar from "../components/Snackbar.vue";

export default {
  name: 'UserDetails',
  components: {
    EditUserDialog,
    Snackbar
  },
  data: function () {
    return {
      loading: false,
      reloadFileInProgress: false,
      userProperties: [
        { label: "Korisničko ime", propertyName: 'username', icon: "mdi-account" },
        { label: "Email", propertyName: 'email', icon: "mdi-email" },
        { label: "Kompanija", propertyName: 'company.companyName', icon: "mdi-domain" },
        { label: "Uloga", propertyName: 'role', icon: "mdi-badge-account" },
        { label: "Datum i vreme kreiranja", propertyName: 'createdAt', icon: "mdi-calendar-range" },
        { label: "Datum i vreme ažuriranja", propertyName: 'updatedAt', icon: "mdi-calendar-refresh" }
      ],

      userStatusProperties: [
        { label: "Aktivan", propertyName: 'active', icon: "mdi-check-circle" },
        { label: "Aktiviran", propertyName: 'activated', icon: "mdi-check-circle" },
      ],

      userSecretProperties: [
        { label: "Lozinka za Login", propertyName: "password", icon: "mdi-lock" },
        { label: "Lozinka za API", propertyName: "password2", icon: "mdi-lock" },
        { label: "Lozinka temp", propertyName: "passwordTemp", icon: "mdi-lock" },
        { label: "Lozinka za FTP", propertyName: "ftp", icon: "mdi-lock" },
        { label: "Kod", propertyName: "code", icon: "mdi-lock" },
      ],

      snackbar: {
        show : false,
        text : "",
        isError : false
      },

      dialogConfirmDelete: false,
      deleting: false
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  methods: {
    getPropertyValue(propertyName) {
      let dateProperties = [ "createdAt", "updatedAt" ];
      if (dateProperties.includes(propertyName)) {
        return this.formatDateTime(this.user[propertyName]);
      }

      if (propertyName.includes(".")) {
        let stringParts = propertyName.split(".");
        let firstPart = stringParts[0];
        let secondPart = stringParts[1];

        let company = this.user[firstPart];
        return company[secondPart];
      }

      return this.user[propertyName];
    },

    getBooleanPropertyValue(propertyName) {
      let value = this.user[propertyName];
      if (value === null || value === 0) {
        return "Ne";
      }
      else {
        return "Da";
      }
    },

    formatDateTime(datum) {
      return datum ? moment(datum).format('DD.MM.YYYY HH:mm:ss') : ''
    },

    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },

    onConfirmDelete() {
      this.deleting = true;

      apiUsers.deleteUser(this.user.id, this.$store.getters.token)
        .then(() => {})
        .then(() => {
          // loading na dugmetu
          this.deleting = false;

          // zatvaranje modalnog prozora
          this.dialogConfirmDelete = false;

          this.$router.push('/users');
        })
        .catch(() => {
          this.deleting = false;
        });
    }
  },
}
</script>
