<template>
  <div>
    <v-dialog v-model="dialogEditPortalUser" @keydown.esc="onCancel" persistent max-width="700px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="primary mt-3" dark v-bind="attrs" v-on="on">
          Izmeni
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Izmena korisnika portala
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-row column justify-left>
            <v-col sm="5" class="mt-2">
              <span class="text-subtitle-1 font-weight-medium">Kompanija:</span>
              {{ getCompanyName() }}
            </v-col>
            <v-col sm="2">
              <find-company-dialog
                @update="companySelected"
                icon="mdi-domain"
                buttonText="Izaberite"
                toolbarText="Biranje kompanije"
              />
            </v-col>
            <v-spacer />
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-row column justify-left>
            <v-col sm="5" class="mt-2">
              <span class="text-subtitle-1 font-weight-medium">Portal:</span>
              {{ getPortalName() }}
            </v-col>
            <v-col sm="2">
              <find-portal-dialog
                @update="portalSelected"
                icon="mdi-monitor"
                buttonText="Izaberite"
                toolbarText="Biranje portala"
              />
            </v-col>
            <v-spacer />
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-row column justify-left>
            <v-col sm="5" class="mt-2">
              <span class="text-subtitle-1 font-weight-medium">Centralni korisnik:</span>
              {{ getCentralUserUsername() }}
            </v-col>
            <v-col sm="2">
              <find-user-dialog
                @update="userSelected"
                icon="mdi-account"
                buttonText="Izaberite"
                toolbarText="Biranje centralnog korisnika"
                :disabled="updatedPortalUser.company.id === null"
                :companyId="updatedPortalUser.company.id"
              />
            </v-col>
            <v-spacer />
          </v-row>
        </v-card-text>

        <v-card-text class="mt-4">
          <v-list dense>
            <v-list-item v-for="item in userTextValues" :key="item.label">
              <v-text-field class="pt-0 pb-0" :prepend-icon="item.icon" :label="item.label" v-model="updatedPortalUser[item.value]" ></v-text-field>
            </v-list-item>

            <v-list-item class="mb-4 mt-0">
              <v-row>
                <v-col cols="12" sm="4">
                  <v-switch hide-details="auto" label="Aktivan" v-model="updatedPortalUser.active"></v-switch>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    :items="roles"
                    v-model="updatedPortalUser.role"
                    item-text="text"
                    item-value="value"
                    label="Uloga"
                    outlined>
                  </v-select>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>

          <v-col sm="12">
            <v-select
              v-model="updatedPortalUser.actions"
              :items="actions"
              :menu-props="{ maxHeight: '400' }"
              :item-text="getActionName"
              item-value="id"
              label="Akcije"
              multiple
              hint="Izaberite akcije"
              persistent-hint
            ></v-select>
          </v-col>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error ma-2 white--text" text @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn class="success ma-2 white--text" text @click="dialogConfirmSave = true" :loading="puttingToApi">
            Sačuvaj izmene
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmSave" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Da li želite da sačuvate promene?
        </v-card-title>

         <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text plain ma-2" text @click="dialogConfirmSave = false">
            Odustani
          </v-btn>
          <v-btn class="green--text plain ma-2" text @click="onConfirmEdit" :loading="puttingToApi">
            Sačuvaj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as apiPortalUsers from '../api/portalusers.js';
import * as apiPortals from '../api/portals.js';

import FindPortalDialog from "./FindPortalDialog.vue";
import FindCompanyDialog from "./FindCompanyDialog.vue";
import FindUserDialog from "./FindUserDialog.vue";

export default {
  name: 'EditPortalUserDialog',
  components: {
    FindPortalDialog,
    FindCompanyDialog,
    FindUserDialog
  },
  data: function () {
    return {
      dialogEditPortalUser: false,
      dialogConfirmSave: false,
      puttingToApi: false,
      updatedPortalUser: {
        portalParameters: {
          id: null,
          portalName: null
        },
        company: {
          id: null,
          companyName: null
        },
        user: {
          id: null,
          username: null
        },
        actionIds: []
      },
      userTextValues: [
        { label: "Korisničko ime", value: 'username', icon: "mdi-account" },
        { label: "Lozinka", value: "password", icon: "mdi-lock" },
      ],
      roles: [
        { value: 2, text: "2 - Podrazumevano" },
        { value: 3, text: "3 - Dobavljač" },
      ],
      actions: []
    }
  },
  props: {
    portalUser: {
      type: Object,
      required: true
    }
  },
  watch: {
    dialogEditPortalUser(newValue) {
      if (newValue === true) {
        this.shallowCopy();
      }
    }
  },
  mounted () {
    // Kopiranje objekta
    this.shallowCopy();
    this.getActionsFromAPI();
  },
  methods: {
    shallowCopy() {
      this.updatedPortalUser = JSON.parse(JSON.stringify(this.portalUser));
      this.updatedPortalUser.actions = this.portalUser.actions.map(a => a.id);
    },
    onCancel() {
      this.shallowCopy();
      this.dialogEditPortalUser = false;
    },
    onConfirmEdit() {
      // TODO: validacija pre slanja na API

      this.puttingToApi = true;
      this.preprocessData();

      apiPortalUsers.updatePortalUser(this.portalUser.id, this.updatedPortalUser, this.$store.getters.token)
        .then(response => response.json())
        .then(() => {
          // loading na dugmetu
          this.puttingToApi = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmSave = false;
          this.dialogEditPortalUser = false;

          // brisanje unetih podataka za novu kompaniju
          //this.updatedPortalUser = {};

          this.$router.go();
        })
        .catch(() => {
          this.puttingToApi = false;
        });
    },
    getActionsFromAPI () {
      apiPortals.getActions(this.$store.getters.token)
        .then(response => response.json())
        .then(actionsList => {
          this.actions = actionsList;
        })
        .catch(() => {
          this.actions = [];
        });
    },
    preprocessData () {
      this.updatedPortalUser.active = (this.updatedPortalUser.active) ? 1 : 0;
      this.updatedPortalUser.companyId = this.updatedPortalUser.company.id;
      this.updatedPortalUser.portalId = this.updatedPortalUser.portalParameters.id;
      this.updatedPortalUser.userId = this.updatedPortalUser.user.id;
      console.log(this.updatedPortalUser);
    },
    companySelected(company) {
      this.updatedPortalUser.company = company;
    },
    portalSelected(portal) {
      this.updatedPortalUser.portalParameters = portal;
    },
    userSelected(user) {
      this.updatedPortalUser.user = user;
    },

    getCompanyName() {
      if (this.updatedPortalUser.company === null) {
        return null;
      }

      return this.updatedPortalUser.company.companyName
    },

    getPortalName() {
      if (this.updatedPortalUser.portalParameters === null) {
        return null;
      }

      return this.updatedPortalUser.portalParameters.portalName;
    },

    getCentralUserUsername() {
      if (this.updatedPortalUser.user === null) {
        return null;
      }

      return this.updatedPortalUser.user.username;
    },

    getActionName(item) {
      return item.name + " (" + item.buttonText + ")";
    },
  }
}
</script>
