<template>
  <v-row align="center" justify="center">
    <v-col sm="9">
      <customer-cust-codes-between-companies />
    </v-col>
  </v-row>
</template>

<script>
import CustomerCustCodesBetweenCompanies from '../components/CustomerCustCodesBetweenCompanies.vue';

export default {
  name: "CustomersCustCodesList",
  components: {
    CustomerCustCodesBetweenCompanies
  }
}
</script>
