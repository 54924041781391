<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" md="10">
        <v-row align-center column justify-center>
          <v-card width="80%" class="mb-6">
            <v-card-title> Uparivanje šifarnika </v-card-title>

            <v-card-text>
              <v-row>
                <v-col sm="12" md="6">
                  <v-file-input
                    label="Parovi šifara u XLSX (Excel) formatu"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    v-model="uploadedFile"
                    truncate-length="50"
                    outlined
                    prepend-icon="mdi-microsoft-excel"
                  ></v-file-input>
                </v-col>
                <v-col sm="12">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col sm="12" class="text-subtitle-1 font-weight-medium">
                      Podešavanja fajla
                    </v-col>

                    <v-col sm="12" class="text-caption">
                      Napomena: Unose se brojevi redova kao u Excelu (počev od broja 1)
                    </v-col>

                    <v-col sm="12" md="4" class="pt-0 pb-0">
                      <v-text-field
                        label="Početni red"
                        required
                        outlined
                        v-model="fileSettings.firstRow"
                      ></v-text-field>
                    </v-col>

                    <v-col  sm="12" md="4" class="pt-0 pb-0">
                      <v-text-field
                        label="Poslednji red"
                        outlined
                        v-model="fileSettings.lastRow"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col sm="12">
                  <v-divider></v-divider>
                  <v-row column justify-left>
                    <v-col sm="12" class="text-subtitle-1 font-weight-medium">
                      Biranje šifarnika koji se uparuju
                    </v-col>

                    <v-col sm="12" md="6">
                      <div class="text-subtitle-1 font-weight-medium">Pošiljalac</div>

                      <div class="mb-4">Kompanija: {{ sender.company.companyName }}</div>

                      <find-company-dialog
                        @update="senderCompanySelected"
                        icon="mdi-domain"
                        buttonText="Izaberite"
                        toolbarText="Biranje kompanije pošiljaoca"
                      />

                      <v-divider class="mt-2 mb-2"></v-divider>

                      <div class="mb-4">Šifarnik: {{ sender.codebook.filename }}</div>

                      <find-codebook-dialog
                        @update="senderCodebookSelected"
                        icon="mdi-book-open-page-variant-outline"
                        buttonText="Izaberite"
                        toolbarText="Biranje šifarnika pošiljaoca"
                        :disabled="sender.company.id === undefined"
                        :companyId="sender.company.id"
                      />
                    </v-col>

                    <v-col sm="12" md="6">
                      <div class="text-subtitle-1 font-weight-medium">Primalac</div>

                      <div class="mb-4">Kompanija: {{ receiver.company.companyName }}</div>

                      <find-company-dialog
                        @update="receiverCompanySelected"
                        icon="mdi-domain"
                        buttonText="Izaberite"
                        toolbarText="Biranje kompanije primaoca"
                      />

                      <v-divider class="mt-2 mb-2"></v-divider>

                      <div class="mb-4">Šifarnik: {{ receiver.codebook.filename }}</div>

                      <find-codebook-dialog
                        @update="receiverCodebookSelected"
                        icon="mdi-book-open-page-variant-outline"
                        buttonText="Izaberite"
                        toolbarText="Biranje šifarnika primaoca"
                        :disabled="receiver.company.id === undefined"
                        :companyId="receiver.company.id"
                      />
                    </v-col>

                    <v-spacer />
                  </v-row>
                </v-col>


                <v-col sm="12">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col sm="12" class="text-subtitle-1 font-weight-medium">
                      Podešavanja kolona
                    </v-col>
                    <v-col sm="12" class="text-caption">
                      Napomena: Unose se slova (kao u Excelu)
                    </v-col>
                    <v-col sm="12" md="6">
                      <v-col sm="12" class="text-subtitle-1 font-weight-medium">
                        Pošiljalac
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-checkbox dense class="mt-0"
                          v-model="sender.doUpdateCodebook"
                          label="Ažurirati postojeći šifarnik pošiljaoca"
                        ></v-checkbox>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-checkbox dense class="mt-0"
                          v-model="sender.removeLeadingZeros"
                          label="Brisati vodeće nule šiframa"
                        ></v-checkbox>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field required outlined
                          label="Šifra"
                          v-model="sender.columnSettings.sifra"
                        ></v-text-field>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field required outlined
                          label="Naziv"
                          v-model="sender.columnSettings.naziv"
                        ></v-text-field>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field outlined
                          label="Proizvođač"
                          v-model="sender.columnSettings.proizvodjac"
                        ></v-text-field>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field outlined
                          label="Opis"
                          v-model="sender.columnSettings.opis"
                        ></v-text-field>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field outlined
                          label="JKL"
                          v-model="sender.columnSettings.jkl"
                        ></v-text-field>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field outlined
                          label="ATC"
                          v-model="sender.columnSettings.atc"
                        ></v-text-field>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field outlined
                          label="EAN 1"
                          v-model="sender.columnSettings.ean1"
                        ></v-text-field>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field outlined
                          label="EAN 2"
                          v-model="sender.columnSettings.ean2"
                        ></v-text-field>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field outlined
                          label="EAN 3"
                          v-model="sender.columnSettings.ean3"
                        ></v-text-field>
                      </v-col>
                    </v-col>

                    <v-col sm="12" md="6">
                      <v-col sm="12" class="text-subtitle-1 font-weight-medium">
                        Primalac
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-checkbox dense class="mt-0"
                          v-model="receiver.doUpdateCodebook"
                          label="Ažurirati postojeći šifarnik primaoca"
                        ></v-checkbox>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-checkbox dense class="mt-0"
                          v-model="receiver.removeLeadingZeros"
                          label="Brisati vodeće nule šiframa"
                        ></v-checkbox>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field required outlined
                          label="Šifra"
                          v-model="receiver.columnSettings.sifra"
                        ></v-text-field>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field required outlined
                          label="Naziv"
                          v-model="receiver.columnSettings.naziv"
                        ></v-text-field>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field outlined
                          label="Proizvođač"
                          v-model="receiver.columnSettings.proizvodjac"
                        ></v-text-field>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field outlined
                          label="Opis"
                          v-model="receiver.columnSettings.opis"
                        ></v-text-field>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field outlined
                          label="JKL"
                          v-model="receiver.columnSettings.jkl"
                        ></v-text-field>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field outlined
                          label="ATC"
                          v-model="receiver.columnSettings.atc"
                        ></v-text-field>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field outlined
                          label="EAN 1"
                          v-model="receiver.columnSettings.ean1"
                        ></v-text-field>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field outlined
                          label="EAN 2"
                          v-model="receiver.columnSettings.ean2"
                        ></v-text-field>
                      </v-col>

                      <v-col sm="12" class="pt-0 pb-0">
                        <v-text-field outlined
                          label="EAN 3"
                          v-model="receiver.columnSettings.ean3"
                        ></v-text-field>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="error ma-2 white--text" text @click="onCancel">
                Odustani
                <v-icon right>mdi-close</v-icon>
              </v-btn>
              <v-btn class="primary ma-2 white--text" text @click="onAddPairing" :loading="postingToApi">
                Uparivanje
                <v-icon right>mdi-book-sync-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>

          <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show">
          </snackbar>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import * as codebooksAPI from "../api/codebooks";
import FindCompanyDialog from "../components/FindCompanyDialog.vue";
import FindCodebookDialog from "../components/FindCodebookDialog.vue";
import Snackbar from "../components/Snackbar.vue"

export default {
  name: "CodebooksPairing",
  components: {
    FindCompanyDialog,
    FindCodebookDialog,
    Snackbar
  },
  data: function () {
    return {
      postingToApi: false,

      snackbar: {
        "show" : false,
        "text" : "",
        "isError" : false
      },

      fileSettings: {},
      uploadedFile: {},
      sender: {
        "company" : {},
        "codebook" : {},
        "columnSettings" : {},
        "doUpdateCodebook" : false
      },

      receiver: {
        "company" : {},
        "codebook" : {},
        "columnSettings" : {},
        "doUpdateCodebook" : false
      }
    };
  },
  methods: {
    onAddPairing() {
      // TODO: validacija pre slanja na API
      this.postingToApi = true;

      let senderColumnSettings = this.mapColumnLetters(this.sender.columnSettings);
      let receiverColumnSettings = this.mapColumnLetters(this.receiver.columnSettings);
      let newRequest = this.createRequest(senderColumnSettings, receiverColumnSettings);

      codebooksAPI
        .pairing(newRequest, this.uploadedFile, this.$store.getters.token)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.postingToApi = false;
          let notificationMessage = this.createNotificationMessage(responseData);
          this.showNotification("Uspešno uparivanje! " + notificationMessage);
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.postingToApi = false;
        });
    },
    onCancel() {
      this.$router.push('/codebooks');
    },
    senderCompanySelected(company) {
      this.sender.company = company;
    },
    receiverCompanySelected(company) {
      this.receiver.company = company;
    },
    senderCodebookSelected(codebook) {
      this.sender.codebook = codebook;
    },
    receiverCodebookSelected(codebook) {
      this.receiver.codebook = codebook;
    },
    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      // prikaz snackbar obavestenja
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },
    createRequest(senderColumnSettings, receiverColumnSettings) {
      let request = {
        "fileSettings" : {},
        "senderSettings" : {},
        "receiverSettings" : {}
      };

      request.fileSettings.firstRow = this.fileSettings.firstRow - 1;
      if (this.fileSettings.lastRow) {
        request.fileSettings.lastRow = this.fileSettings.lastRow - 1;
      }
      request.fileSettings.filename = this.uploadedFile.name;
      request.fileSettings.removeLeadingZeros = false;

      request.senderSettings.codebookId = this.sender.codebook.id;
      request.senderSettings.codeBookColumnSettings = senderColumnSettings;
      request.senderSettings.doUpdateCodebook = this.sender.doUpdateCodebook;
      request.senderSettings.removeLeadingZeros = this.sender.removeLeadingZeros;

      request.receiverSettings.codebookId = this.receiver.codebook.id;
      request.receiverSettings.codeBookColumnSettings = receiverColumnSettings;
      request.receiverSettings.doUpdateCodebook = this.receiver.doUpdateCodebook;
      request.receiverSettings.removeLeadingZeros = this.receiver.removeLeadingZeros;

      return request;
    },
    mapColumnLetters(columnSettings) {
      let mappedColumnSettings = {};
      for (var prop in columnSettings) {
        if (Object.prototype.hasOwnProperty.call(columnSettings, prop)) {
            let letter = columnSettings[prop];
            if (letter === null) {
              continue;
            }

            letter = letter.toLowerCase();
            let number = letter.charCodeAt(0);

            number = number - 97;
            if (number >= 0 && number <= 25) {
              mappedColumnSettings[prop] = number;
            }
            else {
              mappedColumnSettings[prop] = columnSettings[prop];
            }
        }
      }

      return mappedColumnSettings;
    },

    createNotificationMessage (pairingResponse) {
      let message = "Ukupan broj redova u Excel fajlu: " + pairingResponse.totalLoadedExcelRows + " \n";
      message += "Broj kreiranih parova: " + pairingResponse.addedPairsCount + " \n";
      message += "Broj dodatih artikala pošiljaoca: " + pairingResponse.addedSenderProductsCount + " \n";
      message += "Broj dodatih artikala primaoca: " + pairingResponse.addedReceiverProductsCount + " \n";

      return message;
    }
  },
};
</script>
