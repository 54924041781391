<template>
  <v-container>
    <v-card>
      <v-card-title>
        Šifarnici
        <v-spacer></v-spacer>
        <v-text-field
          v-model="queryString"
          append-icon="mdi-magnify"
          label="Pretraga"
          single-line
          hide-details
          clearable
          @keyup.enter.prevent='onEnterSearch'
          @click:clear='onClearSearch()'
        ></v-text-field>
      </v-card-title>
      <v-data-table
      :headers="headers"
      :items="listOfCodebooks"
      :server-items-length="options.page * options.itemsPerPage + 1"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
      @click:row="onRowClick"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ format_date(item.createdAt) }}</span>
        </template>

        <template v-slot:[`item.updatedAt`]="{ item }">
          <span>{{ format_date(item.updatedAt) }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small circle class="mr-2" @click="updateCodebook(item)">
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import * as apiCodebooks from '../api/codebooks.js'
import moment from 'moment'

export default {
  name: "CodebooksList",
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true]
      },
      queryString: null,
      listOfCodebooks: [],
      headers: [
          { text: 'ID', value: 'id', width: "10%" },
          { text: 'Naziv fajla', value: 'filename', width: "20%" },
          { text: 'Datum kreiranja', value: 'createdAt', width: "15%" },
          { text: 'Datum ažuriranja', value: 'updatedAt', width: "15%" },
          { text: 'Kompanija', value: 'company.companyName', width: "20%" },
          { text: 'PIB', value: 'company.pib', width: "10%" },
          { text: 'Akcije', value: 'actions', width: "10%", sortable: false }
      ]
    }
  },
  watch: {
    options: {
      handler () {
        this.options.search = this.queryString;
        this.getCodebooksFromAPI()
      },
      deep: true,
    }
  },
  mounted () {
    this.getCodebooksFromAPI()
  },
  methods: {
    getCodebooksFromAPI() {
      this.loading = true

      apiCodebooks.getList(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false
          this.listOfCodebooks = responseData
        })
        .catch(() => {
          this.listOfCodebooks = []
          this.loading = false
        })
    },

    onEnterSearch() {
      this.options.search = this.queryString
      this.options.page = 1
      this.getCodebooksFromAPI()
    },

    onClearSearch() {
      this.options.search = ''
      this.queryString = ''
      this.getCodebooksFromAPI()
    },

    onRowClick(row) {
      console.log(row.id);
      return;
      // this.$router.push('/codebooks/' + row.id)
    },

    updateCodebook(codebook) {
      this.$router.push('/codebooks/update/' + codebook.id);
    },

    format_date(value){
      if (value) {
        let dateString = moment(String(value)).format('DD.MM.YYYY');
        if (dateString === "01.01.0001") {
          return null;
        }
        else {
          return dateString;
        }
      }
    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
