<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10">
        <v-row align-center column justify-center>
          <v-col class="mt-2 mb-2">
            <v-btn class="primary" dark to="/seflink/createAffiliate">
              Dodavanje Affiliate korisnika
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>

           <v-col class="mt-2 mb-2">
            <v-btn class="primary" dark to="/seflink/documents">
              Dokumenti
              <v-icon>mdi-file</v-icon>
            </v-btn>
          </v-col>

           <v-col class="mt-2 mb-2">
            <v-btn class="primary" dark to="/seflink/users">
              Korisnici
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SefLinkPage",
  components: {
  }
}
</script>
