<template>
  <v-card v-if="loading === false" width="100%">
    <v-card-subtitle>
      ID: {{ portalUser.id }}
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col>
          <v-list dense>
            <v-list-item v-for="item in portalUserProperties" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle class="text-wrap">{{ getPropertyValue(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col>
          <v-list dense>
            <v-list-item v-for="item in portalUserStatusProperties" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getBooleanPropertyValue(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          Dozvoljene akcije
        </v-col>
        <v-col>
          <v-chip
            v-for="action in portalUser.actions"
            :key="action.id"
            class="ma-1"
          >
            {{ action.name }} ({{action.buttonText}})
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn class="error ma-2 white--text" text @click="dialogConfirmDelete = true" :loading="deleting">
        Obriši
        <v-icon right>mdi-trash-can-outline</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <edit-portal-user-dialog :portalUser="portalUser" v-if="loading === false"/>
    </v-card-actions>

    <snackbar :isError="snackbar.isError" :text="snackbar.text" :showSnackbar.sync="snackbar.show">
    </snackbar>

    <v-dialog v-model="dialogConfirmDelete" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Da li želite da obrišete korisnika?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text plain ma-2" outlined @click="dialogConfirmDelete = false">
            Ne
          </v-btn>
          <v-btn class="error ma-2" text @click="onConfirmDelete" :loading="deleting">
            Da
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import * as apiPortalUsers from "../api/portalusers.js";

import EditPortalUserDialog from "./EditPortalUserDialog.vue";
import Snackbar from "../components/Snackbar.vue";

export default {
  name: 'PortalUserDetails',
  components: {
    EditPortalUserDialog,
    Snackbar
  },
  data: function () {
    return {
      loading: false,
      reloadFileInProgress: false,
      portalUserProperties: [
        { label: "Korisničko ime", propertyName: 'username', icon: "mdi-account" },
        { label: "Portal", propertyName: 'portalParameters.portalName', icon: "mdi-monitor" },
        { label: "Kompanija", propertyName: 'company.companyName', icon: "mdi-domain" },
        { label: "Centralni korisnik", propertyName: 'user.name', icon: "mdi-account" },
        { label: "Uloga", propertyName: 'role', icon: "mdi-badge-account" },
        { label: "Datum i vreme kreiranja", propertyName: 'createdAt', icon: "mdi-calendar-range" },
        { label: "Datum i vreme ažuriranja", propertyName: 'updatedAt', icon: "mdi-calendar-refresh" }
      ],

      portalUserStatusProperties: [
        { label: "Aktivan", propertyName: 'active', icon: "mdi-check-circle" },
      ],

      snackbar: {
        show : false,
        text : "",
        isError : false
      },

      dialogConfirmDelete: false,
      deleting: false
    }
  },
  props: {
    portalUser: {
      type: Object,
      required: true
    }
  },
  methods: {
    getPropertyValue(propertyName) {
      let dateProperties = [ "createdAt", "updatedAt" ];
      if (dateProperties.includes(propertyName)) {
        return this.formatDateTime(this.portalUser[propertyName]);
      }

      if (propertyName.includes(".")) {
        let stringParts = propertyName.split(".");
        let firstPart = stringParts[0];
        let secondPart = stringParts[1];

        let obj = this.portalUser[firstPart];
        if (obj === null)
          return "";
        return obj[secondPart];
      }

      return this.portalUser[propertyName];
    },

    getBooleanPropertyValue(propertyName) {
      let value = this.portalUser[propertyName];
      if (value === null || value === 0) {
        return "Ne";
      }
      else {
        return "Da";
      }
    },

    formatDateTime(datum) {
      return datum ? moment(datum).format('DD.MM.YYYY HH:mm:ss') : ''
    },

    showError(errorCode) {
      this.snackbar.text = errorCode;
      this.snackbar.isError = true;
      this.snackbar.show = true;
    },
    showNotification(text) {
      this.snackbar.text = text;
      this.snackbar.isError = false;
      this.snackbar.show = true;
    },

    onConfirmDelete() {
      this.deleting = true;

      apiPortalUsers.deletePortalUser(this.portalUser.id, this.$store.getters.token)
        .then(() => {})
        .then(() => {
          // loading na dugmetu
          this.deleting = false;

          // zatvaranje modalnog prozora
          this.dialogConfirmDelete = false;

          this.goToUsersPage();
        })
        .catch(() => {
          this.deleting = false;
        });
    },

    goToUsersPage() {
      this.$router.push('/users');
    }
  },
}
</script>
